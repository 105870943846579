import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table, Badge, InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import ArticleSearch from "./article-search/ArticleSearch";
import { BrouillonService } from '../../../services/BrouillonService';
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import { reorder, sortByNumber, getChangedValues, currencyFormat, granted, formatTableHeadersFromPrefs, deepFind } from '../../../helper/Helper'

import { GlobalContext } from "../../../services/GlobalProvider";

import './Details.scss';

const Details = (props) => {

    const [detailList, setDetailList] = useState()
    const [detailListInitial, setDetailListInitial] = useState()
    const [contributionList, setContributionList] = useState()
    const [globalError, setGlobalError] = useState()
    const [editableIndex, setEditableIndex] = useState()
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [addDetailLoading, setAddDetailLoading] = useState()
    const [tableHeaders, setTableHeaders] = useState()
    const [addUnderLineNum, setAddUnderLineNum] = useState()


    const { preferences, parametres, folderDetails, userDetails, grants } = React.useContext(GlobalContext);

    useEffect(() => {

        if (preferences && parametres && grants) {
            setDetailList(props.brouillon.details)
            setDetailListInitial(JSON.parse(JSON.stringify(props.brouillon.details)))
            getContributions()
            setAddDetailLoading(false)
            setTableHeaders(formatTableHeadersFromPrefs(preferences?.brouillons['tableHeaders'], parametres?.brouillons['fieldsLabel']['details']))
        }

    }, [props.brouillon.details, preferences, parametres]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);


    function addDetail(detail) {
        setAddDetailLoading(true)
        addUnderLineNum && (detail.numero_ligne = addUnderLineNum + 0.5)
        BrouillonService.postArticle(props.folderId, props.brouillon._id, { details: [detail] }).then(res => {
            props.reloadBrouillon()
            setAddUnderLineNum()
        }).catch(error => {
            setEditableIndex()
            setAddDetailLoading(false)
            setGlobalError(error)
        });
    }

    function updateDetails(detail) {
        let initialDetail = detailListInitial.find(x => x._id == detail._id)
        BrouillonService.updateDetails(props.folderId, props.brouillon._id, { details: [getChangedValues(detail, initialDetail, [], ["_id"])] }).then(res => {
            props.reloadBrouillon()
            setEditableIndex()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function handleKeyDown(e) {

        if (editableIndex != null && !confirmDialog.show) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    if (e.ctrlKey) {
                        editableIndex > 0 && orderUpDown(editableIndex, -1)
                        return;
                    }
                    break;
                case "ArrowDown":
                    if (e.ctrlKey) {
                        (editableIndex < detailList.length - 1) && orderUpDown(editableIndex, 1)
                        return;
                    }
                    break;
                case "Enter":
                    if (e.ctrlKey) {
                        updateDetails(detailList[editableIndex])
                        return;
                    }
                    break;
                case "Delete":
                    if (e.ctrlKey && granted(grants, "brouillons.edit.details.remove")) {
                        removeDetailConfirm(detailList[editableIndex])
                        return;
                    }
                    break;
                case "Tab":
                    // Stoppe la progression du focus avec la tabulation
                    if (e.shiftKey) {
                        e.returnValue = true;
                    } else if (e.target?.id == "validerBtn") {
                        e.preventDefault()
                    }
                    break;
                case "ctrlKey":
                    break;
                case "Enter":
                    break;
                case "ArrowRight":
                    break;
                case "ArrowLeft":
                    break;
                case "Space":
                    break;
                case "Escape":
                    cancelChange()
                    break;
                default:
                    break;
            }
        }

    }

    const handleChange = i => e => {
        let detailListCopy = [...detailList];
        detailListCopy[i][e.target.name] = e.target.value;
        detailListCopy[i].prix_vente_ht != 0 && (detailListCopy[i].masquer = false)
        setDetailList(detailListCopy);
    }

    const handleChangeTauxRemise = i => e => {
        let detailListCopy = [...detailList];
        detailListCopy[i].remise.taux = e.target.value;
        setDetailList(detailListCopy);
    }

    const handleChangeContribution = (i, y) => e => {
        let detailListCopy = [...detailList];
        detailListCopy[i].contributions[y].contribution_id = e.target.value;
        setDetailList(detailListCopy);
    }

    function removeDetailConfirm(d) {
        setConfirmDialog({
            show: true,
            dialogInfos: {
                title: 'Supprimer',
                description: `Voulez vous supprimer "${d.designation}" ?`,
                actionName: 'Supprimer',
                btnVariant: 'danger',
                element: d,
                error: false,
                confirmAction: 'removeDetail'
            }
        })
    }

    function removeDetail(d) {
        BrouillonService.removeArticle(props.folderId, props.brouillon._id, d._id).then(res => {
            props.reloadBrouillon()
            setEditableIndex()
            setConfirmDialog({ show: false });
        }).catch(error => {
            setGlobalError(error)
            setConfirmDialog({ show: false });
        });
    }

    function editDetail(i) {
        setEditableIndex(i)
        setAddUnderLineNum()
    }

    function openArticleId(i) {
        props.openArticleId(detailList[i].article_id)
    }

    function addUnder(i) {
        setAddUnderLineNum(i)
    }

    function showHideDetail(i, state) {
        let detailListCopy = [...detailList];
        detailListCopy[i].masquer = state;
        updateDetails(detailListCopy[i])
    }

    function cancelChange() {
        let details
        details = JSON.parse(JSON.stringify(props.brouillon.details))
        details = sortByNumber(details, "numero_ligne")
        setDetailList(details)
        setEditableIndex()
    }

    function getContributions() {
        ContributionService.getContributions(props.folderId).then(res => {
            setContributionList(res.data.data.contributions)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function addElement(article) {
        let articleTemp = {
            "type": "article",
            "article_id": article._id,
            "quantite": 1
        }
        addDetail(articleTemp)
        setEditableIndex(addUnderLineNum ? addUnderLineNum + 1 : props.brouillon.details.length)
    }

    function addDescription(description) {
        let descriptionTemp = {
            "type": "commentaire",
            "designation": description
        }
        addDetail(descriptionTemp)
    }

    function addSubtotal() {
        let descriptionTemp = {
            "type": "total"
        }
        addDetail(descriptionTemp)
    }

    function orderUpDown(index, value) {

        let detail = detailList[index]

        let list
        list = reorder(detailList, index, index + value)

        // change numero de ligne pout lui ajouter ou enlever 0.5 (Fonctionnement de l'API)
        let listIndex = list.findIndex(d => d._id == detail._id);
        value == -1 && (list[listIndex].numero_ligne = list[listIndex + 1].numero_ligne - 0.5)
        value == 1 && (list[listIndex].numero_ligne = list[listIndex - 1].numero_ligne + 0.5)

        setDetailList(list);
        setEditableIndex(index + value)
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let list
        list = reorder(detailList, result.source.index, result.destination.index)

        // change numero de ligne pout lui ajouter ou enlever 0.5 (Fonctionnement de l'API)
        let listIndex = result.destination.index;
        result.destination.index < result.source.index && (list[listIndex].numero_ligne = list[listIndex + 1].numero_ligne - 0.5)
        result.destination.index > result.source.index && (list[listIndex].numero_ligne = list[listIndex - 1].numero_ligne + 0.5)

        setDetailList(list);
        setEditableIndex(result.destination.index)
    }

    return (
        <>

            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            <div id="Details">
                {globalError && (
                    <Col xs="12" className="mt-3">
                        <ErrorManager error={globalError} />
                    </Col>
                )}

                <div className="mt-2">
                    {detailList &&

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable shouldRespectForcePress={true} droppableId="Details">
                                {(provided, snapshot) => (
                                    <>
                                        <div className="table-responsive d-table w-100 mb-3 mb-xxl-0">
                                            <Table borderless className="text-nowrap align-middle mb-3"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {detailList.length > 0 &&
                                                    <thead className="border-bottom">
                                                        <tr className="align-middle">
                                                            <th>#</th>
                                                            {/* Si client est type "interne" on affiche les colonnes  taux_marge_calc taux_marque_calc coefficient_calc */}
                                                            {tableHeaders.filter(header => !header.hidden).map((header, k) => (
                                                                <th key={header.label} className={"text-uppercase text-gray-600 small " + (header.align == "right" ? "text-end " : "") + (header.dataTarget == "designation" ? "w-100 " : "")}>
                                                                    {(props.brouillon?.client?.type == "interne" || (header.dataTarget != "taux_marge_calc" && header.dataTarget != "taux_marque_calc" && header.dataTarget != "coefficient_calc")) &&
                                                                        <>{header.label}</>
                                                                    }
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                }

                                                <tbody>

                                                    {detailList.map((detail, i) => (
                                                        <Draggable isDragDisabled={editableIndex !== i} key={detail._id} draggableId={detail._id} index={i}>
                                                            {(provided, snapshot) => (
                                                                <>
                                                                    <tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        className={(detail.masquer ? "hover-bg-gray-100 bg-gray-100 text-gray-500 " : "") + (editableIndex == i ? "" : "hover-bg-gray-100")}
                                                                    >
                                                                        {/* Ligne en mode affichage */}
                                                                        {(editableIndex != i) &&
                                                                            <>
                                                                                {/* Ligne de description ou sous-total */}
                                                                                {(detail.type == "commentaire" || detail.type == "total") &&
                                                                                    <>
                                                                                        {/* Numéro de ligne */}
                                                                                        <td style={{ minWidth: 25 }} className="fw-bold text-gray-500">
                                                                                            <span className={"d-flex " + ((props.showChanges && props.modifiedValues?.details?.[detail._id]?.numero_ligne != null) ? "modified-bg" : "")}>
                                                                                                {i + 1} {detail.masquer && <i className="material-icons ms-2">visibility_off</i>}
                                                                                            </span>
                                                                                        </td>

                                                                                        <td></td>

                                                                                        <td key={detail._id} colSpan={tableHeaders.filter(header => !header.hidden).length}>
                                                                                            {/* Description */}
                                                                                            <span title={detail.designation} onClick={() => { editableIndex == null && editDetail(i) }} className={"text-truncate d-block " + (editableIndex == null ? "text-primary cursor-pointer" : "")}>
                                                                                                <span className={(props.showChanges && props.modifiedValues?.details?.[detail._id]?.designation) ? "modified-bg" : ""}>
                                                                                                    {detail.designation}
                                                                                                </span>
                                                                                            </span>
                                                                                        </td>

                                                                                    </>
                                                                                }

                                                                                {/* Ligne d'article */}
                                                                                {detail.type == "article" &&
                                                                                    <>
                                                                                        <td style={{ minWidth: 25 }} className="fw-bold text-gray-500">
                                                                                            <span className={"d-flex " + ((props.showChanges && props.modifiedValues?.details?.[detail._id]?.numero_ligne != null) ? "modified-bg" : "")}>
                                                                                                {i + 1} {detail.masquer && <i className="material-icons ms-2">visibility_off</i>}
                                                                                            </span>
                                                                                        </td>

                                                                                        {/* Si client est type "interne" on affiche les colonnes  taux_marge_calc taux_marque_calc coefficient_calc */}
                                                                                        {tableHeaders.filter(header => !header.hidden).map(header =>
                                                                                        ((props.brouillon?.client?.type == "interne" || (header.dataTarget != "taux_marge_calc" && header.dataTarget != "taux_marque_calc" && header.dataTarget != "coefficient_calc")) &&
                                                                                            <td key={header.dataTarget} className={header.align == "right" ? "text-end" : ""}>
                                                                                                {/* designation */}
                                                                                                {header.dataTarget == "designation" &&
                                                                                                    <>
                                                                                                        {granted(grants, "brouillons.edit.details") ?
                                                                                                            <>
                                                                                                                {Object.entries(detail?.contributions)?.filter(([key, value]) => detail.contributions[key]?.type == "contrib_eco").map(([key, value], y) => (
                                                                                                                    <span className="float-start" key={y}>
                                                                                                                        <OverlayTrigger
                                                                                                                            placement="top"
                                                                                                                            overlay={<Tooltip>{detail.contributions[key].libelle}</Tooltip>}
                                                                                                                        >
                                                                                                                            <i className="material-icons me-1 cursor-default">energy_savings_leaf</i>
                                                                                                                        </OverlayTrigger>

                                                                                                                    </span>
                                                                                                                ))}
                                                                                                                {detail.tarif?.libelle &&
                                                                                                                    <span className="float-start">
                                                                                                                        <OverlayTrigger
                                                                                                                            placement="top"
                                                                                                                            overlay={<Tooltip>{detail.tarif?.code} - {detail.tarif?.libelle} ({detail.tarif?.type})</Tooltip>}
                                                                                                                        >
                                                                                                                            <i className="material-icons me-1 cursor-default">local_offer</i>
                                                                                                                        </OverlayTrigger>

                                                                                                                    </span>
                                                                                                                }
                                                                                                                <span title={detail.designation} onClick={() => { editableIndex == null && editDetail(i) }} className={"text-truncate d-block " + (editableIndex == null ? "text-primary cursor-pointer " : "")}>
                                                                                                                    {detail.designation}
                                                                                                                </span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {Object.entries(detail?.contributions)?.filter(([key, value]) => detail.contributions[key]?.type == "contrib_eco").map(([key, value], y) => (
                                                                                                                    <span className="float-start" key={y}>
                                                                                                                        <OverlayTrigger
                                                                                                                            placement="top"
                                                                                                                            overlay={<Tooltip>{detail.contributions[key].libelle}</Tooltip>}
                                                                                                                        >
                                                                                                                            <i className="material-icons me-1 cursor-default">energy_savings_leaf</i>
                                                                                                                        </OverlayTrigger>

                                                                                                                    </span>
                                                                                                                ))}
                                                                                                                <span title={detail.designation} className="text-truncate d-block">{detail.designation}</span>
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                }

                                                                                                {/* prix_vente_ht || montant_ttc */}
                                                                                                {(
                                                                                                    header.dataTarget == "prix_vente_ht" ||
                                                                                                    header.dataTarget == "prix_revient" ||
                                                                                                    header.dataTarget == "montant_brut_ht" ||
                                                                                                    header.dataTarget == "remise.montant" ||
                                                                                                    header.dataTarget == "montant_net_ht" ||
                                                                                                    header.dataTarget == "montant_ttc") &&
                                                                                                    <span className={(props.showChanges && deepFind(props.modifiedValues?.details?.[detail._id], header.dataTarget)) ? "modified-bg" : ""}>
                                                                                                        {currencyFormat(deepFind(detail, header.dataTarget))}
                                                                                                    </span>
                                                                                                }

                                                                                                {/* taux_marge_calc || taux_marque_calc || coefficient_calc quand client est type "interne" */}
                                                                                                {props.brouillon?.client?.type == "interne" && (
                                                                                                    header.dataTarget == "taux_marge_calc" ||
                                                                                                    header.dataTarget == "coefficient_calc" ||
                                                                                                    header.dataTarget == "taux_marque_calc") &&
                                                                                                    <span className={(props.showChanges && deepFind(props.modifiedValues?.details?.[detail._id], header.dataTarget)) ? "modified-bg" : ""}>
                                                                                                        {currencyFormat(deepFind(detail, header.dataTarget))}
                                                                                                    </span>
                                                                                                }

                                                                                                {/* contributions.valeur */}
                                                                                                {(
                                                                                                    header.dataTarget == "contributions.valeur"
                                                                                                ) &&
                                                                                                    <span>
                                                                                                        {Object.entries(detail?.contributions)?.map(([key, value], y) => (
                                                                                                            <span key={y}>
                                                                                                                {(detail.contributions[key]?.type == "taxe_vente") &&
                                                                                                                    <>
                                                                                                                        <span className={(props.showChanges && props.modifiedValues?.details?.[detail._id]?.contributions?.find(c => c.libelle == detail.contributions[key].libelle)) ? "modified-bg" : ""}>
                                                                                                                            {detail.contributions[key].libelle}
                                                                                                                        </span>

                                                                                                                    </>
                                                                                                                }
                                                                                                            </span>
                                                                                                        ))}
                                                                                                    </span>
                                                                                                }

                                                                                                {/* contributions.montant */}
                                                                                                {(
                                                                                                    header.dataTarget == "contributions.montant"
                                                                                                ) &&
                                                                                                    <span>
                                                                                                        {Object.entries(detail?.contributions)?.map(([key, value], y) => (
                                                                                                            <span key={y}>
                                                                                                                {(detail.contributions[key]?.type == "taxe_vente") &&
                                                                                                                    <>
                                                                                                                        <span className={(props.showChanges && props.modifiedValues?.details?.[detail._id]?.contribution?.montant) ? "modified-bg" : ""}>
                                                                                                                            {currencyFormat(detail.contributions[key].montant)}
                                                                                                                        </span>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </span>
                                                                                                        ))}
                                                                                                    </span>
                                                                                                }

                                                                                                {/* remise.taux */}
                                                                                                {(
                                                                                                    header.dataTarget == "remise.taux"
                                                                                                ) &&
                                                                                                    <span className={(props.showChanges && deepFind(props.modifiedValues?.details?.[detail._id], header.dataTarget)) ? "modified-bg" : ""}>
                                                                                                        {currencyFormat(deepFind(detail, header.dataTarget))} %
                                                                                                    </span>
                                                                                                }


                                                                                                {/* Autres données */}
                                                                                                {(
                                                                                                    header.dataTarget == "code" ||
                                                                                                    header.dataTarget == "quantite") &&
                                                                                                    <span className={(props.showChanges && deepFind(props.modifiedValues?.details?.[detail._id], header.dataTarget)) ? "modified-bg" : ""}>
                                                                                                        {deepFind(detail, header.dataTarget)}
                                                                                                    </span>
                                                                                                }

                                                                                            </td>)
                                                                                        )}

                                                                                    </>
                                                                                }


                                                                                {/* Actions  */}
                                                                                {(editableIndex == null && granted(grants, "brouillons.edit.details")) &&
                                                                                    <td className="actions py-0">
                                                                                        <span>

                                                                                            {detail.masquer &&
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip>
                                                                                                            Rendre la ligne visible
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <i onClick={() => showHideDetail(i, false)} className="cursor-pointer material-icons hover-bg-gray-200 rounded text-dark">visibility</i>
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            {(!detail.masquer && (!detailList[i].montant_ttc || detailList[i].montant_ttc == 0)) &&
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip>
                                                                                                            Masquer la ligne
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <i onClick={() => showHideDetail(i, true)} className="cursor-pointer material-icons hover-bg-gray-200 rounded text-dark">visibility_off</i>
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            {(!detail.masquer && (detailList[i].montant_ttc > 0 || detailList[i].montant_ttc < 0)) &&
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip>
                                                                                                            Masquer la ligne (le Total TTC doit être à zéro)
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <i className="cursor-default material-icons text-gray-500">visibility_off</i>
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            {detailList[i].type == "article" &&
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip>
                                                                                                            Consulter
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <i onClick={() => openArticleId(i)} className="cursor-pointer material-icons hover-bg-gray-200 rounded text-dark">exit_to_app</i>
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            {(granted(grants, "brouillons.edit.details")) &&
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip>
                                                                                                            Ajouter une ligne en dessous
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <i onClick={() => addUnder(i)} className="cursor-pointer material-icons hover-bg-gray-200 rounded text-dark">keyboard_double_arrow_down</i>
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                overlay={
                                                                                                    <Tooltip>
                                                                                                        Modifier
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <i onClick={() => editDetail(i)} className="cursor-pointer material-icons hover-bg-gray-200 rounded text-dark">edit</i>
                                                                                            </OverlayTrigger>

                                                                                            {granted(grants, "brouillons.edit.details.remove") &&
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip>
                                                                                                            Supprimer
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <i onClick={() => removeDetailConfirm(detail)} className="cursor-pointer material-icons hover-bg-gray-200 rounded text-dark">delete_forever</i>
                                                                                                </OverlayTrigger>
                                                                                            }

                                                                                        </span>
                                                                                    </td>
                                                                                }

                                                                            </>
                                                                        }

                                                                        {/* Ligne en mode edition */}
                                                                        {(editableIndex == i) &&
                                                                            <td className="p-0" style={snapshot.isDragging ? { display: "contents" } : {}} colSpan={tableHeaders.filter(header => !header.hidden).length + 2}>

                                                                                <div className={"w-100 px-3 py-3 my-2 bg-primary-200 rounded shadow "}
                                                                                >
                                                                                    <Row>

                                                                                        {/* Numéro de ligne et drag icon */}
                                                                                        <Col sm={"auto"} style={{ width: 60 }} className="fw-bold text-gray-500 py-0">
                                                                                            <span className="d-inline-flex align-items-center">
                                                                                                <span
                                                                                                    {...provided.dragHandleProps}
                                                                                                    {...{ ...provided.dragHandleProps, tabIndex: -1, }}>
                                                                                                    <i className="material-icons float-start ">drag_indicator</i>
                                                                                                    {i + 1}
                                                                                                </span>
                                                                                                <span className="ms-2 d-inline-flex flex-column">
                                                                                                    <i className={"material-icons hover-bg-primary-400 rounded px-1 float-start " + (i == 0 ? '' : 'cursor-pointer')} onClick={() => i != 0 && orderUpDown(i, -1)}>arrow_drop_up</i>
                                                                                                    <i className={"material-icons hover-bg-primary-400 rounded px-1 float-end " + (i == detailList.length - 1 ? '' : 'cursor-pointer')} onClick={() => i != detailList.length - 1 && orderUpDown(i, 1)}>arrow_drop_down</i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </Col>

                                                                                        {/* Ligne de "sous-total" */}
                                                                                        {detail.type == "total" &&
                                                                                            <Col>
                                                                                                <Form.Group>
                                                                                                    <Form.Label className="fw-bold mb-1 text-primary-600">Sous-total</Form.Label>
                                                                                                    <p className="m-0 text-gray-700"><em>- Calculé après validation de la ligne -</em></p>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        }

                                                                                        {/* Ligne de description */}
                                                                                        {detail.type == "commentaire" &&
                                                                                            <Col>
                                                                                                <Form.Group>
                                                                                                    <Form.Label className="fw-bold mb-1 text-primary-600">{tableHeaders.find(header => { return header.dataTarget == "designation" })['label']}</Form.Label>
                                                                                                    <Form.Control autoFocus onFocus={e => e.target.select()} name="designation" onChange={handleChange(i)} value={detail.designation} placeholder="Ecrire une désignation" />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        }

                                                                                        {/* Ligne d'article */}
                                                                                        {detail.type == "article" &&
                                                                                            <>
                                                                                                <Col sm={"auto"}>
                                                                                                    <Form.Label className="fw-bold mb-1 text-primary-600">{tableHeaders.find(header => { return header.dataTarget == "code" })['label']}</Form.Label>
                                                                                                    <span className="d-block">{detail.code}</span>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Form.Group>
                                                                                                        <Form.Label className="fw-bold mb-1 text-primary-600">{tableHeaders.find(header => { return header.dataTarget == "designation" })['label']}</Form.Label>
                                                                                                        <Form.Control disabled={!granted(grants, "brouillons.edit.details.designation")} name="designation" onChange={handleChange(i)} value={detail.designation} className="py-1 px-2 w-100 d-block" type="text" placeholder="Désignation de l'article" />
                                                                                                        {Object.entries(detail?.contributions).filter(([key, value]) => detail.contributions[key]?.type == "contrib_eco").map(([key, value], y) => (
                                                                                                            <Badge bg="success" text="white" key={y} className="my-1">
                                                                                                                <i className="material-icons me-1 d-inline-block">energy_savings_leaf</i>
                                                                                                                {detail.contributions[key].libelle}
                                                                                                            </Badge>
                                                                                                        ))}
                                                                                                    </Form.Group>

                                                                                                </Col>
                                                                                                <Col sm={"auto"} style={{ width: 120 }}>
                                                                                                    <Form.Group>
                                                                                                        <Form.Label className="fw-bold mb-1 text-primary-600">{tableHeaders.find(header => { return header.dataTarget == "quantite" })['label']}</Form.Label>
                                                                                                        <Form.Control min={granted(grants, "brouillons.edit.details.qte_negative") ? null : 0} autoFocus onFocus={e => e.target.select()} name="quantite" onChange={handleChange(i)} className="py-1 px-2" type="number" onWheel={(e) => e.target.blur()} value={detail.quantite} />
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                                <Col sm={"auto"} style={{ width: 140 }}>
                                                                                                    <Form.Group>
                                                                                                        <Form.Label className="fw-bold mb-1 text-primary-600">{tableHeaders.find(header => { return header.dataTarget == "prix_vente_ht" })['label']}</Form.Label>
                                                                                                        <Form.Control min={granted(grants, "brouillons.edit.details.prix_negatif") ? null : 0} disabled={!granted(grants, "brouillons.edit.details.prix_vente")} name="prix_vente_ht" onChange={handleChange(i)} className="py-1 px-2" type="number" onWheel={(e) => e.target.blur()} value={detail.prix_vente_ht} />
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                                <Col sm={"auto"} style={{ width: 120 }}>
                                                                                                    <Form.Label className="fw-bold mb-1 text-primary-600">{tableHeaders.find(header => { return header.dataTarget == "remise.taux" })['label']}</Form.Label>
                                                                                                    <InputGroup>
                                                                                                        <Form.Control disabled={!granted(grants, "brouillons.edit.details.remise")} onChange={handleChangeTauxRemise(i)} className="py-1 px-2" type="number" min="0" onWheel={(e) => e.target.blur()} value={detail.remise.taux} />
                                                                                                        <InputGroup.Text className="py-1 px-2">%</InputGroup.Text>
                                                                                                    </InputGroup>
                                                                                                </Col>
                                                                                                <Col sm={"auto"} style={{ width: 160 }}>
                                                                                                    {Object.entries(detail?.contributions)?.map(([key, value], y) => (
                                                                                                        <span key={detail.contributions[key].contribution_id}>
                                                                                                            {(detail.contributions[key]?.type == "taxe_vente") &&
                                                                                                                <>
                                                                                                                    <Form.Label className="fw-bold mb-1 text-primary-600">{tableHeaders.find(header => { return header.dataTarget == "contributions.valeur" })['label']}</Form.Label>
                                                                                                                    <Form.Select disabled={!granted(grants, "brouillons.edit.details.contribution")} className={"py-1 px-2 " + (y > 0 ? "mt-1" : "")} defaultValue={detail.contributions[key].contribution_id ? detail.contributions[key].contribution_id : ""} onChange={handleChangeContribution(i, y)}>
                                                                                                                        <option disabled value="">- choisir -</option>
                                                                                                                        {contributionList?.map(c => (
                                                                                                                            c.type == "taxe_vente" && <option key={c._id} value={c._id}>{c.libelle}</option>
                                                                                                                        ))}
                                                                                                                    </Form.Select>
                                                                                                                </>
                                                                                                            }
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </Col>
                                                                                            </>
                                                                                        }

                                                                                        {/* Actions */}
                                                                                        <Col sm={"auto"}>
                                                                                            <Form.Label className="fw-bold mb-1 text-primary-600">Actions</Form.Label>
                                                                                            <span className="d-flex">
                                                                                                <Button size="sm" variant="secondary" className="" onClick={() => removeDetailConfirm(detail)}>Suppr.</Button>
                                                                                                <Button size="sm" variant="secondary" className="ms-1" onClick={() => cancelChange()}>Fermer</Button>
                                                                                                <Button size="sm" className="ms-1 " id="validerBtn" onClick={() => updateDetails(detail)}>Valider</Button>
                                                                                            </span>
                                                                                        </Col>

                                                                                    </Row>
                                                                                </div>

                                                                            </td>
                                                                        }

                                                                    </tr>
                                                                    {addUnderLineNum == i &&
                                                                        <tr>
                                                                            <td colSpan={tableHeaders.filter(header => !header.hidden).length}>
                                                                                <div className="d-flex">
                                                                                    <ArticleSearch folderId={props.folderId} addElement={addElement} addDescription={addDescription} addSubtotal={addSubtotal} disabled={editableIndex != null} dropBottom={addUnderLineNum < 8 ? true : false} />
                                                                                    <Button variant="secondary" className="ms-3" onClick={() => setAddUnderLineNum()}>Annuler</Button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </>
                                                            )}


                                                        </Draggable>
                                                    ))}

                                                    {addDetailLoading &&
                                                        <tr className="w-100">
                                                            <td colSpan={tableHeaders.filter(header => !header.hidden).length + 1}>
                                                                <span className="hloader"></span>
                                                            </td>
                                                        </tr>
                                                    }

                                                    {provided.placeholder}

                                                </tbody>
                                            </Table>
                                        </div>
                                        {(granted(grants, "brouillons.edit.details") && addUnderLineNum == null) &&
                                            <ArticleSearch folderId={props.folderId} addElement={addElement} addDescription={addDescription} addSubtotal={addSubtotal} disabled={editableIndex != null} dropBottom={props.dropBottom} />
                                        }
                                    </>
                                )}
                            </Droppable>

                        </DragDropContext>
                    }
                </div>
            </div>

        </>
    );
}

export default Details;