// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Dossiers .card {
  margin-top: 0rem;
  margin-bottom: 1rem;
  transition: all 0.4s;
  text-decoration: none;
}
#Dossiers .card:hover {
  margin-top: -0.4rem;
  margin-bottom: 1.4rem !important;
  transition: all 0.2s;
  color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/dossiers/Dossiers.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,qBAAA;AAAR;AACQ;EACI,mBAAA;EACA,gCAAA;EACA,oBAAA;EACA,sBAAA;AACZ","sourcesContent":["#Dossiers{\n    .card{\n        margin-top: 0rem;\n        margin-bottom: 1rem;\n        transition: all .4s;\n        text-decoration: none;\n        &:hover{\n            margin-top: -0.4rem;\n            margin-bottom: 1.4rem !important;\n            transition: all .2s;\n            color: #fff !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
