import React, { useEffect, useState } from 'react';
import { Spinner, Card, Col, Row, InputGroup, FormControl, Badge, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover, Tabs, Tab } from 'react-bootstrap';
import { getBase64 } from '../../../../../../helper/Helper'


const Simplified = ({ facturesParams }) => {

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        facturesParams[e.target.name] = value
    }

    return (
        <div className="d-flex flex-column name-list">

            {!facturesParams &&
                <em>- Ce template ne nécessite aucune personnalisation -</em>
            }

            {facturesParams &&
                <>
                    <Form.Group className="mb-2">
                        <Form.Check type="switch" name="showObligationsPaiement" onChange={handleChange} defaultValue={facturesParams.showObligationsPaiement} defaultChecked={facturesParams.showObligationsPaiement} label="Afficher les informations de paiement." />
                    </Form.Group>
                </>
            }

        </div>

    );
}

export default Simplified;