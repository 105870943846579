import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, ToggleButton, OverlayTrigger, Tooltip, ButtonGroup, ToastContainer, Spinner } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { ReglementService } from '../../services/ReglementService';
import { EditionService } from '../../services/EditionService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import ReglementDetails from '../../components/side-details/reglement/reglement-details/ReglementDetails';
import ReglementEdition from '../../components/side-details/reglement/reglement-edition/ReglementEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Reglements.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, formattedDateInput, currencyFormat, getFile } from '../../helper/Helper'

const Reglements = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let reglementsParams = useRef({
    'start': 0,
    'limit': 50,
    'search': null,
    'filters': {},
    'sort': null,
    'order': null
  });

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [reglements, setReglements] = useState()
  const [isGetReglementsLoading, setIsGetReglementsLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentReglement, setCurrentReglement] = useState()
  const [isReglementEditing, setIsReglementEditing] = useState(false)
  const [reglementEditingId, setReglementEditingId] = useState()

  const [tableRowElementActions] = useState([
    { icon: "print", title: "Imprimer le reçu", action: "downloadReceiptPDF" }
  ])

  const [reglementElementActions] = useState([
    { icon: "print", title: "Imprimer le reçu", action: "downloadReceiptPDF" }
  ])

  useEffect(() => {
    if (grants) {

      !grants.reglements.valeur && setGlobalError({ statusText: `Reglements : vous n'avez pas les droit d'accès à cette ressource.` })

      /* granted(grants, "reglements.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeReglementDialog" })
      granted(grants, "reglements.remove") && reglementElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeReglementDialog" }) */
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('reglementId') && getReglement(searchParams.get('reglementId'))
      setPreferences()
      getReglements()
    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.reglements['tableHeaders'], parametres.reglements['fieldsLabel']))
    reglementsParams.current['sort'] = preferences.reglements['tableSort']
    reglementsParams.current['order'] = preferences.reglements['tableOrder']
    reglementsParams.current['fields'] = Object.keys(preferences.reglements['tableHeaders']).join(',')
  }

  function getDayTitle(place) {
    let date = reglementsParams.current?.filters['filter[date]']?.find(f => f.operation == "equal_to")?.value ? new Date(reglementsParams.current?.filters['filter[date]']?.find(f => f.operation == "equal_to")?.value) : new Date()
    var newDate = new Date(date.getTime())
    newDate.setDate(date.getDate() + place);
    var days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
    var dayName = days[newDate.getDay()];
    return `${dayName} ${newDate.getDate()}`
  }

  function getReglement(reglementId) {
    ReglementService.getReglement(folderDetails._id, reglementId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentReglement(res.data.data)
        setIsReglementEditing(false)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getReglements() {
    if (granted(grants, "reglements.consult")) {
      setIsGetReglementsLoading(true)
      ReglementService.getReglements(folderDetails._id, reglementsParams.current).then(res => {
        setReglements(res.data.data)
        setIsGetReglementsLoading(false)
      }).catch(error => {
        setIsGetReglementsLoading(false)
        setGlobalError(error)
      });
    }
  }

  function downloadReceiptPDF(reglement) {
    EditionService.getReglement(folderDetails._id, reglement._id, "pdf").then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        getFile(res.data, `Reçu`)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getReglements() {
    if (granted(grants, "reglements.consult")) {
      setIsGetReglementsLoading(true)
      ReglementService.getReglements(folderDetails._id, reglementsParams.current).then(res => {
        setReglements(res.data.data)
        setIsGetReglementsLoading(false)
      }).catch(error => {
        setIsGetReglementsLoading(false)
        setGlobalError(error)
      });
    }
  }

  function getReglementsPDF() {
    let params = {
      'start': 0,
      'search': reglementsParams.current.search,
      'sort': reglementsParams.current.sort,
      'order': reglementsParams.current.order,
      'filters': reglementsParams.current.filters
    }
    if (granted(grants, "reglements.consult")) {
      setIsPDFLoading(true)
      ReglementService.getReglementsPDF(folderDetails._id, params).then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Reglements - Liste`)
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }



  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['reglements']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentReglement(reglement) {
    reglement?._id ? setSearchParams({ reglementId: reglement._id }) : setSearchParams(searchParams.delete('reglementId'))
    setCurrentReglement(reglement)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    reglementsParams.current['start'] = (reglementsParams.current.limit * p) - reglementsParams.current.limit
    getReglements()
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      reglementsParams.current['start'] = 0
      reglementsParams.current['search'] = value
      getReglements()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      reglementsParams.current['start'] = 0
      reglementsParams.current['filters'] = f ? f : {}
      getReglements()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    reglementsParams.current['start'] = 0
    reglementsParams.current['filters'] = {}
    getReglements()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    reglementsParams.current['sort'] = s
    reglementsParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getReglements()
  }

  function onReglementSaved(articleId) {
    getReglement(articleId)
    getReglements()
  }

  function closeSide() {
    setIsReglementEditing(false)
    setCurrentReglement()
  }

  function cancelEdition() {
    setIsReglementEditing(false)
    !currentReglement && setSideWidth()
  }

  function createNewReglement() {
    setIsReglementEditing(true)
    setReglementEditingId()
  }

  function addFilterDate(value) {

    let showDate = reglementsParams.current?.filters['filter[date]']?.find(f => f.operation == "equal_to")?.value ? new Date(reglementsParams.current?.filters['filter[date]']?.find(f => f.operation == "equal_to")?.value + 'T12:12:00') : new Date()

    if (value == "today") {
      showDate = new Date()
    }

    if (value == "next") {
      showDate.setDate(showDate.getDate() + 1)
    }

    if (value == "prev") {
      showDate.setDate(showDate.getDate() - 1)
    }

    setIsTableFilterVisible(true)
    if (reglementsParams.current.filters) {
      reglementsParams.current.filters['filter[date]'] = [{ "operation": "equal_to", "operator": "=", "value": formattedDateInput(showDate) }]
    } else {
      reglementsParams.current.filters = { 'filter[date]': [{ "operation": "equal_to", "operator": "=", "value": formattedDateInput(showDate) }] }
    }

    let tableHeadersCopy = [...tableHeaders];
    tableHeadersCopy.find(t => t.dataTarget == "date").hidden = false
    setTableHeaders(tableHeadersCopy);

    setCurrentPagination(1)
    reglementsParams.current['start'] = 0
    getReglements()

  }

  function addFilterInProgress(e) {

    if (e.target.checked) {
      setIsTableFilterVisible(true)
      if (reglementsParams.current.filters) {
        reglementsParams.current.filters['filter[comptabilisee]'] = [{ "operation": "equal_to", "operator": "=", "value": 'false' }]
      } else {
        reglementsParams.current.filters = { 'filter[comptabilisee]': [{ "operation": "equal_to", "operator": "=", "value": 'false' }] }
      }
    } else {
      delete reglementsParams.current.filters['filter[comptabilisee]']
    }

    let tableHeadersCopy = [...tableHeaders];
    tableHeadersCopy.find(t => t.dataTarget == "comptabilisee").hidden = false
    setTableHeaders(tableHeadersCopy);

    setCurrentPagination(1)
    reglementsParams.current['start'] = 0
    getReglements()

  }



  return (
    <Container fluid id="Reglements" className="py-4">

      <ErrorManager error={globalError} fixed="true" />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row>
            {granted(grants, "reglements.consult") &&
              <>
                <Col xs="12" xl="3" className="mb-sm-2 mb-xl-0">
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="search"
                      onChange={e => changeSearchValue(e.target.value)}
                    />

                    {Object.keys(reglementsParams.current.filters).length == 0 &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                        <Button variant={Object.keys(reglementsParams.current.filters).length != 0 ? 'warning' : 'secondary'} onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                          <i className="material-icons i-lg">filter_alt</i>
                        </Button>
                      </OverlayTrigger>
                    }

                    {Object.keys(reglementsParams.current.filters).length != 0 &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Supprimer les filtres</Tooltip>}>
                        <Button variant={Object.keys(reglementsParams.current.filters).length != 0 ? 'warning' : 'secondary'} onClick={() => clearFilters()}>
                          <i className="material-icons i-lg">clear</i>
                        </Button>
                      </OverlayTrigger>
                    }

                  </InputGroup>
                </Col>

                <Col xs="12" xl="auto" className="d-flex align-items-center mb-sm-2 mb-xl-0">

                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="ms-2" onClick={() => getReglementsPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer
                  </Button>

                  <ButtonGroup className="ms-2">
                    <Button onClick={() => addFilterDate("today")} variant={reglementsParams.current?.filters && reglementsParams.current?.filters['filter[date]']?.find(f => f.operation == "equal_to")?.value == formattedDateInput(new Date()) ? "primary-300" : "secondary"} >Règlements du jour</Button>
                    <Button variant="secondary" onClick={() => addFilterDate("prev")}>
                      {getDayTitle(-1)}
                      <i className="material-icons float-start me-1">
                        arrow_back_ios_new
                      </i>
                    </Button>
                    <Button variant="secondary" onClick={() => addFilterDate("next")}>
                      {getDayTitle(1)}
                      <i className="material-icons float-end ms-1">
                        arrow_forward_ios
                      </i>
                    </Button>
                  </ButtonGroup>

                  <Form.Check
                    className="ms-3"
                    label="Paiements en cours uniquement"
                    type="switch"
                    onChange={e => addFilterInProgress(e)}
                    checked={reglementsParams.current?.filters && reglementsParams.current?.filters['filter[comptabilisee]']?.find(f => f.operation == "equal_to")?.value == 'false'}
                  />

                </Col>
              </>
            }

            <Col xs="12" xl="auto" className="mb-sm-2 mb-xl-0 ms-auto">
              {checkedElementIdList?.length > 0 &&
                <Dropdown className="d-inline-block me-2">
                  <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                    {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item>
                    <Dropdown.Item>Imprimer</Dropdown.Item>
                    {/*                     {granted(grants, "reglements.remove") &&
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => removeReglementListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                      </>
                    } */}
                  </Dropdown.Menu>
                </Dropdown>
              }
              {granted(grants, "reglements.edit") &&
                <Button variant="primary" onClick={() => createNewReglement()}>Créer un reglement</Button>
              }
            </Col>
          </Row>

          {granted(grants, "reglements.consult.totaux") &&
            <>
              <Row className="mt-3">
                <Col className="d-flex align-items-center">
                  <h6 className="m-0">Paiements par type :</h6>
                  {!reglements?.totaux &&
                    <em className="ms-3 mt-1">- Aucun -</em>
                  }
                </Col>
              </Row>

              {reglements?.totaux &&
                <Row className="mt-3">
                  <Col className="d-flex align-items-center">

                    <div className="rounded border px-3 py-2 bg-gray-900 text-white fw-bold me-2 ">
                      <span className="small w-100 float-start text-gray-200">Total général</span>
                      <span>{currencyFormat(reglements?.total_general[0]?.total)}</span>
                    </div>

                    {reglements?.totaux.map(total => (
                      <div key={total.mode_paiement_id} className="rounded border px-3 py-2 bg-white me-2">
                        <span className="small text-gray-500 w-100 float-start">{total.mode_paiement_libelle}</span>
                        <span>{currencyFormat(total.total)}</span>
                      </div>
                    ))}

                  </Col>
                </Row>
              }
            </>
          }

          {granted(grants, "reglements.consult") &&
            <Row className="mt-3">
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">
                  <DynamicTable
                    isDataLoading={isGetReglementsLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={reglements?.reglements}
                    currentElement={currentReglement}
                    changeCurrentElement={e => changeCurrentReglement(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={reglementsParams.current}
                  />
                  <Pagination itemsLength={reglements?.total} elementsPerPage={reglementsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />
                </div>
              </Col>
            </Row>
          }

          {(currentReglement || isReglementEditing) &&
            <SideDetails
              editMode={isReglementEditing}
              currentElement={isReglementEditing ? null : { id: currentReglement?._id, label: new Date(currentReglement?.date).toLocaleString(), title: currentReglement?.client_nom }}
              changeCurrentElement={e => changeCurrentReglement(e)}
              setSideClose={closeSide}
              setSideCancel={cancelEdition}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                isReglementEditing ?
                  <ReglementEdition folderId={folderDetails._id} reglementEditingId={reglementEditingId} onSetSaved={f => onReglementSaved(f)} />
                  :
                  <ReglementDetails folderId={folderDetails._id} reglementId={currentReglement._id} elementActions={reglementElementActions} setElementAction={(a, e) => eval(a)(e)} />
              }
            />
          }

        </Col>
      </Row>

    </Container>

  );
}

export default Reglements;