import React, { useEffect, useState } from 'react';
import { Spinner, Card, Col, Row, InputGroup, FormControl, Badge, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover, Tabs, Tab } from 'react-bootstrap';
import { getBase64 } from '../../../../../helper/Helper'
import ImageInput from '../../../../../components/form-inputs/image-input/ImageInput'


const Image = ({ globalParams }) => {

    useEffect(() => {

    }, []);

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        globalParams[e.target.name] = value
    }

    function handleChangeImg(e) {
        getBase64(e.target.files[0], (result) => {
            globalParams[e.target.name] = result
        });
    }

    return (

        <div className="d-flex flex-column name-list">

            {!globalParams &&
                <em>- Ce template ne nécessite aucune personnalisation -</em>
            }

            {globalParams &&
                <>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={6}>Image du footer :</Form.Label>
                        <Col sm={6}>
                            <ImageInput value={globalParams.footer} name="footer" setImage={i => (globalParams.footer = i)} />
                        </Col>
                    </Form.Group>

                </>
            }


        </div>

    );
}

export default Image;

