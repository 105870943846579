// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type-render-bubble-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.type-render-bubble-container .type-render-bubble-tag {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-left: 3px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.type-render-bubble-container .type-render-bubble-tag:hover {
  border: 1px solid rgba(55, 46, 99, 0.35);
}
.type-render-bubble-container .type-render-bubble-tag:hover .label {
  display: flex;
  position: absolute;
  white-space: nowrap;
  z-index: 9999;
  top: -24px;
  border-radius: 20px;
  padding: 0px 7px;
  font-size: 0.9rem;
  left: -5px;
}
.type-render-bubble-container .type-render-bubble-tag .label {
  display: none;
}

.type-render-bubble {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/table/dynamic-table/type-render/TypeRender.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AACJ;AACI;EACE,wCAAA;AACN;AACM;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,UAAA;AACR;AAGI;EACE,aAAA;AADN;;AAMA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AAHF","sourcesContent":[".type-render-bubble-container {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n\n  .type-render-bubble-tag {\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    position: relative;\n    margin-left: 3px;\n    margin-top: 2px;\n    margin-bottom: 2px;\n\n    &:hover {\n      border: 1px solid rgb(55 46 99 / 35%);\n\n      .label {\n        display: flex;\n        position: absolute;\n        white-space: nowrap;\n        z-index: 9999;\n        top: -24px;\n        border-radius: 20px;\n        padding: 0px 7px;\n        font-size: .9rem;\n        left: -5px;\n      }\n    }\n\n    .label {\n      display: none;\n    }\n  }\n}\n\n.type-render-bubble {\n  width: 24px;\n  height: 24px;\n  border-radius: 50px;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
