import { Link } from "react-router-dom";
import React, { Component, useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown, Dropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import './TopNav.scss';
import logo from '../../assets/img/logo/logo-facturexl.png'
import logoSmall from '../../assets/img/logo/logo-facturexl-small.png'
import { FolderService } from '../../services/FolderService'
import { UserService } from '../../services/UserService'
import { GlobalContext } from "../../services/GlobalProvider";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import { granted } from '../../helper/Helper'
import ShortCuts from './shortcuts/ShortCuts';

const TopNav = () => {

  const { userDetails, folderDetails, grants, folders, setFolderDetails, preferences, globalContextLoaded } = React.useContext(GlobalContext);

  const [currentPage, setCurrentPage] = useState()

  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let segment = location.pathname.split("/")[2];
    setCurrentPage(segment)
  }, [location.pathname]);

  function disconnect() {

    const dataPost = {
      token: localStorage.getItem('access_token'),
      client_secret: global.config._OAUTH_CLIENT_SECRET_,
      client_id: global.config._OAUTH_CLIENT_ID_
    }

    UserService.revoke(dataPost).then(res => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('openid');
      UserService.goToExternalConnection()
    })

  }

  function changeFolder(folder) {
    setFolderDetails(folder)
    navigate(`/${folder._id}/accueil`)
  }

  return (
    <>
      <Navbar id="topNav" bg="primary-700" variant="dark" expand="md" fixed="top" className="py-3">
        <Container fluid>
          <Navbar.Brand className="d-xl-none" href={`/${folderDetails?._id}/accueil`}><img className="me-2" src={logoSmall} alt="Logo XL Prog" /></Navbar.Brand>
          <Navbar.Brand className="d-none d-xl-block" href={`/${folderDetails?._id}/accueil`}><img className="me-2" src={logo} alt="Logo XL Prog" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="id-top-nav" /> {/* Utile au responsive */}
          <Navbar.Collapse id="id-top-nav">

            <Nav className="d-flex align-items-center me-auto">

              {folderDetails &&
                <>
                  <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "accueil" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/accueil`} >Accueil</Nav.Link>
                  {granted(grants, "articles") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "articles" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/articles`} >Articles</Nav.Link>}
                  {granted(grants, "clients") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "clients" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/clients`} >Clients</Nav.Link>}
                  {granted(grants, "tarifs") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "tarifs" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/tarifs`} >Tarifs</Nav.Link>}
                  {granted(grants, "factures") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "factures" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/factures`} >{preferences?.factures?.currentDraft && <i className="float-start material-icons me-1 text-warning">fiber_manual_record</i>}Factures</Nav.Link>}
                  {granted(grants, "reglements") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "reglements" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/reglements`} >Règlements</Nav.Link>}
                  {granted(grants, "proformas") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "proformas" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/proformas`} >{preferences?.proformas?.currentDraft && <i className="float-start material-icons me-1 text-warning">fiber_manual_record</i>}Proformas</Nav.Link>}
                  {granted(grants, "fournisseurs") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "fournisseurs" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/fournisseurs`} >Fournisseurs</Nav.Link>}
                  {granted(grants, "editions") && <Nav.Link className={"py-1 rounded me-1 " + (currentPage == "editions" ? "text-primary-800 bg-white fw-bold" : "")} as={Link} to={`${folderDetails._id}/editions`} >Editions</Nav.Link>}
                </>
              }

            </Nav>

            <Nav className="d-flex align-items-center">

              {folderDetails &&
                <>
                  {granted(grants, "admin") &&
                    <Nav.Link className={"fw-bold py-1 rounded me-2 d-flex " + (currentPage == "administration" ? "text-white bg-primary-500 " : "")} as={Link} to={folderDetails._id + "/administration"} >
                      <i className="material-icons me-2">settings</i>
                      Admin
                    </Nav.Link>
                  }
                </>
              }

              {folders?.length > 1 &&
                <Dropdown align="end" className="me-2">
                  <Dropdown.Toggle variant="primary-800">
                    <i className="material-icons me-2 float-start">folder</i>
                    {folderDetails?.nom ? folderDetails?.nom : "Dossiers"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {folders.map((folder, i) => (
                      <Dropdown.Item key={i} onClick={() => changeFolder(folder)}>{folder.nom}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              }

              {userDetails &&
                <>
                  <Dropdown align="end" className="me-2">
                    <Dropdown.Toggle variant="primary-800">
                      <i className="material-icons me-2 float-start">person</i>
                      {userDetails?.identifiant}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.ItemText>{userDetails?.email}</Dropdown.ItemText>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => disconnect()}>Déconnexion</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>


                  <ShortCuts />
                </>
              }

              {!userDetails &&
                <Nav.Link onClick={() => UserService.goToExternalConnection()}>Se connecter</Nav.Link>
              }

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );

}

export default TopNav;