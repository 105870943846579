import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useOuterClick, currencyFormat, deepFind, isDark } from '../../../../helper/Helper'
import { InputGroup, FloatingLabel, Badge, FormControl, Table, Checkbox, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';
import './TypeRender.scss';

const TypeRender = ({ header, tableBodyElement }) => {

    function getValueListFromPathWithArray(tableBodyElement, dataTarget) {

        let valuesArray = []

        let pathList = dataTarget.split(".")

        for (let index = 0; index < pathList.length; index++) {
            let currentPath = pathList.slice(0, index + 1).join(".")
            let elemFound = deepFind(tableBodyElement, currentPath)
            if (Array.isArray(elemFound)) {
                elemFound.map(e => {
                    let lastElemString = pathList[pathList.length - 1]
                    valuesArray.push(e[lastElemString])
                })
            }
        }

        return valuesArray

    }


    switch (header.type) {
        case 'img':
            return <span className="picture rounded" style={{ backgroundImage: `url(${tableBodyElement[header.dataTarget]})` }}></span>;
        case 'contributions':
            return (tableBodyElement[header.dataTarget].map(contribution => (
                <small key={contribution._id} className="bg-gray-100 m-1 px-1 rounded d-inline-block">{contribution.libelle} </small>
            )))
        case 'codesbarres':
            return (tableBodyElement[header.dataTarget].map(codeBarre => (
                <small key={codeBarre} className="bg-gray-100 m-1 px-1 rounded d-inline-block">{codeBarre} </small>
            )))
        case 'tags':
            return <span className="type-render-bubble-container">
                {
                    tableBodyElement[header.dataTarget].map(tag => (
                        <span key={tag._id} className="type-render-bubble-tag" style={{ backgroundColor: tag.couleur }}>
                            <span style={{ backgroundColor: tag.couleur }} className={"label " + (isDark(tag.couleur) ? "text-dark" : "text-white")}>{tag.tag}</span>
                        </span>
                    ))
                }
            </span>
        case 'tarifs': /* N'est plus utilisé ? */
            return (tableBodyElement[header.dataTarget].map(tarif => (
                <small key={tarif} className="bg-gray-100 m-1 px-1 rounded d-inline-block">{tarif.tarif_id}</small>
            )))
        case 'date':
            return <>{deepFind(tableBodyElement, header.dataTarget) ? new Date(deepFind(tableBodyElement, header.dataTarget)).toLocaleString() : ""}</>;
        case 'etatProforma':
            if (deepFind(tableBodyElement, header.dataTarget) == "Expirée") {
                return <Badge bg="warning" className="text-dark">{deepFind(tableBodyElement, header.dataTarget)}</Badge>
            }
            if (deepFind(tableBodyElement, header.dataTarget) == "Annulée") {
                return <Badge bg="danger">{deepFind(tableBodyElement, header.dataTarget)}</Badge>
            }
            if (deepFind(tableBodyElement, header.dataTarget) == "Facturée") {
                return <Badge bg="primary-500">{deepFind(tableBodyElement, header.dataTarget)}</Badge>
            }
            return null
        case 'string':
            return <>{deepFind(tableBodyElement, header.dataTarget)}</>;
        case 'number':
            return <>{deepFind(tableBodyElement, header.dataTarget)}</>;
        case 'gps':

            if (deepFind(tableBodyElement, header.dataTarget)[0] || deepFind(tableBodyElement, header.dataTarget)[1]) {
                return <>

                    <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={
                        <Popover>
                            <Popover.Body>
                                <span className="me-2 d-block"><b>Latitude : </b>{deepFind(tableBodyElement, header.dataTarget)[0]}</span>
                                <span className="me-2 d-block"><b>Longitude : </b>{deepFind(tableBodyElement, header.dataTarget)[1]}</span>
                            </Popover.Body>
                        </Popover>
                    }>

                        <i className="material-icons d-inline-flex text-gray-600 bg-gray-200 px-1 me-1 type-render-bubble">location_on</i>
                    </OverlayTrigger>


                </>

            } else {
                return null
            }


        case 'taux_remise':
            return <>{deepFind(tableBodyElement, header.dataTarget) > 0 && deepFind(tableBodyElement, header.dataTarget) + '%'}</>;
        case 'genericList':
            let valuesArray = getValueListFromPathWithArray(tableBodyElement, header.dataTarget)
            return (valuesArray.map((val, i) => (
                <small key={i} className="bg-gray-100 m-1 px-1 rounded d-inline-block">{val}</small>
            )))
        case 'dateList':
            let valuesArrDate = getValueListFromPathWithArray(tableBodyElement, header.dataTarget)
            return (valuesArrDate.map((val, i) => (
                <small key={i} className="bg-gray-100 m-1 px-1 rounded d-inline-block">{new Date(val).toLocaleString()}</small>
            )))
        case 'html':
            if (tableBodyElement[header.dataTarget]) {
                return <Badge bg="secondary">Élement HTML</Badge>;
            } else {
                return null
            }
        case 'currency':
            return <>{currencyFormat(deepFind(tableBodyElement, header.dataTarget))}</>;
        case 'currencyHighlightNegative':
            return <span className={"px-1 " + (deepFind(tableBodyElement, header.dataTarget) < 0 ? "bg-danger text-white rounded" : "")}>{currencyFormat(deepFind(tableBodyElement, header.dataTarget))}</span>;
        case 'bloquer':
            if (deepFind(tableBodyElement, header.dataTarget) == true) {
                return <Badge bg="danger" text='white' className="align-items-center d-inline-flex"><i className="material-icons me-1">warning</i>Bloqué</Badge>
            }
            return null
        case 'boolean':
            if (deepFind(tableBodyElement, header.dataTarget) == true) {
                return <span className="py-1 px-2 text-success d-flex"><i className="material-icons me-1">check</i>Oui</span>;
            }
            if (deepFind(tableBodyElement, header.dataTarget) == false) {
                return <span className="py-1 px-2 text-danger d-flex"><i className="material-icons me-1">close</i>Non</span>;
            }
            return null
        case 'booleanTrueOnly':
            if (deepFind(tableBodyElement, header.dataTarget) == true) {
                return <span className="py-1 px-2 text-success d-flex"><i className="material-icons me-1">check</i>Oui</span>;
            }
            return null
        case 'booleanFalseOnly':
            if (deepFind(tableBodyElement, header.dataTarget) == false) {
                return <span className="py-1 px-2 text-danger d-flex"><i className="material-icons me-1">close</i>Non</span>;
            }
            return null
        case 'reglements':
            return (tableBodyElement[header.dataTarget].map(reglement => (
                <OverlayTrigger key={reglement._id} trigger={["hover", "focus"]} placement="top" overlay={
                    <Popover>
                        <Popover.Header as="h3">{reglement.client_nom}</Popover.Header>
                        <Popover.Body>
                            <span className="me-2 d-block"><b>Date : </b>{new Date(reglement.date).toLocaleString()}</span>
                            <span className="me-2 d-block"><b>Montant : </b>{reglement.montant}</span>
                            <span className="me-2 d-block"><b>Mode de paiement : </b>{reglement.mode_paiement_libelle}</span>
                        </Popover.Body>
                    </Popover>
                }>
                    <i className="material-icons d-inline-flex text-gray-600 bg-gray-200 px-1 me-1 type-render-bubble">credit_card</i>
                </OverlayTrigger>
            )));
        case 'fournisseurs':
            return (tableBodyElement[header.dataTarget].map((fournisseur, i) => (
                <span key={i}>
                    {fournisseur.fournisseur_id &&
                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={
                            <Popover>
                                <Popover.Header as="h3">{fournisseur.fournisseur[0]?.nom}</Popover.Header>
                                <Popover.Body>
                                    {fournisseur.date_achat && <span className="me-2 d-block"><b>Date d'achat : </b>{new Date(fournisseur.date_achat).toLocaleString()}</span>}
                                    {fournisseur.prix_achat && <span className="me-2 d-block"><b>Prix d'achat : </b>{fournisseur.prix_achat}</span>}
                                    {fournisseur.devise && <span className="me-2 d-block"><b>Devise : </b>{fournisseur.devise}</span>}
                                </Popover.Body>
                            </Popover>
                        }>
                            <i className="material-icons d-inline-flex text-gray-600 bg-gray-200 px-1 me-1 type-render-bubble">local_shipping</i>
                        </OverlayTrigger>
                    }
                </span>
            )));
        case 'observation':
            return (
                <>
                    {tableBodyElement[header.dataTarget] &&
                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={
                            <Popover>
                                <Popover.Body>
                                    <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(tableBodyElement[header.dataTarget])))} readOnly={true} />
                                </Popover.Body>
                            </Popover>
                        }>
                            <i className="material-icons d-inline-flex text-gray-600 bg-gray-200 px-1 me-1 type-render-bubble">wysiwyg</i>
                        </OverlayTrigger>
                    }
                </>
            );
        case 'facture':
            return (
                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={
                    <Popover>
                        <Popover.Body>
                            {tableBodyElement[header.dataTarget].client_nom && <span className="me-2 d-block"><b>Client : </b>{tableBodyElement[header.dataTarget].client_nom}</span>}
                            {tableBodyElement[header.dataTarget].code && <span className="me-2 d-block"><b>Code facture : </b>{tableBodyElement[header.dataTarget].code}</span>}
                        </Popover.Body>
                    </Popover>
                }>
                    <i className="material-icons d-inline-flex text-gray-600 bg-gray-200 px-1 me-1 type-render-bubble">receipt_long</i>
                </OverlayTrigger>
            );
        case 'adresses':
            return (deepFind(tableBodyElement, header.dataTarget).map(adresse => (
                <OverlayTrigger key={adresse._id} trigger={["hover", "focus"]} placement="top" overlay={
                    <Popover>
                        {adresse.libelle && <Popover.Header as="h3">{adresse.libelle}</Popover.Header>}
                        <Popover.Body>
                            {adresse.adresse && <span className="me-2 d-block"><b>Adresse : </b>{adresse.adresse}</span>}
                            {adresse.libelle && <span className="me-2 d-block"><b>Libelle : </b>{adresse.libelle}</span>}
                            {adresse.code_postal && <span className="me-2 d-block"><b>CP : </b>{adresse.code_postal}</span>}
                            {adresse.ville && <span className="me-2 d-block"><b>Ville : </b>{adresse.ville}</span>}
                            {adresse.pays && <span className="me-2 d-block"><b>Pays : </b>{adresse.pays}</span>}
                            {adresse.principal && <span className="me-2 d-block"><b>Adresse principale : </b>{adresse.principal ? 'oui' : 'non'}</span>}
                            {adresse.observation && <span className="me-2 d-block"><b>Observation : </b>{adresse.observation}</span>}
                        </Popover.Body>
                    </Popover>
                }>
                    <i className="material-icons d-inline-flex text-gray-600 bg-gray-200 px-1 me-1 type-render-bubble">home</i>

                </OverlayTrigger>
            )));
        case 'interlocuteurs':
            return (deepFind(tableBodyElement, header.dataTarget).map(interlocuteur => (
                <OverlayTrigger key={interlocuteur._id} trigger={["hover", "focus"]} placement="top" overlay={
                    <Popover>
                        <Popover.Header as="h3">{interlocuteur?.nom} {interlocuteur?.prenom}</Popover.Header>
                        <Popover.Body>
                            {interlocuteur.service && <span className="me-2 d-block"><b>Service : </b>{interlocuteur.service}</span>}
                            <span className="me-2 d-block"><b>Interlocuteur principal : </b>{interlocuteur.principal ? 'oui' : 'non'}</span>
                            {interlocuteur.observation && <span className="me-2 d-block"><b>Observation : </b>{interlocuteur.observation}</span>}
                        </Popover.Body>
                    </Popover>
                }>
                    <i className="material-icons d-inline-flex text-gray-600 bg-gray-200 px-1 me-1 type-render-bubble">person</i>
                </OverlayTrigger>
            )));
        case 'historiques':
            return (
                <span>
                    <span className="d-flex align-items-center" style={{ fontSize: "0.75em" }}>

                        {tableBodyElement[header.dataTarget].precedents?.proforma &&
                            <>
                                <span className="px-2 py-1 bg-gray-200 text-gray-600 rounded fw-bolder">P</span>
                                <i className="material-icons d-inline-block text-gray-500 m-n1 bg-white border rounded zindex-10">arrow_right</i>
                            </>
                        }
                        {tableBodyElement[header.dataTarget].precedents?.facture &&
                            <>
                                <span className="px-2 py-1 bg-gray-200 text-gray-600 rounded fw-bolder">F</span>
                                <i className="material-icons d-inline-block text-gray-500 m-n1 bg-white border rounded zindex-10">arrow_right</i>
                            </>
                        }
                        {tableBodyElement[header.dataTarget].precedents?.avoir &&
                            <>
                                <span className="px-2 py-1 bg-gray-200 text-gray-600 rounded fw-bolder">A</span>
                                <i className="material-icons d-inline-block text-gray-500 m-n1 bg-white border rounded zindex-10">arrow_right</i>
                            </>
                        }

                        {(tableBodyElement[header.dataTarget].precedents?.avoir || tableBodyElement[header.dataTarget].precedents?.proforma || tableBodyElement[header.dataTarget].precedents.facture || tableBodyElement[header.dataTarget].suivants.avoir || tableBodyElement[header.dataTarget].suivants.facture || tableBodyElement[header.dataTarget].suivants.proforma) &&
                            <span className="px-2 py-1 bg-primary-300 text-primary-600 rounded fw-bolder border border-primary-400">
                                <i className="material-icons d-inline-block">insert_drive_file</i>
                            </span>
                        }

                        {tableBodyElement[header.dataTarget].suivants?.avoir &&
                            <>
                                <i className="material-icons d-inline-block text-gray-500 m-n1 bg-white border rounded zindex-10">arrow_right</i>
                                <span className="px-2 py-1 bg-gray-200 text-gray-600 rounded fw-bolder">A</span>
                            </>
                        }
                        {tableBodyElement[header.dataTarget].suivants?.facture &&
                            <>
                                <i className="material-icons d-inline-block text-gray-500 m-n1 bg-white border rounded zindex-10">arrow_right</i>
                                <span className="px-2 py-1 bg-gray-200 text-gray-600 rounded fw-bolder">F</span>
                            </>
                        }
                        {tableBodyElement[header.dataTarget].suivants?.proforma &&
                            <>
                                <i className="material-icons d-inline-block text-gray-500 m-n1 bg-white border rounded zindex-10">arrow_right</i>
                                <span className="px-2 py-1 bg-gray-200 text-gray-600 rounded fw-bolder">P</span>
                            </>
                        }
                    </span>

                    {/*                     {(!tableBodyElement[header.dataTarget].precedents.avoir && !tableBodyElement[header.dataTarget].precedents.facture && !tableBodyElement[header.dataTarget].suivants.avoir && !tableBodyElement[header.dataTarget].suivants.facture) &&
                        <em>- Aucun document lié -</em>
                    } */}
                </span>
            );
        default:
            return <>--- Type Inconnu</>;
    }
}

export default TypeRender;