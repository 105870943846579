import React, { useEffect, useState } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Badge, Label, Modal, Spinner, Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';

const ImageInput = (props) => {

    const [image, setImage] = useState(props.value);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
    }, []);

    useEffect(() => {
        props.setImage({
            target: {
                name: props.name,
                value: image ? image : ""
            }
        })
    }, [image]);

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function handleChangeImg(e) {
        getBase64(e.target.files[0], (result) => {
            setImage(result)
            forceUpdate();
        });
    }

    function removeImg(e) {
        setImage()
        forceUpdate();
    }

    return (
        <>
            {!image &&
                <Form.Control onChange={handleChangeImg} name="background" className="mb-1" type="file" accept="image/*" />
            }
            {image &&
                <div className="border rounded p-2 d-flex flex-column w-75">
                    <img className="w-100 d-inline-block mb-2" src={image} alt="" />
                    <Button variant="outline-danger" size="sm" onClick={() => removeImg()}>Supprimer l'image</Button>
                </div>
            }
        </>
    )
}
export default ImageInput;

