import React, { useEffect, useState } from 'react';
import { Spinner, Card, Col, Row, InputGroup, FormControl, ButtonGroup, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover, Tabs, Tab } from 'react-bootstrap';
import ErrorManager from "../../../../../components/error-manager/ErrorManager";
import { getBase64 } from '../../../../../helper/Helper'
import ImageInput from '../../../../../components/form-inputs/image-input/ImageInput'


const Default = ({ globalParams }) => {

    useEffect(() => {

    }, []);

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        globalParams[e.target.name] = value
    }

    function handleChangeImg(e) {
        getBase64(e.target.files[0], (result) => {
            globalParams[e.target.name] = result
        });
    }

    return (

        <div className="d-flex flex-column name-list">

            {!globalParams &&
                <em>- Ce template ne nécessite aucune personnalisation -</em>
            }

            {globalParams &&
                <>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={6}>Logo :</Form.Label>
                        <Col sm={6}>
                            <ImageInput value={globalParams.logo} name="logo" setImage={i => (globalParams.logo = i)} />
                            <Form.Check type="switch" name="reverseLeftRightLogo" onChange={handleChange} defaultValue={globalParams.reverseLeftRightLogo} defaultChecked={globalParams.reverseLeftRightLogo} label="Aligner le logo à droite." />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={6}>Taille du logo :</Form.Label>
                        <Col sm={6}>
                            <Form.Control type="number" name="logoSize" onChange={handleChange} defaultValue={globalParams.logoSize} />
                        </Col>
                    </Form.Group>
 
                </>
            }


        </div>

    );
}

export default Default;