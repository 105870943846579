import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, Nav, Tab } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { FournisseurService } from '../../services/FournisseurService';
import { FactureService } from '../../services/FactureService';
import { ParametreService } from '../../services/ParametreService';
import { PreferenceService } from '../../services/PreferenceService';
import { UsersProfilService } from '../../services/UsersProfilService';
import { ClientService } from '../../services/ClientService';
import { GlobalContext } from "../../services/GlobalProvider";
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, granted } from '../../helper/Helper'

import RecursiveConfig from './recursive-config/RecursiveConfig'
import UsersProfils from './users-profils/UsersProfils'
import ModesReglements from './modes-reglements/ModesReglements'
import PersonnaliserTheme from './personnaliser-theme/PersonnaliserTheme'
import Contributions from './contributions/Contributions'
import Editions from './editions/Editions'

import './Administration.scss';

export default function Administration() {

    let [openElement, setOpenElement] = useState("params-dossier")

    const { grants, apiVersion } = React.useContext(GlobalContext);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    let fields = ["general", "articles", "brouillons", "clients", "contributions", "factures", "familles", "fournisseurs", "proformas", "reglements", "tags", "tarifs"]

    return (
        <>
            <div id="Administration" className="container-fluid">

                <Tab.Container id="left-tabs-example" defaultActiveKey="params-dossier" onSelect={handleTabSelect}>
                    <Row>
                        <Col sm={3} xl={2}>
                            <div className="height-full-min-80 pt-5 pb-3">
                                <div className="light-v-scroll">
                                    <Nav className="d-block">

                                        {granted(grants, "admin.access_utils") &&
                                            <>
                                                <Nav.Link className="text-dark py-1 px-2 fw-bold d-flex cursor-default"><i className="material-icons me-2 d-flex">lock</i>Paramètres</Nav.Link>
                                                <Nav.Link eventKey="params-dossier" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Dossier</Nav.Link>
                                                <Nav.Link eventKey="params-userprofil" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex"><i className="material-icons me-1">subdirectory_arrow_right</i>Profils utilisateur</Nav.Link>
                                                <Nav.Link className="text-dark py-1 px-2 fw-bold d-flex cursor-default mt-3"><i className="material-icons me-2 d-flex">settings</i>Préférences</Nav.Link>
                                                <Nav.Link eventKey="prefs-dossier" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Dossier</Nav.Link>
                                                <Nav.Link eventKey="prefs-userProfil" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex"><i className="material-icons me-1">subdirectory_arrow_right</i>Profils utilisateur</Nav.Link>
                                                <Nav.Link eventKey="prefs-user" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex"><i className="material-icons ms-3 me-1">subdirectory_arrow_right</i>Vos préférences</Nav.Link>
                                            </>
                                        }

                                        {granted(grants, "admin.admin_dossier") &&
                                            <>
                                                <Nav.Link className="text-dark py-1 px-2 fw-bold d-flex mt-3 cursor-default"><i className="material-icons me-2 d-flex">person</i>Utilisateurs</Nav.Link>
                                                <Nav.Link eventKey="users-profils" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Profils & utilisateurs</Nav.Link>
                                            </>
                                        }

                                        <>
                                            <Nav.Link className="text-dark py-1 px-2 fw-bold d-flex mt-3 cursor-default"><i className="material-icons me-2 d-flex">payment</i>Financier</Nav.Link>
                                            <Nav.Link eventKey="modes-reglements" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Modes de règlements</Nav.Link>
                                            <Nav.Link eventKey="contributions" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Contributions</Nav.Link>
                                        </>

                                        <>
                                            <Nav.Link className="text-dark py-1 px-2 fw-bold d-flex mt-3 cursor-default"><i className="material-icons me-2 d-flex">palette</i>Theme (Old)</Nav.Link>
                                            <Nav.Link eventKey="personnaliser-theme" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Personnaliser le theme</Nav.Link>
                                        </>

                                        <>
                                            <Nav.Link className="text-dark py-1 px-2 fw-bold d-flex mt-3 cursor-default"><i className="material-icons me-2 d-flex">newspaper</i>Editions</Nav.Link>
                                            <Nav.Link eventKey="edition-theme" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Theme</Nav.Link>
                                            <Nav.Link eventKey="edition-facturation-commun" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex">Facturation</Nav.Link>
                                            <Nav.Link eventKey="edition-facturation-factures" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex"><i className="material-icons me-1">subdirectory_arrow_right</i>Factures</Nav.Link>
                                            <Nav.Link eventKey="edition-facturation-proformas" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex"><i className="material-icons me-1">subdirectory_arrow_right</i>Proformas</Nav.Link>
                                            <Nav.Link eventKey="edition-facturation-recus" className="rounded text-dark cursor-pointer py-1 px-2 ms-4 d-flex"><i className="material-icons me-1">subdirectory_arrow_right</i>Reçus</Nav.Link>
                                        </>

                                        <span className="d-block text-gray-500 mt-4 ms-2">API {apiVersion}</span>

                                    </Nav>
                                </div>
                            </div>
                        </Col>

                        <Col sm={9} xl={10}>
                            <Tab.Content>

                                {/* ----------------------------------------------------------- */}
                                <Tab.Pane eventKey="params-dossier">
                                    {openElement == "params-dossier" &&
                                        <RecursiveConfig
                                            source="dossier"
                                            apiObject="parametres"
                                            fieldList={fields}
                                            getListeService={ParametreService.getParametresListe}
                                            getFolderService={ParametreService.getParametresDossier}
                                            //getUsersProfilService={ParametreService.getParametresUsersProfil}
                                            //getService={ParametreService.getPreferences}
                                            removeService={ParametreService.removeParametresDossier}
                                            updateService={ParametreService.updateParametresDossier}
                                        />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="params-userprofil">
                                    {openElement == "params-userprofil" &&
                                        <RecursiveConfig
                                            source="userprofil"
                                            apiObject="parametres"
                                            fieldList={fields}
                                            getListeService={ParametreService.getParametresListe}
                                            getFolderService={ParametreService.getParametresDossier}
                                            getUserProfilService={ParametreService.getParametresUserProfil}
                                            //getService={ParametreService.getPreferences}
                                            removeService={ParametreService.removeParametresUserProfil}
                                            updateService={ParametreService.updateParametresUserProfil}
                                        />
                                    }
                                </Tab.Pane>

                                {/* ----------------------------------------------------------- */}
                                <Tab.Pane eventKey="prefs-dossier">
                                    {openElement == "prefs-dossier" &&
                                        <RecursiveConfig
                                            source="dossier"
                                            apiObject="preferences"
                                            fieldList={fields}
                                            getListeService={PreferenceService.getPreferencesListe}
                                            getFolderService={PreferenceService.getPreferencesDossier}
                                            //getUsersProfilService={PreferenceService.getPreferencesUsersProfil}
                                            //getService={PreferenceService.getPreferences}
                                            removeService={PreferenceService.removePreferencesDossier}
                                            updateService={PreferenceService.updatePreferencesDossier}
                                        />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="prefs-userProfil">
                                    {openElement == "prefs-userProfil" &&
                                        <RecursiveConfig
                                            source="userprofil"
                                            apiObject="preferences"
                                            fieldList={fields}
                                            getListeService={PreferenceService.getPreferencesListe}
                                            getFolderService={PreferenceService.getPreferencesDossier}
                                            getUserProfilService={PreferenceService.getPreferencesUserProfil}
                                            //getService={PreferenceService.getPreferences}
                                            removeService={PreferenceService.removePreferencesUserProfil}
                                            updateService={PreferenceService.updatePreferencesUserProfil}
                                        />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="prefs-user">
                                    {openElement == "prefs-user" &&
                                        <RecursiveConfig
                                            source="user"
                                            apiObject="preferences"
                                            fieldList={fields}
                                            getListeService={PreferenceService.getPreferencesListe}
                                            getFolderService={PreferenceService.getPreferencesDossier}
                                            getUsersProfilService={PreferenceService.getPreferencesUsersProfil}
                                            getService={PreferenceService.getPreferences}
                                            removeService={PreferenceService.removePreferences}
                                            updateService={PreferenceService.updatePreferences}
                                        />
                                    }
                                </Tab.Pane>

                                {/* ----------------------------------------------------------- */}
                                <Tab.Pane eventKey="users-profils">
                                    {openElement == "users-profils" &&
                                        <UsersProfils />
                                    }
                                </Tab.Pane>

                                {/* ----------------------------------------------------------- */}
                                <Tab.Pane eventKey="modes-reglements">
                                    {openElement == "modes-reglements" &&
                                        <ModesReglements />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="contributions">
                                    {openElement == "contributions" &&
                                        <Contributions />
                                    }
                                </Tab.Pane>

                                {/* ----------------------------------------------------------- */}
                                <Tab.Pane eventKey="personnaliser-theme">
                                    {openElement == "personnaliser-theme" &&
                                        <PersonnaliserTheme />
                                    }
                                </Tab.Pane>

                                {/* ----------------------------------------------------------- */}
                                <Tab.Pane eventKey="edition-theme">
                                    {openElement == "edition-theme" &&
                                        <Editions tplTitle={"Personnalisation globale"} paramsPath={"editions.global"} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="edition-facturation-commun">
                                    {openElement == "edition-facturation-commun" &&
                                        <Editions tplTitle={"Templates communs"} paramsPath={"editions.facturation.commun"} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="edition-facturation-factures">
                                    {openElement == "edition-facturation-factures" &&
                                        <Editions tplTitle={"Personnalisez vos factures"} paramsPath={"editions.facturation.factures"} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="edition-facturation-proformas">
                                    {openElement == "edition-facturation-proformas" &&
                                        <Editions tplTitle={"Personnalisez vos proformas"} paramsPath={"editions.facturation.proformas"} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="edition-facturation-recus">
                                    {openElement == "edition-facturation-recus" &&
                                        <Editions tplTitle={"Personnalisez vos reçus"} paramsPath={"editions.facturation.recus"} />
                                    }
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>


            </div>
        </>
    );
}