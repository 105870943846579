import React, { useEffect, useState } from 'react';
import { ReglementService } from '../../../../services/ReglementService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg, currencyFormat } from '../../../../helper/Helper'
//import './ReglementDetails.scss';
import { TarifService } from '../../../../services/TarifService';


const ReglementDetails = (props) => {

    let [reglement, setReglement] = useState()

    const [getReglementLoading, setGetReglementLoading] = useState()

    useEffect(() => {
        setReglement()
        getReglement()
    }, [props.reglementId]);

    function getReglement() {
        setGetReglementLoading(true)
        ReglementService.getReglement(props.folderId, props.reglementId).then(res => {
            setReglement(res.status == 200 ? res.data.data : { warning: res.statusText })
            setGetReglementLoading(false)
        }).catch(error => {
            setReglement({ error: error })
            setGetReglementLoading(false)
        });
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    return (
        <div id="ReglementDetails">

            {(!reglement && getReglementLoading) &&
                <span className="hloader mt-3"></span>
            }

            {reglement?.warning &&
                <div className="d-inline-block w-100 mt-4">
                    <Alert variant="warning">
                        {reglement?.warning}
                    </Alert>
                </div>
            }

            {reglement?.error &&
                <div className="d-inline-block w-100 mt-4">
                    <ErrorManager error={reglement?.error} />
                </div>
            }

            {
                (reglement && !reglement.warning && !reglement.error) && (

                    <>
                        <div className="d-flex mb-4 align-items-center">
                            <div className="me-auto">
                                <h6 className="fw-bolder m-0">{reglement.client_nom}</h6>
                                <h6 className="fw-bolder m-0 text-secondary">{new Date(reglement.date).toLocaleString()}</h6>
                            </div>
                            {props.elementActions.length > 0 &&
                                <Dropdown className="float-end ms-2 me-3">
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Plus d'actions
                                            </Tooltip>
                                        }
                                    >
                                        <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                            <i className="material-icons i-lg">more_horiz</i>
                                        </Dropdown.Toggle>
                                    </OverlayTrigger>
                                    <Dropdown.Menu>
                                        {props.elementActions.map((element) => (
                                            <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, reglement)}>{element.title}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>

                        <div className="light-v-scroll height-full-min-250 pe-2">

                            <Split title="Nom client" border value={reglement.client_nom} />
                            {reglement.facture_id && <Split title="Facture" border value={<a href={`/${props.folderId}/factures?factureId=${reglement.facture_id}`} target="_blank">Afficher la facture</a>} />}
                            <Split title="Date" border value={reglement.date} />
                            <Split title="Date valeur (date d'exécution)" border value={reglement.date_valeur} />
                            <Split title="Mode de paiement" border value={reglement.mode_paiement_libelle} />
                            <Split title="Numéro de pièce" border value={reglement.numero_piece} />
                            <Split title="Organisme" border value={reglement.organisme} />
                            <Split title="Montant" border value={currencyFormat(reglement.montant)} />
                            <Split title="Observation" border value={reglement.observation} />

                        </div>

                    </>

                )
            }
        </div>

    )

}

export default ReglementDetails;