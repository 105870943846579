import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, InputGroup, Row, Badge, Label, Table, Tooltip, Spinner, OverlayTrigger, FormControl } from 'react-bootstrap';
import { ContributionService } from '../../../../../../services/ContributionService';
import { ClientService } from '../../../../../../services/ClientService';
import Pagination from '../../../../../../components/pagination/Pagination';
import './ClientInput.scss';
import { useOuterClick, currencyFormat, granted, formattedDateInput, getFormErrorMsg } from '../../../../../../helper/Helper'
import ErrorManager from "../../../../../../components/error-manager/ErrorManager";




const ClientInput = (props) => {

    const [currentPagination, setCurrentPagination] = useState(1)
    const [isGetClientsLoading, setIsGetClientsLoading] = useState(false)
    const [clients, setClients] = useState()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [activeRowIndex, setActiveRowIndex] = useState(1)
    const [globalError, setGlobalError] = useState()
    const [filterChecked, setFilterChecked] = useState("nom")

    const searchRef = useRef(null);

    const outerClick = useOuterClick(ev => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false)
            searchRef.current.blur()
        }
    });

    let clientsParams = useRef({
        'start': 0,
        'limit': 8,
        'filters': props.filters || {}
    });


    function addClient(client) {
        setIsDropdownOpen(false)
        props.setClient(client)
        searchRef.current.value = null
    }

    function getClients() {
        setIsGetClientsLoading(true)
        setGlobalError()
        setActiveRowIndex()
        ClientService.getClients(props.folderId, clientsParams.current).then(res => {
            setIsGetClientsLoading(false)
            setClients(res.data ? res.data.data : null)
            setActiveRowIndex(0)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetClientsLoading(false)
            setGlobalError(error)
        });
    }

    function changePagination(p) {
        setCurrentPagination(p)
        clientsParams.current['start'] = (clientsParams.current.limit * p) - clientsParams.current.limit
        getClients()
    }

    let searchTimeOut;
    function changeSearchValue(e) {
        window.clearTimeout(searchTimeOut);
        setIsDropdownOpen(true)
        searchTimeOut = window.setTimeout(function () {
            setCurrentPagination(1)
            searchRef.current.value = e.target.value
            clientsParams.current['start'] = 0
            clientsParams.current['filters'][`filter[${filterChecked}]`] = [{ operator: "", value: searchRef.current.value }]
            getClients()
        }, 1000);

    }

    function handleFilterChange(e) {
        setFilterChecked(e.target.name)
        setCurrentPagination(1)
        clientsParams.current['start'] = 0
        if (e.target.name == "code") {
            clientsParams.current['filters'][`filter[code]`] = [{ operator: "", value: searchRef.current.value }]
            delete clientsParams.current['filters'][`filter[nom]`];
        }
        if (e.target.name == "nom") {
            clientsParams.current['filters'][`filter[nom]`] = [{ operator: "", value: searchRef.current.value }]
            delete clientsParams.current['filters'][`filter[code]`];
        }
        getClients()
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (activeRowIndex != null && isDropdownOpen) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    setActiveRowIndex((activeRowIndex < clients?.clients.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    addClient(clients.clients[activeRowIndex])
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div id="ClientInput" className="position-relative">

            <InputGroup className="">
                <i className="material-icons input-icon text-gray-400">search</i>
                <FormControl
                    onFocus={() => setIsDropdownOpen(true)}
                    className="rounded-start input-icon-space"
                    type="text"
                    ref={searchRef}
                    onChange={changeSearchValue}
                    placeholder="Rechercher un client"
                />
            </InputGroup>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            {isDropdownOpen && (
                <span ref={outerClick} className="drop-container zindex-100 border border-gray-500 rounded p-3 position-absolute top-100 bg-white shadow-lg">

                    <h6>Rechercher dans :</h6>
                    <Form.Group className="d-flex">
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "code"}
                            label="Code"
                            name="code"
                            type="radio"
                            id={`inline-1`}
                        />
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "nom"}
                            label="Nom"
                            name="nom"
                            type="radio"
                            id={`inline-2`}
                        />
                    </Form.Group>

                    <hr />

                    {isGetClientsLoading &&
                        <span className="hloader"></span>
                    }

                    {(!isGetClientsLoading && !clients?.clients) &&
                        <em>- Aucun élément trouvé -</em>
                    }

                    {(!isGetClientsLoading && clients?.clients) &&
                        <>
                            <Table size="sm" hover className="rounded">
                                <thead className="text-uppercase text-gray-600 small">
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clients?.clients?.map((client, i) => (
                                        <tr key={client?._id} className={"cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")} onClick={() => addClient(client)}>
                                            <td>{client?.code}</td>
                                            <td>
                                                {client?.nom}
                                                <Badge className="float-end" bg="secondary">{client?.type}</Badge>
                                                {client?.bloquer &&
                                                    <Badge className="me-2 float-end" bg="danger">Bloqué</Badge>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination size="sm" itemsLength={clients?.total} elementsPerPage={clientsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                        </>
                    }

                </span>
            )}

        </div>

    )
}
export default ClientInput;
