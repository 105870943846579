import React, { useEffect, useState } from 'react';
import { ArticleService } from '../../../../../services/ArticleService';
import { TarifService } from '../../../../../services/TarifService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Spinner, Card, Col, Row, InputGroup, FormControl, Badge, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover, Tabs, Tab } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import { getFormErrorMsg, getInvalidObject } from '../../../../../helper/Helper'
import Pagination from '../../../../../components/pagination/Pagination';
import TarifInput from "./../../../../form-inputs/tarif-input/TarifInput";


const Tarifs = (props) => {

    const [globalError, setGlobalError] = useState();
    const [articleTarifList, setArticleTarifList] = useState()
    const [articlePromotionList, setArticlePromotionList] = useState()
    const [getTarifListLoading, setGetTarifListLoading] = useState(false)
    const [postArticlesLoading, setPostArticlesLoading] = useState(false)
    const [removeTarifIdLoading, setRemoveTarifIdLoading] = useState(false)
    const [currentType, setCurrentType] = useState()
    const [currentValue, setCurrentValue] = useState()
    const [currentTarif, setCurrentTarif] = useState()


    useEffect(() => {
        getArticleTarifs()
    }, []);

    function saveTarif() {
        setGlobalError()
        setPostArticlesLoading(true)
        TarifService.postArticle(props.folderId, currentTarif._id, { articles: [{ article_id: props.article._id, [currentType]: currentValue }] }).then(res => {
            setPostArticlesLoading(false)
            getArticleTarifs()
            setCurrentType()
            setCurrentValue()
            setCurrentTarif()
            props.onTarifChange()
        }).catch(error => {
            setGlobalError(error)
            setPostArticlesLoading(false)
        });
    }

    function removeTarif(tarif) {
        setGlobalError()
        setRemoveTarifIdLoading(tarif._id)
        TarifService.removeArticle(props.folderId, tarif._id, props.article._id).then(res => {
            setRemoveTarifIdLoading()
            getArticleTarifs()
            props.onTarifChange()
        }).catch(error => {
            setRemoveTarifIdLoading()
            setGlobalError(error)
        });
    }

    function cancelArticle() {
        setCurrentType()
        setCurrentValue()
        setCurrentTarif()
    }

    function getArticleTarifs() {
        setGlobalError()
        setGetTarifListLoading(true)
        ArticleService.getTarifs(props.folderId, props.article._id).then(res => {
            setGetTarifListLoading(false)
            setArticleTarifList(res?.data?.data?.tarifs ? res.data.data.tarifs : null)
            setArticlePromotionList(res?.data?.data?.promotions ? res.data.data.promotions : null)
        }).catch(error => {
            setGetTarifListLoading(false)
            setGlobalError(error)
        });
    }

    function handleChangeTarif(tarif) {

        setCurrentTarif(tarif)

        if (!tarif) { return }

        let value = null
        tarif.coefficient_pr && (value = tarif.coefficient_pr)
        tarif.prix_vente_ht && (value = tarif.prix_vente_ht)
        tarif.prix_vente_ttc && (value = tarif.prix_vente_ttc)
        tarif.taux_remise && (value = tarif.taux_remise)
        setCurrentValue(value)

        let type = null
        tarif.coefficient_pr && (type = "coefficient_pr")
        tarif.prix_vente_ht && (type = "prix_vente_ht")
        tarif.prix_vente_ttc && (type = "prix_vente_ttc")
        tarif.taux_remise && (type = "taux_remise")
        setCurrentType(type)

    }

    function handleChangeType(e) {
        setCurrentType(e.target.value)
    }

    function handleChangeValue(e) {
        setCurrentValue(e.target.value)
    }

    return (
        <>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            <div className="border p-3 rounded bg-gray-100 mb-3">

                <Form.Group className="mb-3">
                    <Form.Label>Ajouter un tarif :</Form.Label>
                    <TarifInput name="tarif_id" /* isInvalid={getFormErrorMsg(invalidData?.tarif_id)} */ tarifId={currentTarif?._id} folderId={props.folderId} setTarif={t => handleChangeTarif(t)} />
                </Form.Group>

                {currentTarif &&
                    <>
                        <Form.Group className="d-flex">

                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Taux remise"
                                name="groupeType"
                                value="taux_remise"
                                checked={currentType == "taux_remise"}
                                type="radio"
                                id={`inline-1`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Prix vente HT"
                                name="groupeType"
                                value="prix_vente_ht"
                                checked={currentType == "prix_vente_ht"}
                                type="radio"
                                id={`inline-2`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Prix vente TTC"
                                name="groupeType"
                                value="prix_vente_ttc"
                                checked={currentType == "prix_vente_ttc"}
                                type="radio"
                                id={`inline-3`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Coef. Prix de revient"
                                name="groupeType"
                                value="coefficient_pr"
                                checked={currentType == "coefficient_pr"}
                                type="radio"
                                id={`inline-4`}
                            />
                        </Form.Group>

                        <div className="d-flex mt-2">

                            <InputGroup className="w-100">

                                <Form.Control defaultValue={currentValue} onChange={handleChangeValue} type="number" />

                                {(currentType == "taux_remise") &&
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                }

                                {(currentType == "prix_vente_ht") &&
                                    <InputGroup.Text id="basic-addon2">F. HT</InputGroup.Text>
                                }

                                {(currentType == "prix_vente_ttc") &&
                                    <InputGroup.Text id="basic-addon2">F. TTC</InputGroup.Text>
                                }

                                {(currentType == "coefficient_pr") &&
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                }

                            </InputGroup>



                            <Button className="ms-2" disabled={postArticlesLoading} variant="secondary" onClick={() => cancelArticle()}>Annuler</Button>
                            <Button className="ms-2 d-flex" disabled={postArticlesLoading} onClick={() => saveTarif()}>
                                {postArticlesLoading &&
                                    <Spinner animation="border" size="sm" className="me-2" />
                                }
                                Enregistrer
                            </Button>
                        </div>
                    </>
                }
            </div>


            {getTarifListLoading &&
                <span className="hloader mt-3"></span>
            }

            {(!articleTarifList && !articlePromotionList && !getTarifListLoading) &&
                <em>- Aucun élément trouvé -</em>
            }

            {(articleTarifList?.length > 0) &&
                <div className="border p-3 rounded">
                    <h6>Tarifs :</h6>
                    {articleTarifList.map(tarif => (
                        <span key={tarif._id} className="py-1 px-2 bg-gray-100 rounded mb-2 d-flex align-items-center">
                            <span className="me-auto">
                                <span className="me-2 fw-bolder">{tarif.code}</span>
                                {tarif.libelle}
                            </span>

                            {(tarif.taux_remise != null) && <span className="text-gray-600">Taux remise : {tarif.taux_remise}</span>}
                            {(tarif.prix_vente_ht != null) && <span className="text-gray-600">Prix vente HT : {tarif.prix_vente_ht}</span>}
                            {(tarif.prix_vente_ttc != null) && <span className="text-gray-600">Prix vente TTC : {tarif.prix_vente_ttc}</span>}
                            {(tarif.coefficient_pr != null) && <span className="text-gray-600">Coef. Prix de revient : {tarif.coefficient_pr}</span>}

                            {tarif?.famille_id &&
                                <span className="ms-3 text-gray-700 px-2 bg-gray-300 rounded">Appliqué à la famille</span>
                            }

                            {!tarif?.famille_id &&
                                <Button disabled={removeTarifIdLoading == tarif._id} size="sm" onClick={() => removeTarif(tarif)} variant="outline-danger" className="ms-3">
                                    <i className="material-icons i-lg">delete</i>
                                </Button>
                            }

                        </span>
                    ))}
                </div>
            }

            {(articlePromotionList?.length > 0) &&
                <div className="mt-3 border p-3 rounded">
                    <h6>Promotions :</h6>
                    {articlePromotionList.map(promo => (
                        <span key={promo._id} className="py-1 px-2 bg-gray-100 rounded mb-2 d-flex align-items-center">
                            <span className="me-auto">
                                <span className="me-2 fw-bolder">{promo.code}</span>
                                {promo.libelle}
                            </span>

                            {(promo.taux_remise != null) && <span className="text-gray-600">Taux remise : {promo.taux_remise}</span>}
                            {(promo.prix_vente_ht != null) && <span className="text-gray-600">Prix vente HT : {promo.prix_vente_ht}</span>}
                            {(promo.prix_vente_ttc != null) && <span className="text-gray-600">Prix vente TTC : {promo.prix_vente_ttc}</span>}
                            {(promo.coefficient_pr != null) && <span className="text-gray-600">Coef. Prix de revient : {promo.coefficient_pr}</span>}

                            {promo?.famille_id &&
                                <span className="ms-3 text-gray-700 px-2 bg-gray-300 rounded">Appliqué à la famille</span>
                            }
                            
                            {!promo?.famille_id &&
                                <Button disabled={removeTarifIdLoading == promo._id} size="sm" onClick={() => removeTarif(promo)} variant="outline-danger" className="ms-3">
                                    <i className="material-icons i-lg">delete</i>
                                </Button>
                            }

                        </span>
                    ))}
                </div>
            }


        </>
    );
}

export default Tarifs;