import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, Modal, Tooltip, InputGroup, Form } from 'react-bootstrap';
import { EditionService } from '../../services/EditionService';
import { ClientService } from '../../services/ClientService';
import ErrorManager from "../../components/error-manager/ErrorManager";

//import './SendMailDialog.scss';

const SendMailDialog = ({ clientId, folderId, documentType, documentId, show, setCloseModal }) => {

    const [globalError, setGlobalError] = useState();
    const [showModal, setShowModal] = useState(false);
    const [sendCurrentMail, setSendCurrentMail] = useState();
    const [sendCurrentMailInput, setSendCurrentMailInput] = useState(false);
    const [sendArlreadyMailList, setSendAlreadyMailList] = useState([]);
    const [sendArlreadyMailInputList, setSendAlreadyMailInputList] = useState([]);
    const [mailInputValue, setMailInputValue] = useState();
    const [interlocuteurList, setInterlocuteurList] = useState([]);

    useEffect(() => {
        setShowModal(show)
        if(show){
            resetValues()
            getClient()
        }
    }, [show]);

    function resetValues() {
        setGlobalError()
        setSendCurrentMail()
        setSendCurrentMailInput(false)
        setSendAlreadyMailList([])
        setSendAlreadyMailInputList([])
        setMailInputValue()
    }

    function handleClose(e) {
        setCloseModal()
    }

    function getClient(){
        ClientService.getClient(folderId, clientId).then(res => {
            if (!res.data?.data) {
                setGlobalError({ statusText: res.statusText })
              } else {
                setInterlocuteurList(res.data.data.interlocuteurs)
              }
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function sendEmail(mailAdress, fromEmailInput) {
        setSendCurrentMail(mailAdress)
        setGlobalError()
        if (fromEmailInput) {
            setSendCurrentMailInput(true)
        }
        EditionService.getSendDocumentMail(folderId, documentType, documentId, mailAdress).then(res => {
            if (!res.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                setSendCurrentMail()
                setSendAlreadyMailList(prevState => [...prevState, mailAdress])
            }

            if (fromEmailInput) {
                setSendAlreadyMailInputList(prevState => [...prevState, mailAdress])
                setSendCurrentMailInput(false)
            }
        }).catch(error => {
            setSendCurrentMail()
            setSendCurrentMailInput(false)
            setGlobalError(error)
        });
    }

    function handleMailInputChange(e) {
        setMailInputValue(e.target.value)
    }

    return (
        <div id="SendMailDialog">
            <Modal show={showModal} onHide={() => handleClose()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Envoyer le document par mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ErrorManager error={globalError} />

                    {interlocuteurList?.map(interlocuteur => (
                        <div key={interlocuteur._id}>
                            {interlocuteur?.mails?.length > 0 &&
                                <>
                                    <p className="m-0 small mt-2 mb-1">{interlocuteur?.nom} {interlocuteur?.prenom} {interlocuteur?.principal && <>(principal)</>}</p>
                                    {interlocuteur.mails.map(mail => (
                                        <div key={mail} className="d-flex align-items-center">
                                            <span className="small text-gray-600 me-3">{mail}</span>
                                            <span className="ms-auto">
                                                {(sendArlreadyMailList.indexOf(mail) > -1) ?
                                                    <Button disabled className="p-0 text-decoration-none d-flex" variant="link" size="sm">Envoyé<i className="material-icons ms-1">done</i></Button>
                                                    :
                                                    <Button disabled={sendCurrentMail == mail} onClick={() => sendEmail(mail)} className="p-0" variant="link" size="sm">Envoyer</Button>
                                                }
                                            </span>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                    ))}

                    {(interlocuteurList?.filter(i => i.mails.length > 0).length == 0) &&
                        <em className="small text-gray-600">- Aucune adresse mail n'est rattachée à ce client -</em>
                    }

                    <InputGroup className="mt-3">
                        <Form.Control
                            placeholder="Saisir un email"
                            defaultValue={mailInputValue}
                            onChange={handleMailInputChange}
                        />
                        <Button disabled={sendCurrentMailInput} variant="secondary" id="button-addon2" onClick={() => sendEmail(mailInputValue, true)} >
                            Envoyer
                        </Button>
                    </InputGroup>
                    {sendArlreadyMailInputList?.map(mail => (
                        <span key={mail} className="px-1 rounded bg-gray-200 me-2 mt-2 d-inline-flex text-gray-600">{mail}<i className="material-icons ms-1">done</i></span>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SendMailDialog;
