import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, FormControl, Tooltip, InputGroup, Form } from 'react-bootstrap';
import { GlobalContext } from "../../services/GlobalProvider";
import { TagService } from '../../services/TagService';
import { ClientService } from '../../services/ClientService';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { isDark, granted, useOuterClick } from '../../helper/Helper'
import './TagsFilter.scss';


const TagsFilter = ({ documentType, changeTagIdList, parentFilters }) => {

    const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

    const [globalError, setGlobalError] = useState();
    const [tagList, setTagList] = useState();
    const [showTagList, setShowTagList] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [inputLibelle, setInputLibelle] = useState();
    const [inputColor, setInputColor] = useState("#e5e7e9");
    const [openColorPalet, setOpenColorPalet] = useState(false);
    const [addRemoveTagLoading, setAddRemoveTagLoading] = useState(false);
    const [totalElementsTagsList, setTotalElementsTagsList] = useState();
    const [partialElementsTagsList, setPartialElementsTagsList] = useState();
    const [tagListLoading, setTagListLoading] = useState(false);
    const [filterTagList, setFilterTagList] = useState([]);

    const tagListOuterClick = useOuterClick(ev => {
        showTagList && setShowTagList(false)
    });

    let tagsParams = useRef({
        'start': 0,
        'limit': 0,
        'search': null,
        'filters': {
            'filter[type]': [{ operator: "=", value: documentType }]
        },
        'sort': null,
        'order': null
    });

    useEffect(() => {
        let tagIdList = []
        filterTagList.map(tag => {
            tagIdList.push(tag._id)
        })
        changeTagIdList(tagIdList)
    }, [filterTagList]);

    useEffect(() => {
        if (!parentFilters) {
            setFilterTagList([])
        }
    }, [parentFilters]);

    function getTagList() {
        setGlobalError()
        setTagListLoading(true)
        TagService.getTags(folderDetails._id, tagsParams.current).then(res => {
            setTagList(res.data.data.tags)
            setTagListLoading(false)
        }).catch(error => {
            setGlobalError(error)
            setTagListLoading(false)
        });
    }

    function changeSearchValue(e) {
        setSearchValue(e.target.value)
    }

    function addTag(tag) {
        setFilterTagList(filterTagList => [...filterTagList, tag]);
    }

    function removeTag(tag) {
        setFilterTagList(filterTagList.filter(tagFound => tagFound._id !== tag._id))
    }

    function showDropdownTagList() {
        if (!showTagList) {
            getTagList()
            setSearchValue(null)
        }
        setShowTagList(!showTagList)
    }

    return (
        <div ref={tagListOuterClick}>

            <Button id="TagsFilterButton" className="d-flex align-items-center rounded-0" variant="secondary" onClick={() => showDropdownTagList()}>
                Filtrer par tags
                {filterTagList?.length > 0 &&
                    <span className="bubble-tags-container">
                        {filterTagList.map(tag => (
                            <span key={tag._id} className="bubble" style={{ backgroundColor: tag.couleur }}>
                                <span style={{ backgroundColor: tag.couleur }} className={"label " + (isDark(tag.couleur) ? "text-dark" : "text-white")}>{tag.tag}</span>
                            </span>
                        ))}
                    </span>
                }
            </Button>

            {showTagList &&
                <div id="TagsFilter" className="position-absolute bg-white p-3 rounded border zindex-90 mt-1 end-0 top-100">
                    {filterTagList.length > 0 &&
                        <>
                            <h6>Tags appliqués sur le filtre :</h6>
                            {filterTagList.map(tag => (
                                <span key={tag._id} /* onClick={addTag(tag)} */ className={"cursor-pointer me-2 rounded-5 px-2 py-1 d-inline-flex mb-2 small fw-bold " + (isDark(tag.couleur) ? "text-dark " : "text-white ")} style={{ backgroundColor: tag.couleur }}>
                                    {tag.tag}
                                    <i className="material-icons ms-1 i-lg cursor-pointer" onClick={() => removeTag(tag)}>close</i>
                                </span>
                            ))}
                        </>
                    }
                    <FormControl
                        autoFocus
                        className="mb-3 mt-3"
                        placeholder="Rechercher un tag"
                        type="search"
                        onChange={e => changeSearchValue(e)}
                    />
                    {(!tagListLoading && tagList) &&
                        <>
                            {tagList.filter(t => (!searchValue || t.tag.indexOf(searchValue) !== -1)).map(tag => (
                                <span key={tag._id} onClick={() => addTag(tag)} className={"cursor-pointer me-1 rounded-5 px-2 d-inline-flex mb-2 small fw-bold " + (isDark(tag.couleur) ? "text-dark " : "text-white ")} style={{ backgroundColor: tag.couleur }}>
                                    {tag.tag}
                                </span>
                            ))}
                            {tagList.filter(t => (!searchValue || t.tag.indexOf(searchValue) !== -1)).length == 0 &&
                                <em>- Aucun résultat trouvé -</em>
                            }
                        </>
                    }
                    {tagListLoading &&
                        <span className="hloader mt-3"></span>
                    }
                </div>
            }
        </div>
    )
}

export default TagsFilter;
