import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, Pagination as BsPagination } from 'react-bootstrap';

const Pagination = (props) => {

    let elementsPerPage = props.elementsPerPage
    let total = props.itemsLength

    const [paginationActiveNumber, setPaginationActiveNumber] = useState(1)
    const [paginationItemList, setPaginationItemList] = useState(null)

    useEffect(() => {
        //props.currentPagination
        //props.changePagination(paginationActiveNumber-1)
        definePaginationItems()
    }, [paginationActiveNumber]);

    useEffect(() => {
        setPaginationActiveNumber(props.currentPagination)
    }, [props.currentPagination]);

    useEffect(() => {
        definePaginationItems()
    }, [total]);

    function definePaginationItems() {

        let paginationItemList = []

        let lastNum = (total / elementsPerPage == Math.floor(total / elementsPerPage)) ? Math.floor(total / elementsPerPage) : Math.floor(total / elementsPerPage) + 1

        if (lastNum == 1) { // ne pas afficher de pagination si les résultats tiennent en 1 page
            setPaginationItemList(null)
            return
        }

        if (lastNum <= 9) { // affichage simplifié si moins de 9 pages
            for (let number = 1; number <= lastNum; number++) {
                paginationItemList.push(
                    <BsPagination.Item key={number} active={number === paginationActiveNumber} onClick={() => props.changePagination(number)}>
                        {number}
                    </BsPagination.Item>,
                );
            }
            setPaginationItemList(paginationItemList)
            return
        }

        if (lastNum > 9) { // affichage simplifié si moins de 9 pages

            // précédent
            paginationItemList.push(<BsPagination.Prev key={'prev'} disabled={paginationActiveNumber == 1} onClick={() => props.changePagination(paginationActiveNumber - 1)} />);


            // premier numéro
            paginationItemList.push(
                <BsPagination.Item key={1} active={1 === paginationActiveNumber} onClick={() => props.changePagination(1)}>{1}</BsPagination.Item>
            );

            // ellipsis
            if (paginationActiveNumber > 3) {
                paginationItemList.push(<BsPagination.Ellipsis key={'ellipsis-prev'} disabled />);
            }

            // numéros avant le 6 (commence à 2)
            if (paginationActiveNumber < 4) {
                for (let number = 2; number <= 5; number++) {
                    paginationItemList.push(
                        <BsPagination.Item key={number} active={number === paginationActiveNumber} onClick={() => props.changePagination(number)}>
                            {number}
                        </BsPagination.Item>,
                    );
                }
            } else if (paginationActiveNumber >= 4 && paginationActiveNumber < lastNum - 2) {
                for (let number = paginationActiveNumber - 1; number < paginationActiveNumber + 2; number++) {
                    paginationItemList.push(
                        <BsPagination.Item key={number} active={number === paginationActiveNumber} onClick={() => props.changePagination(number)}>
                            {number}
                        </BsPagination.Item>,
                    );
                }
            } else if (paginationActiveNumber > lastNum - 3) {
                for (let number = lastNum - 4; number < lastNum; number++) {
                    paginationItemList.push(
                        <BsPagination.Item key={number} active={number === paginationActiveNumber} onClick={() => props.changePagination(number)}>
                            {number}
                        </BsPagination.Item>,
                    );
                }
            }


            // ellipsis
            if (paginationActiveNumber < lastNum - 2) {
                paginationItemList.push(<BsPagination.Ellipsis key={'ellipsis-next'} disabled />);
            }

            // dernier numéro
            paginationItemList.push(
                <BsPagination.Item key={lastNum} active={lastNum === paginationActiveNumber} onClick={() => props.changePagination(lastNum)}>{lastNum}</BsPagination.Item>
            );

            // suivant
            paginationItemList.push(<BsPagination.Next key={'next'} disabled={paginationActiveNumber >= (total / elementsPerPage)} onClick={() => props.changePagination(paginationActiveNumber + 1)} />);

            setPaginationItemList(paginationItemList)


        }

    }

    return (
        <>
            {props.itemsLength &&
                <BsPagination size={props.size} className="mt-3">
                    {paginationItemList}
                    <small className="align-self-center ms-3 text-black-50">
                        {paginationActiveNumber*props.elementsPerPage-props.elementsPerPage+1} — {(paginationActiveNumber*props.elementsPerPage)>props.itemsLength ? props.itemsLength : paginationActiveNumber*props.elementsPerPage} de {props.itemsLength} éléments
                    </small>
                </BsPagination>
            }
        </>
    );
}

export default Pagination;