import React, { useEffect, useState } from 'react';
import { Spinner, Card, Col, Row, InputGroup, FormControl, Badge, Accordion, Button, ButtonGroup, Alert, Form, Dropdown, OverlayTrigger, Popover, Tabs, Tab } from 'react-bootstrap';
import { getBase64 } from '../../../../helper/Helper'
import ImageInput from '../../../../components/form-inputs/image-input/ImageInput'


const Global = ({ globalParams }) => {

    useEffect(() => {
    }, []);

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        globalParams[e.target.name] = value
    }

    return (
        <>

            <div className="d-flex flex-column name-list">
                <Form.Group className="mb-2">
                    <Form.Control type="color" name="primaryColor" onChange={handleChange} defaultValue={globalParams.primaryColor} placeholder="Choisir une couleur" />
                    <Form.Label className="mb-0">Couleur principale.</Form.Label>
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Control type="color" name="secondaryColor" onChange={handleChange} defaultValue={globalParams.secondaryColor} placeholder="Choisir une couleur" />
                    <Form.Label className="mb-0">Couleur secondaire.</Form.Label>
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Control type="color" name="backgroundColor" onChange={handleChange} defaultValue={globalParams.backgroundColor} placeholder="Choisir une couleur" />
                    <Form.Label className="mb-0">Couleur de fond.</Form.Label>
                </Form.Group>

                {/* TODO : polices */}
                {/*                 <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm={6}>Police des titres :</Form.Label>
                    <Col sm={6}>
                        <Form.Select name="headersFont" onChange={handleChange} defaultValue={previewParams.headersFont}>
                            <option value="Helvetica">Helvetica</option>
                            <option value="Arial">Arial</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Courier New">Courier New</option>
                        </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm={6}>Police du texte :</Form.Label>
                    <Col sm={6}>
                        <Form.Select name="textsFont" onChange={handleChange} defaultValue={previewParams.textsFont}>
                            <option value="Helvetica">Helvetica</option>
                            <option value="Arial">Arial</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Courier New">Courier New</option>
                        </Form.Select>
                    </Col>
                </Form.Group> */}

                <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm={6}>Image de fond :</Form.Label>
                    <Col sm={6}>
                        <ImageInput value={globalParams.background} name="background" setImage={i => (globalParams.background = i)} />
                    </Col>
                </Form.Group>

                {/*                 <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm={6}>Marges du document :</Form.Label>
                    <Row>
                        <Col sm={3}>
                            <InputGroup>
                            <i className="material-icons input-icon text-gray-400">keyboard_arrow_down</i>
                                <FormControl
                                    className="rounded-start input-icon-space"
                                    type="number"
                                    value="10"
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup>
                            <i className="material-icons input-icon text-gray-400">keyboard_arrow_left</i>
                                <FormControl
                                    className="rounded-start input-icon-space"
                                    type="number"
                                    value="10"
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup>
                            <i className="material-icons input-icon text-gray-400">keyboard_arrow_up</i>
                                <FormControl
                                    className="rounded-start input-icon-space"
                                    type="number"
                                    value="10"
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup>
                            <i className="material-icons input-icon text-gray-400">keyboard_arrow_right</i>
                                <FormControl
                                    className="rounded-start input-icon-space"
                                    type="number"
                                    value="10"
                                />
                            </InputGroup>
                        </Col>

                    </Row>
                </Form.Group> */}

                {/*                 <Form.Group className="mb-2">
                    <Form.Check type="switch" label="Afficher les numéros de pages." />
                </Form.Group> */}
            </div>



        </>
    );
}

export default Global;