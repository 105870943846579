// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#WysiwygInput {
  display: flex;
  flex-direction: column;
}
#WysiwygInput a {
  color: inherit;
  text-decoration: none;
}
#WysiwygInput .rdw-option-wrapper {
  border: none;
}
#WysiwygInput .rdw-option-wrapper:hover {
  box-shadow: none;
  background-color: #eff1f9;
}
#WysiwygInput .rdw-dropdown-wrapper {
  border: none;
}
#WysiwygInput .rdw-dropdown-wrapper:hover {
  box-shadow: none;
  background-color: #eff1f9;
}
#WysiwygInput .rdw-dropdown-optionwrapper {
  overflow: hidden;
  box-shadow: none;
}
#WysiwygInput .rdw-editor-main {
  max-height: 300px;
}
#WysiwygInput .message-editor {
  min-height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/form-inputs/wysiwyg-input/WysiwygInput.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,cAAA;EACA,qBAAA;AACR;AACI;EACI,YAAA;AACR;AAAQ;EACI,gBAAA;EACA,yBAAA;AAEZ;AACI;EACI,YAAA;AACR;AAAQ;EACI,gBAAA;EACA,yBAAA;AAEZ;AAEI;EACI,gBAAA;EACA,gBAAA;AAAR;AAGI;EACI,iBAAA;AADR;AAUI;EACI,gBAAA;AARR","sourcesContent":["#WysiwygInput {\n    display: flex;\n    flex-direction: column;\n    \n    a{\n        color: inherit;\n        text-decoration: none;\n    }\n    .rdw-option-wrapper{\n        border: none;\n        &:hover{\n            box-shadow: none;\n            background-color: #eff1f9;\n        }\n    }\n    .rdw-dropdown-wrapper{\n        border: none;\n        &:hover{\n            box-shadow: none;\n            background-color: #eff1f9;\n        }\n    }\n\n    .rdw-dropdown-optionwrapper{\n        overflow: hidden;\n        box-shadow: none;\n    }\n\n    .rdw-editor-main{\n        max-height: 300px;\n    }\n    .editor-wrapper {\n    }\n    \n    .message-toolbar {\n        \n    }\n    \n    .message-editor {\n        min-height: 80px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
