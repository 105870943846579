import { Link } from "react-router-dom";
import React, { Component, useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown, Dropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import logo from '../../../assets/img/logo/logo-facturexl.png'
import logoSmall from '../../../assets/img/logo/logo-facturexl-small.png'
import { FolderService } from '../../../services/FolderService'
import { UserService } from '../../../services/UserService'
import { GlobalContext } from '../../../services/GlobalProvider';
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import { granted } from '../../../helper/Helper'


const ShortCuts = () => {

    const [currentPage, setCurrentPage] = useState()

    let location = useLocation();

    useEffect(() => {
        setCurrentPage(location.pathname.split("/").splice(2, 4).join(".")) // Ex Output : "factures.brouillon" or "articles"
    }, [location.pathname]);

    useEffect(() => {

    }, []);

    const GetShortcuts = () => {
        switch (currentPage) {
            case "articles": case "clients": case "factures": case "reglements": case "proformas": case "fournisseurs":
                return (
                    <>
                        <Dropdown.Header>Ligne du tableau en mode sélection :</Dropdown.Header>

                        <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                            <span className="pe-5 me-auto">Se déplacer dans le tableau</span>
                            <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Haut</span>
                            <span className="mx-1 fw-bold small text-gray-400">/</span>
                            <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Bas</span>
                        </span>

                        <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                            <span className="pe-5 me-auto">Cocher la ligne</span>
                            <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Espace</span>
                        </span>

                        <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                            <span className="pe-5 me-auto">Fermer la fiche</span>
                            <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Échap</span>
                        </span>
                    </>
                )
            case "factures.brouillon": case "proformas.brouillon":
                return (<>

                    <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                        <span className="pe-5 me-auto">Définir le client à "Comptant"</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">F10</span>
                    </span>

                    <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                        <span className="pe-5 me-auto">Ajouter un règlement</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Ctrl</span>
                        <span className="mx-1 fw-bold small text-gray-400">+</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">R</span>
                    </span>

                    <Dropdown.Header>Ligne de détail en mode édition :</Dropdown.Header>

                    <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                        <span className="pe-5 me-auto">Déplacer la ligne de détail</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Ctrl</span>
                        <span className="mx-1 fw-bold small text-gray-400">+</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Haut</span>
                        <span className="mx-1 fw-bold small text-gray-400">/</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Bas</span>
                    </span>

                    <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                        <span className="pe-5 me-auto">Fermer la ligne de détail</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Échap</span>
                    </span>

                    <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                        <span className="pe-5 me-auto">Valider la ligne de détail</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Ctrl</span>
                        <span className="mx-1 fw-bold small text-gray-400">+</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Entrée</span>
                    </span>

                    <span className="px-3 mb-1 d-flex align-items-center text-nowrap">
                        <span className="pe-5 me-auto">Supprimer la ligne de détail</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Ctrl</span>
                        <span className="mx-1 fw-bold small text-gray-400">+</span>
                        <span className="fw-bold border-bottom border-gray-600 rounded small px-1 bg-gray-700">Suppr</span>
                    </span>

                </>)
            default:
                return (<span className="px-3 mb-1 text-nowrap"><em>- Aucun raccourci possible dans cette page -</em></span>)
        }
    }

    return (
        <Dropdown align="end">
            <OverlayTrigger
                placement="left"
                delay={{ show: 800 }}
                overlay={
                    <Tooltip>
                        Raccourcis clavier
                    </Tooltip>
                }
            >
                <Dropdown.Toggle variant="primary-800" className="no-btn-caret dropdown-toggle">
                    <i className="material-icons i-lg">question_mark</i>
                </Dropdown.Toggle>
            </OverlayTrigger>


            <Dropdown.Menu variant="dark" className="py-3">
                {GetShortcuts()}
            </Dropdown.Menu>
        </Dropdown>
    );

}

export default ShortCuts;