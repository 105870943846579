import React, { useEffect, useState } from 'react';
import { Spinner, Card, Col, Row, InputGroup, FormControl, Badge, Accordion, Button, ButtonGroup, Alert, Form, Dropdown, OverlayTrigger, Popover, Tabs, Tab } from 'react-bootstrap';
import ErrorManager from "../../../../../components/error-manager/ErrorManager";
import { getBase64 } from '../../../../../helper/Helper'

const Default = ({ globalParams }) => {

    const [showTextarea, setShowTextarea] = useState([])

    useEffect(() => {
        let showTextarea = []
        !globalParams.left.info_block_name && showTextarea.push('left')
        !globalParams.center.info_block_name && showTextarea.push('center')
        !globalParams.right.info_block_name && showTextarea.push('right')
        setShowTextarea(showTextarea)
    }, []);

    function handleChangePart(e) {
        let value = e.target.value

        globalParams[e.target.name].info_block_name = value

        if (value == "") {
            setShowTextarea(prevState => [...prevState, e.target.name])
        } else {
            setShowTextarea(prevState => {
                return prevState.filter((_, i) => i !== showTextarea.indexOf(e.target.name))
            })
        }
    }

    function handleCustom(e) {
        globalParams[e.target.name].custom = e.target.value
    }

    /*     function handleChangeImg(e) {
            getBase64(e.target.files[0], (result) => {
                previewParams[e.target.name] = result
            });
        } */

    return (

        <div className="d-flex flex-column name-list">

            {!globalParams &&
                <em>- Ce template ne nécessite aucune personnalisation -</em>
            }

            {globalParams &&
                <>

                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={6}>Partie gauche</Form.Label>
                        <Col sm={6}>
                            <Form.Select name="left" onChange={handleChangePart} defaultValue={globalParams.left.info_block_name || ""}>
                                <option value="block_vide">- Vide -</option>
                                <option value="contact">Contact</option>
                                <option value="societe">Société</option>
                                <option value="informations_bancaires">Informations bancaires</option>
                                <option value="obligations_paiement">Obligations de paiement</option>
                                <option value="">Texte personnalisé</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    {(showTextarea?.indexOf("left") > -1) &&
                        <Form.Control className="mb-3" as="textarea" rows={3} name="left" onChange={handleCustom} defaultValue={globalParams.left.custom} />
                    }



                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={6}>Partie centrale</Form.Label>
                        <Col sm={6}>
                            <Form.Select name="center" onChange={handleChangePart} defaultValue={globalParams.center.info_block_name || ""}>
                                <option value="block_vide">- Vide -</option>
                                <option value="contact">Contact</option>
                                <option value="societe">Société</option>
                                <option value="informations_bancaires">Informations bancaires</option>
                                <option value="obligations_paiement">Obligations de paiement</option>
                                <option value="">Texte personnalisé</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    {(showTextarea?.indexOf("center") > -1) &&
                        <Form.Control className="mb-3" as="textarea" rows={3} name="center" onChange={handleCustom} defaultValue={globalParams.center.custom} />
                    }



                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={6}>Partie droite</Form.Label>
                        <Col sm={6}>
                            <Form.Select name="right" onChange={handleChangePart} defaultValue={globalParams.right.info_block_name || ""}>
                                <option value="block_vide">- Vide -</option>
                                <option value="contact">Contact</option>
                                <option value="societe">Société</option>
                                <option value="informations_bancaires">Informations bancaires</option>
                                <option value="obligations_paiement">Obligations de paiement</option>
                                <option value="">Texte personnalisé</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    {(showTextarea?.indexOf("right") > -1) &&
                        <Form.Control className="mb-3" as="textarea" rows={3} name="right" onChange={handleCustom} defaultValue={globalParams.right.custom} />
                    }


                </>

            }


        </div>

    );
}

export default Default;