import {axiosAPI} from "./axiosClient"
import { UserService } from './UserService';
import { getParams } from '../helper/Helper'
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";

export const BrouillonService = {

  getBrouillons(folderId, params) {
    return axiosAPI.get(`/${folderId}/brouillons?lookup=true&${getParams(params)}`)
  },

  getBrouillon(folderId, brouillonId){
    return axiosAPI.get(`/${folderId}/brouillons/${brouillonId}?lookup=true`)
  },

  postCheckBrouillon(folderId, brouillonId){
    return axiosAPI.post(`/${folderId}/brouillons/${brouillonId}/check`)
  },

  updateBrouillon(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/brouillons`, qs.stringify(dataPost))
  },

  removeBrouillon(folderId, brouillonId) {
    return axiosAPI.delete(`/${folderId}/brouillons/${brouillonId}`)
  },
  
  getReglements(folderId, brouillonId) {  
    return axiosAPI.get(`/${folderId}/brouillons/${brouillonId}/reglements`)
  },

  postReglement(folderId, brouillonId, dataPost){
    return axiosAPI.post(`/${folderId}/brouillons/${brouillonId}/reglements`, qs.stringify(dataPost))
  },
  
  removeReglement(folderId, brouillonId, reglementId) {
    return axiosAPI.delete(`/${folderId}/brouillons/${brouillonId}/reglements/${reglementId}`)
  },

  postArticle(folderId, brouillonId, dataPost){
    return axiosAPI.post(`/${folderId}/brouillons/${brouillonId}/articles`, qs.stringify(dataPost))
  },
  
  removeArticle(folderId, brouillonId, articleId) {
    return axiosAPI.delete(`/${folderId}/brouillons/${brouillonId}/articles/${articleId}`)
  },
  
  updateDetails(folderId, brouillonId, dataPost){
    return axiosAPI.put(`/${folderId}/brouillons/${brouillonId}/articles`, qs.stringify(dataPost))
  },

  postValidate(folderId, brouillonId) {
    return axiosAPI.post(`/${folderId}/brouillons/${brouillonId}/validate`)
  },

  getSearchSecteurs(folderId, searchString){
    return axiosAPI.get(`/${folderId}/brouillons/searchsecteurs?search=${searchString}`)
  },

/*   updateFacture(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/factures`, qs.stringify(dataPost))
  },

  
   */
  
/*   updateAdresse(folderId, factureId, dataPost){
    return axiosAPI.put(`/${folderId}/factures/${factureId}/adresses`, qs.stringify(dataPost))
  }, */
  
/*   postAdresse(folderId, factureId, dataPost){
    return axiosAPI.post(`/${folderId}/factures/${factureId}/adresses`, qs.stringify(dataPost))
  }, */
  

  
/*   postInterlocuteur(folderId, factureId, dataPost){
    return axiosAPI.post(`/${folderId}/factures/${factureId}/interlocuteurs`, qs.stringify(dataPost))
  }, */
  
/*   removeInterlocuteur(folderId, factureId, interlocuteurId) {
    return axiosAPI.delete(`/${folderId}/factures/${factureId}/interlocuteurs/${interlocuteurId}`)
  }, */
  
}