import React, { createContext, useState, useEffect } from "react";
import { UserService } from './UserService';
import { PreferenceService } from './PreferenceService';
import { ParametreService } from './ParametreService';
import { FolderService } from './FolderService';
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";

  

const GlobalContext = createContext(undefined);

function GlobalProvider({ children }) {

  const [folderDetails, setFolderDetails] = useState();
  const [userDetails, setUserDetails] = useState();
  const [preferences, setPreferences] = useState();
  const [parametres, setParametres] = useState();
  const [folders, setFolders] = useState();
  const [grants, setGrants] = useState();
  const [apiVersion, setApiVersion] = useState();

  const [globalContextLoaded, setGlobalContextLoaded] = useState(false);

  let { folderId } = useParams();

  const navigate = useNavigate();

    useEffect(() => {
    getUser()
    getFolders()
  }, []);

  useEffect(() => {
    folderDetails && getPreferences()
    folderDetails && getParametres()
    folderDetails && getGrants()
  }, [folderDetails]);

  useEffect(() => {
    if (folderDetails && userDetails && preferences && parametres && grants) {
      setGlobalContextLoaded(true)
    }
  }, [folderDetails, userDetails, preferences, parametres, grants]);

  function getUser() {
    UserService.getUserInfos()
      .then((res) => {
        setUserDetails(res.data)
      }).catch(error => {
      });
  }

  function getPreferences() {
    folderDetails &&
    PreferenceService.getPreferences(folderDetails._id, false, false).then(res => {
      setPreferences(res.data.data.preferences)
    }).catch(error => {
    });
  }

  function getParametres() {
    folderDetails &&
    ParametreService.getParametres(folderDetails._id, false, false,{params: ["general","articles","brouillons","clients","contributions","factures","familles","fournisseurs","proformas","reglements","tags","tarifs"]}).then(res => {
      setParametres(res.data.data.parametres)
    }).catch(error => {
    });
  }

  function getGrants() {
    folderDetails &&
    UserService.getUserGrants(folderDetails._id).then((res) => {
        setGrants(res.data.data.grants)
      }).catch(error => {
      });
  }


  function getFolders() {

    FolderService.getFolders().then((res) => {

      setFolders(res.data.data)
      setApiVersion(res.data.status.api_version)

      /* Define folderDetails */
      let folderFound = res.data.data?.find(f => f._id == folderId);
      if (folderFound) {
        setFolderDetails(folderFound)
      }
      
      /* Redirection si le dossier n'a pas été trouvé mais que quelque chose a été saisi dans l'URL */
      if (!folderFound && folderId) {
        navigate(`/not-found?folder-not-found=true`)
      }

    }).catch(error => {
    });
  }


  return (
    <GlobalContext.Provider value={{ globalContextLoaded, userDetails, folderDetails, preferences, getPreferences, parametres, getParametres, grants, getGrants, folders, getFolders, setFolderDetails, apiVersion }}>
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalProvider, GlobalContext };
