import React, { useEffect, useState } from 'react';
import { FournisseurService } from '../../../../services/FournisseurService';
import { Form, Spinner, Card, Tabs, Table, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg } from '../../../../helper/Helper'
//import './FournisseurDetails.scss';
import TextArrayInput from "../../../form-inputs/text-array-input/TextArrayInput";
import { ProformaService } from '../../../../services/ProformaService';
import { reorder, sortByNumber, currencyFormat } from '../../../../helper/Helper'
import './ProformaDetails.scss';
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';
import { GlobalContext } from "../../../../services/GlobalProvider";


const ProformaDetails = (props) => {

    const [proforma, setProforma] = useState()
    const [getProformaLoading, setGetProformaLoading] = useState()
    let [openElement, setOpenElement] = useState("infos")

    const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

    useEffect(() => {
        getProforma()
        setOpenElement("infos")
    }, [props.proformaId]);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    function getProforma() {
        setGetProformaLoading(true)

        ProformaService.getProforma(props.folderId, props.proformaId).then(res => {

            sortByNumber(res.data.data.details, "numero_ligne")

            setProforma(res.data.data)
            setGetProformaLoading(false)

        }).catch(error => {
            setGetProformaLoading(false)

            // setIsGetProformasLoading(false)
            // setGlobalError(error)
        });
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }
    return (

        <div id="ProformaDetails">

            {(!proforma && getProformaLoading) &&
                <span className="hloader mt-3"></span>
            }

            {(proforma && !getProformaLoading) &&
                <>
                    <div className="d-flex mb-4">
                        <div className="me-auto">
                            <div className="me-3">
                                <h6 className="fw-bolder m-0">{proforma.client_nom}</h6>
                            </div>
                            <div className="me-3">
                                <Badge bg="light" text="dark" className="me-2">Type : {proforma.type}</Badge>
                                <Badge bg="light" text="dark" className="me-2">Date : {new Date(proforma.date).toLocaleString()}</Badge>
                            </div>
                        </div>
                        {props.elementActions.length > 0 &&
                            <Dropdown className="float-end ms-2 me-3">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 800 }}
                                    overlay={
                                        <Tooltip>
                                            Plus d'actions
                                        </Tooltip>
                                    }
                                >
                                    <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                        <i className="material-icons i-lg">more_horiz</i>
                                    </Dropdown.Toggle>
                                </OverlayTrigger>
                                <Dropdown.Menu>
                                    {props.elementActions.map((element) => (
                                        <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, proforma)}>{element.title}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>

                    <div className="">
                        <Tabs variant="pills" defaultActiveKey="infos" onSelect={handleTabSelect} className="mb-3">

                            <Tab eventKey="infos" title="Infos" className="light-v-scroll height-full-min-250 pe-2">
                                {openElement == "infos" &&



                                    <Accordion defaultActiveKey={['description', 'proforma', 'details', 'observations']} alwaysOpen>

                                        <Accordion.Item eventKey="description" className="border-0 mb-3">
                                            <Accordion.Header>Description</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {proforma.description &&
                                                    <p>{proforma.description}</p>
                                                }
                                                {!proforma.description &&
                                                    <em>- Aucune description rédigée -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>


                                        <Accordion.Item eventKey="proforma" className="border-0 mb-3">
                                            {proforma.type == "proforma" &&
                                                <Accordion.Header>Proforma</Accordion.Header>
                                            }
                                            {proforma.type == "avoir" &&
                                                <Accordion.Header>Avoir</Accordion.Header>
                                            }
                                            <Accordion.Body className="px-1">
                                                <Split border title="Code" value={proforma.code} />
                                                <Split border title="Utilisateur" value={proforma.user.user_login} />
                                                <Split border title="Vendeur" value={proforma.vendeur.user_login} />
                                                {parametres?.general?.export_comptable.comptabilite_analytique &&
                                                    <Split border title="Secteur analytique" value={proforma.secteur_analytique} />
                                                }
                                                <Split border title="Total prix revient" value={currencyFormat(proforma.total_prix_revient)} />
                                                <Split border title="Total brut hors taxes" value={currencyFormat(proforma.total_brut_ht)} />
                                                <Split border title="Total net hors taxes" value={currencyFormat(proforma.total_net_ht)} />
                                                <Split border title="Total remise" value={currencyFormat(proforma.total_remise)} />
                                                <Split border title="Total TTC" value={currencyFormat(proforma.total_ttc)} />
                                                <Split border title="Statut" value={proforma.statut} />
                                                <Split title="Total montant taxes" value={currencyFormat(proforma.total_taxes?.montant_total)} />
                                                <div className="">
                                                    {proforma.total_taxes?.montants_details.map(montant => (
                                                        <Badge key={montant.contribution_id} bg="gray-200" text="dark" className="me-2">{montant.libelle}</Badge>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="details" className="border-0 mb-3">
                                            <Accordion.Header>Details</Accordion.Header>
                                            <Accordion.Body className="px-1 overflow-auto bg-gray-100">

                                                {/* {(proforma.details.length > 0) &&
                                                    <>
                                                        {proforma.details.map(d =>
                                                            <div className="py-2">
                                                                {d.code &&
                                                                    <span className="me-2">{d.code}</span>
                                                                }
                                                                {d.designation &&
                                                                    <>
                                                                        {d.article_id &&
                                                                            <span><a href={`/${props.folderId}/articles/?articleId=${d.article_id}`}>{d.designation}</a></span>
                                                                        }
                                                                        {!d.article_id &&
                                                                            <span>{d.designation}</span>
                                                                        }
                                                                    </>
                                                                }
                                                                <div className="w-100">
                                                                    {d.quantite &&
                                                                        <span className="bg-gray-100 rounded px-2 me-1 mb-1 small text-gray-600">
                                                                            Qté : <b>{d.quantite}</b>
                                                                        </span>
                                                                    }
                                                                    {d.prix_vente_ht &&
                                                                        <span className="bg-gray-100 rounded px-2 me-1 mb-1 small text-gray-600">
                                                                            Prix unit. HT : <b>{d.prix_vente_ht}</b>
                                                                        </span>
                                                                    }
                                                                    {d.remise?.taux &&
                                                                        <span className="bg-gray-100 rounded px-2 me-1 mb-1 small text-gray-600">
                                                                            Remise : <b>{d.remise?.taux}</b>
                                                                        </span>
                                                                    }
                                                                </div>


                                                            </div>
                                                        )}
                                                    </>

                                                } */}

                                                {(proforma.details.length > 0) &&
                                                    <div className="table-container mt-2">
                                                        <Table borderless className="small w-100">
                                                            <thead>
                                                                <tr className='text-gray-600 text-uppercase'>
                                                                    <th>Code</th>
                                                                    <th style={{ minWidth: 220 }}>Désignation</th>
                                                                    <th>Qté</th>
                                                                    <th>Prix H.T</th>
                                                                    <th>Montant H.T</th>
                                                                    <th>Taux remise</th>
                                                                    <th>Montant remise</th>
                                                                    <th>Taux contrib.</th>
                                                                    <th>Montant contri.</th>
                                                                    <th>Px. de revient</th>
                                                                    <th>Px. de vente TTC</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="align-middle">
                                                                {proforma.details.map(d =>
                                                                    <tr key={d._id}>
                                                                        <td>{d.code}</td>
                                                                        <td>
                                                                            {d.article_id && <a href={`/${props.folderId}/articles/?articleId=${d.article_id}`}>{d.designation}</a>}
                                                                            {!d.article_id && <span>{d.designation}</span>}
                                                                        </td>
                                                                        <td>{d.quantite}</td>
                                                                        <td>{currencyFormat(d.prix_vente_ht)}</td>
                                                                        <td>{currencyFormat(d.montant_net_ht)}</td>
                                                                        <td>{d.remise?.taux}</td>
                                                                        <td>{d.remise?.montant}</td>
                                                                        <td>
                                                                            {d.contributions?.map(c => (
                                                                                <span key={c.contribution_id} className="rounded bg-gray-300 px-1 me-1">
                                                                                    {c.valeur}
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>
                                                                            {d.contributions?.map(c => (
                                                                                <span key={c.contribution_id} className="rounded bg-gray-300 px-1 me-1">
                                                                                    {currencyFormat(c.montant)}
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>{currencyFormat(d.prix_revient)}</td>
                                                                        <td>{currencyFormat(d.montant_ttc)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                }
                                                {(proforma.details.length == 0) &&
                                                    <em>- Aucune ligne de détail n'a été saisie -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="observations" className="border-0 mb-3">
                                            <Accordion.Header>Observations</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {proforma.observation &&
                                                    <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(proforma.observation)))} readOnly={true} />
                                                }
                                                {!proforma.observation &&
                                                    <em>- Aucune observation rédigée -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                }
                            </Tab>

                            <Tab eventKey="corrige" title="Documents liés" className="light-v-scroll height-full-min-250 pe-2">
                                <span className="d-flex flex-column border-start border-gray-400 ps-3">

                                    {proforma.historiques.precedents.facture &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/proformas/?proformaId=${proforma.historiques.precedents.facture}`}>Facture {proforma.historiques.precedents.facture}</a>
                                    }
                                    {proforma.historiques.precedents.proforma &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/proformas/?proformaId=${proforma.historiques.precedents.proforma}`}>Proforma {proforma.historiques.precedents.proforma}</a>
                                    }
                                    {proforma.historiques.precedents.avoir &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/proformas/?proformaId=${proforma.historiques.precedents.avoir}`}>Avoir {proforma.historiques.precedents.avoir}</a>
                                    }

                                    {(proforma.historiques.precedents.avoir || proforma.historiques.precedents.proforma || proforma.historiques.suivants.avoir || proforma.historiques.suivants.proforma) &&
                                        <p className="time-dot my-2">Document actuel ({proforma.type} {proforma._id})</p>
                                    }

                                    {proforma.historiques.suivants.avoir &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/proformas/?proformaId=${proforma.historiques.suivants.avoir}`}>Avoir {proforma.historiques.suivants.avoir}</a>
                                    }
                                    {proforma.historiques.suivants.proforma &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/proformas/?proformaId=${proforma.historiques.suivants.proforma}`}>Proforma {proforma.historiques.suivants.proforma}</a>
                                    }
                                    {proforma.historiques.suivants.facture &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/proformas/?proformaId=${proforma.historiques.suivants.facture}`}>Facture {proforma.historiques.suivants.facture}</a>
                                    }
                                </span>

                                {(!proforma.historiques.precedents.avoir && !proforma.historiques.precedents.proforma && !proforma.historiques.suivants.avoir && !proforma.historiques.suivants.proforma) &&
                                    <em>- Aucun document lié -</em>
                                }
                            </Tab>


                        </Tabs>
                    </div>



                </>
            }
        </div>
    )

}

export default ProformaDetails;

