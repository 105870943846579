import {axiosAPI} from "./axiosClient"
import qs from "qs"
import { getParams } from '../helper/Helper'

export const TarifService = {

  getTarifs(folderId, params) {
    return axiosAPI.get(`/${folderId}/tarifs?lookup=true&${getParams(params)}`)
  },

  getTarif(folderId, tarifId){
    return axiosAPI.get(`/${folderId}/tarifs/${tarifId}?lookup=true`)
  },

  updateTarif(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/tarifs`, qs.stringify(dataPost))
  },

  postTarif(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/tarifs`, qs.stringify(dataPost))
  },

  removeTarifs(folderId, tarifIdList) {
    var params = '?tarifs[]=' + tarifIdList.join('&tarifs[]=');
    return axiosAPI.delete(`/${folderId}/tarifs${params}`)
  },

  getNextCode(folderId) {
    return axiosAPI.get(`/${folderId}/tarifs/nextcode`)
  },

  updateTarif(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/tarifs`, qs.stringify(dataPost))
  },

  getFamilles(folderId, tarifId, params){
    return axiosAPI.get(`/${folderId}/tarifs/${tarifId}/familles?lookup=true&${getParams(params)}`)
  },
  
  postFamille(folderId, tarifId, dataPost){
    return axiosAPI.post(`/${folderId}/tarifs/${tarifId}/familles`, qs.stringify(dataPost))
  },

  updateFamille(folderId, tarifId, dataPost){
    return axiosAPI.put(`/${folderId}/tarifs/${tarifId}/familles`, qs.stringify(dataPost))
  },
  
  removeFamille(folderId, tarifId, familleId){
    return axiosAPI.delete(`/${folderId}/tarifs/${tarifId}/familles/${familleId}`)
  },

  getArticles(folderId, tarifId, params){
    return axiosAPI.get(`/${folderId}/tarifs/${tarifId}/articles?lookup=true&${getParams(params)}`)
  },

  getArticleList(folderId, tarifId, params){
    return axiosAPI.get(`/${folderId}/tarifs/${tarifId}/articles/liste?lookup=true&${getParams(params)}`)
  },

  postArticle(folderId, tarifId, dataPost){
    return axiosAPI.post(`/${folderId}/tarifs/${tarifId}/articles`, qs.stringify(dataPost))
  },

  updateArticle(folderId, tarifId, dataPost){
    return axiosAPI.put(`/${folderId}/tarifs/${tarifId}/articles`, qs.stringify(dataPost))
  },
  
  removeArticle(folderId, tarifId, articleId){
    return axiosAPI.delete(`/${folderId}/tarifs/${tarifId}/articles/${articleId}`)
  },

  getClients(folderId, tarifId, params){
    return axiosAPI.get(`/${folderId}/tarifs/${tarifId}/clients?lookup=true&${getParams(params)}`)
  },

  getClients(folderId, tarifId, params){
    return axiosAPI.get(`/${folderId}/tarifs/${tarifId}/clients?lookup=true&${getParams(params)}`)
  },

  postClient(folderId, tarifId, dataPost){
    return axiosAPI.post(`/${folderId}/tarifs/${tarifId}/clients`, qs.stringify(dataPost))
  },

  removeClients(folderId, tarifId, clientIdList){
    var params = '?clients[]=' + clientIdList.join('&clients[]=');
    return axiosAPI.delete(`/${folderId}/tarifs/${tarifId}/clients${params}`)
  },

}