module.exports = global.config = {
  "_DEBUG_" : false,

  // URL api stockweb
  "_API_URL_" : "https://api.stockxl.nc",
//  "_API_URL_" : "https://api.xlprog.nc/stockweb/v0",

  // URL du serveur OAuth partie publique
  "_OAUTH_PUBLIC_API_" : "https://oauth.xlprog.nc",

  // Infos du client OAuth
  // "_OAUTH_CLIENT_ID_" : "9c6330c4-5d74-4abc-ae53-720f6f8bb74a",
  "_OAUTH_CLIENT_ID_" : "3d1178bf-d945-42c0-86e9-c31ab1783697",
  "_OAUTH_CLIENT_SECRET_" : "secret"
}; 
