// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#PersonnaliserTheme input[type=color] {
  appearance: none;
  overflow: hidden;
  width: 30px;
  height: 20px;
  display: inline-block;
  padding: 0;
  margin: 0 8px 0 0;
  vertical-align: middle;
}
#PersonnaliserTheme input[type=color]::-webkit-color-swatch-wrapper {
  padding: 0;
}
#PersonnaliserTheme input[type=color]::-webkit-color-swatch {
  border: none;
}
#PersonnaliserTheme .image-control img {
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/administration/personnaliser-theme/PersonnaliserTheme.scss"],"names":[],"mappings":"AAGI;EACI,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,UAAA;EACA,iBAAA;EACA,sBAAA;AAFR;AAII;EACI,UAAA;AAFR;AAII;EACI,YAAA;AAFR;AAMQ;EACI,YAAA;AAJZ","sourcesContent":["#PersonnaliserTheme{\n\n\n    input[type=\"color\"] {\n        appearance: none;\n        overflow: hidden;\n        width: 30px;\n        height: 20px;\n        display: inline-block;\n        padding: 0;\n        margin: 0 8px 0 0;\n        vertical-align: middle;\n    }\n    input[type=\"color\"]::-webkit-color-swatch-wrapper {\n        padding: 0;\n    }\n    input[type=\"color\"]::-webkit-color-swatch {\n        border: none;\n    }\n\n    .image-control{\n        img{\n            width: 100px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
