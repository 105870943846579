import React, { useEffect, useState, useRef } from 'react';
import { ReglementService } from '../../../../services/ReglementService';
import { EditionService } from '../../../../services/EditionService';
import { Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, Table, Alert, Form, Modal, FloatingLabel } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, currencyFormat, formattedDateInput, getFile } from '../../../../helper/Helper'
import ClientInput from "../../../form-inputs/client-input/ClientInput";
import FactureInput from "../../../form-inputs/facture-input/FactureInput";
import FactureListInput from "../../../form-inputs/facture-list-input/FactureListInput";


const ReglementEdition = (props) => {

    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [modalError, setModalError] = useState()
    const [reglementsModes, setReglementsModes] = useState()
    const [currentMode, setCurrentMode] = useState()
    const [showModalValidation, setShowModalValidation] = useState({ show: false, reglement: null })


    const form = useRef({
        "_id": "",
        /* "client_nom": "", */
        "observation": "",
        /* "date": formattedDateInput(new Date()), */
        "date_valeur": formattedDateInput(new Date()),
        "mode_paiement_id": "",
        "numero_piece": "",
        "organisme": "",
        "client_id": "",
        "facture_id": "",
        "montant": ""
    });

    const clientFilters = useRef({
        'filter[type]': [{ operator: "=", value: "debit" }]
    });

    const factureFilters = useRef({
        'filter[client_id]': [{ operator: "=", value: form.current.client_id }]
    });

    useEffect(() => {
        getReglementsModes()
    }, []);




    function handleChange(e) {

        form.current[e.target.name] = e.target.value

        if (e.target.name == "mode_paiement_id") {
            let mode = reglementsModes.find(mode => mode._id === e.target.value)
            setCurrentMode(mode)
            if (!mode.piece_comptable) {
                form.current['organisme'] = ""
                form.current['numero_piece'] = ""
            }
        }

        setInvalidData({ ...invalidData, [e.target.name]: null })
    }


    function getReglementsModes() {
        ReglementService.getReglementsModes(props.folderId).then(res => {
            setReglementsModes(res.data.data.modespaiements)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function changeClient(c) {
        if (c?._id) {
            form.current['client_id'] = c._id
            factureFilters.current['filter[client_id]'] = [{ operator: "=", value: c._id }]
        } else {
            form.current['client_id'] = ""
            factureFilters.current['filter[client_id]'] = [{ operator: "", value: "" }]
        }
        form.current['facture_id'] = ""
        setInvalidData({ ...invalidData, ['client_id']: null })
    }

    function setFacture(factureList) {

        if (factureList.length == 0) {
            form.current['facture_id'] = ""
            form.current['observation'] = form.current['observation'].replace(/\[.*\]/g, "")
        }

        if (factureList.length == 1) {
            form.current['facture_id'] = factureList[0]._id

            form.current['observation'] = form.current['observation'].replace(/\[.*\]/g, "")
            form.current['observation'] = "[Règlements portant sur la facture : " + factureList[0].code + "]" + form.current['observation']
        }

        if (factureList.length > 1) {
            form.current['facture_id'] = ""

            let codeList = []
            factureList.map(facture => {
                codeList.push(facture.code)
            })
            form.current['observation'] = form.current['observation'].replace(/\[.*\]/g, "")
            form.current['observation'] = "[Règlements portant sur les factures : " + codeList.toString() + "]" + form.current['observation']

        }

        setInvalidData({ ...invalidData, ['factures']: null })
    }

    function addReglement() {
        ReglementService.postReglement(props.folderId, { reglements: [form.current] }).then(res => {
            setShowModalValidation({ show: true, reglement: res.data.data.reglements[0] })
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function downloadReceiptPDF() {
        EditionService.getReglement(props.folderId, showModalValidation.reglement, "pdf").then(res => {
            if (!res.data) {
                setModalError({ statusText: res.statusText })
            } else {
                getFile(res.data, `Reçu`)
                props.onSetSaved(showModalValidation.reglement)
                setShowModalValidation({ show: false, reglement: null })
            }
        }).catch(error => {
            setModalError(error)
        });
    }


    function closeModalValidation() {
        props.onSetSaved(showModalValidation.reglement)
        setShowModalValidation({ show: false, reglement: null })
    }

    return (
        <>

            <Form.Group className="mb-3 float-end me-2 d-flex flex-column" controlId="formBasicEmail">
                <Button
                    disabled={!form.current.client_id || !form.current.montant || !form.current.mode_paiement_id}
                    variant="primary"
                    onClick={() => addReglement()}>
                    Enregistrer
                </Button>
            </Form.Group>

            <div className="d-flex  mb-4 ps-2">
                <div className="pe-3 w-100">
                    <h6 className="text-secondary m-0">Créer un</h6>
                    <h6 className="fw-bolder m-0">Nouveau reglement</h6>
                </div>
            </div>

            <div className="light-v-scroll pe-3 ps-2 pb-5 w-100">

                {globalError && (
                    <ErrorManager error={globalError} />
                )}

                {showModalValidation.show &&

                    <Modal size="sm" show centered>

                        <Modal.Header className="border-0 pb-0">
                            Le règlement a bien été enregistré.
                        </Modal.Header>

                        <Modal.Body className="">
                            <ErrorManager error={modalError} />
                            <Button className="w-100 mb-2" variant="primary" onClick={() => downloadReceiptPDF()}>Télécharger le reçu</Button>
                        </Modal.Body>

                        <Modal.Footer className="border-0">
                            <Button variant="secondary" onClick={() => closeModalValidation()} >
                                Fermer
                            </Button>
                        </Modal.Footer>

                    </Modal >
                }


                <Form>


                    <Form.Group className="mb-3">
                        <Form.Label>Date valeur (date d'exécution)</Form.Label>
                        <Form.Control name="date_valeur" value={form.current.date_valeur} isInvalid={getFormErrorMsg(invalidData?.date_valeur)} onChange={handleChange} type="date" />
                        <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_valeur)}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Client<span className="text-danger">*</span></Form.Label>
                        <ClientInput autoFocus value={form.current.client_id} name="client_id" filters={clientFilters.current} isInvalid={getFormErrorMsg(invalidData?.client_id)} clientId={form.current.client_id} folderId={props.folderId} setClient={c => changeClient(c)} />
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                        <Form.Label>Date</Form.Label>
                        <Form.Control name="date" value={form.current.date} isInvalid={getFormErrorMsg(invalidData?.date)} onChange={handleChange} type="date" />
                        <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date)}</Form.Control.Feedback>
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                        <Form.Label>Mode de paiement<span className="text-danger">*</span></Form.Label>
                        <Form.Select name="mode_paiement_id" defaultValue={form.current.mode_paiement_id} onChange={handleChange}>
                            <option value="" disabled>- Choisir -</option>
                            {reglementsModes?.map(mode =>
                                <option key={mode._id} value={mode._id}>{mode.libelle}</option>
                            )}
                        </Form.Select>
                    </Form.Group>

                    {currentMode?.piece_comptable &&
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Organisme bancaire</Form.Label>
                                <Form.Control name="organisme" value={form.current.organisme} isInvalid={getFormErrorMsg(invalidData?.organisme)} onChange={handleChange} type="text" />
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.organisme)}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Numéro de pièce</Form.Label>
                                <Form.Control name="numero_piece" value={form.current.numero_piece} isInvalid={getFormErrorMsg(invalidData?.numero_piece)} onChange={handleChange} type="text" />
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.numero_piece)}</Form.Control.Feedback>
                            </Form.Group>
                        </>
                    }

                    <Form.Group className="mb-3">
                        <Form.Label>Montant<span className="text-danger">*</span></Form.Label>
                        <Form.Control name="montant" value={form.current.montant} isInvalid={getFormErrorMsg(invalidData?.montant)} onChange={handleChange} type="number" />
                        <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.montant)}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Factures</Form.Label>
                        <FactureListInput reset={form.current.client_id} disabled={!form.current.client_id} name="factures" filters={factureFilters.current} isInvalid={getFormErrorMsg(invalidData?.factures)} folderId={props.folderId} addedFactureList={factureList => setFacture(factureList)} />
                        {/* <FactureInput value={form.current.facture_id} disabled={!form.current.client_id} name="facture_id" filters={factureFilters.current} isInvalid={getFormErrorMsg(invalidData?.facture_id)} factureId={form.current.client_id ? form.current.facture_id : ""} folderId={props.folderId} setFacture={f => changeFacture(f)} /> */}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Observations</Form.Label>
                        <Form.Control as="textarea" name="observation" value={form.current.observation} isInvalid={getFormErrorMsg(invalidData?.observation)} onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.client_nom)}</Form.Control.Feedback>
                    </Form.Group>

                </Form>


            </div>
        </>

    )

}

export default ReglementEdition;