import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Badge, Col, Row, Form, OverlayTrigger, Tooltip, Toast, ToastContainer, Spinner } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { ProformaService } from '../../services/ProformaService';
import { EditionService } from '../../services/EditionService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import ProformaDetails from '../../components/side-details/proforma/proforma-details/ProformaDetails';
//import ProformaEdition from '../../components/side-details/proforma/proforma-edition/ProformaEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Proformas.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'
import Brouillons from "../../components/brouillons/Brouillons";


const Proformas = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let proformasParams = useRef({
    'start': 0,
    'limit': 50,
    'search': null,
    'filters': null,
    'sort': null,
    'order': null,
    'includes': null
  });

  const navigate = useNavigate();

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [proformas, setProformas] = useState()
  const [isGetProformasLoading, setIsGetProformasLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentProforma, setCurrentProforma] = useState()

  const [tableRowElementActions] = useState([
    { icon: "edit", title: "Modifier la proforma", action: "editProforma" },
    { icon: "task", title: "Transformer en facture", action: "facturerProforma" },
    { icon: "file_copy", title: "Dupliquer", action: "duplicateProforma" },
    { icon: "print", title: "Imprimer la proforma", action: "downloadPDF" }
  ])

  const [proformaElementActions] = useState([
    { icon: "edit", title: "Modifier la proforma", action: "editProforma" },
    { icon: "task", title: "Transformer en facture", action: "facturerProforma" },
    { icon: "file_copy", title: "Dupliquer", action: "duplicateProforma" },
    { icon: "print", title: "Imprimer la proforma", action: "downloadPDF" }
  ])

  useEffect(() => {
    if (grants) {
      !grants.proformas.valeur && setGlobalError({ statusText: `Proformas : vous n'avez pas les droit d'accès à cette ressource.` })
      granted(grants, "proformas.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeProformaDialog" })
      granted(grants, "proformas.remove") && proformaElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeProformaDialog" })
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('proformaId') && getProforma(searchParams.get('proformaId'))
      setPreferences()
      getProformas()

      // Si un brouillon est en cours d'édition on redirige.
      if (!searchParams.get('factureId') && preferences.proformas.currentDraft) {
        navigate(`/${folderDetails._id}/proformas/brouillon?brouillonId=${preferences.proformas.currentDraft}&from=proformas`)
      }

    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.proformas['tableHeaders'], parametres.proformas['fieldsLabel']))
    proformasParams.current['sort'] = preferences.proformas['tableSort']
    proformasParams.current['order'] = preferences.proformas['tableOrder']
    proformasParams.current['order'] = preferences.proformas['tableOrder']
    proformasParams.current['includes'] = preferences.proformas['proformasIncludes']
    proformasParams.current['fields'] = Object.keys(preferences.proformas['tableHeaders']).join(',')
  }

  function getProforma(proformaId) {
    ProformaService.getProforma(folderDetails._id, proformaId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentProforma(res.data.data)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getProformas() {
    if (granted(grants, "proformas.consult")) {
      setIsGetProformasLoading(true)
      ProformaService.getProformas(folderDetails._id, proformasParams.current).then(res => {
        res.data.data && addCustomEtatColumn(res.data.data)
        setProformas(res.data.data)
        setIsGetProformasLoading(false)
      }).catch(error => {
        setIsGetProformasLoading(false)
        setGlobalError(error)
      });
    }
  }

  function addCustomEtatColumn(data) {
    data.proformas.map(proforma => {

      let value = ""
      if(proforma.validite_jours_calc < 0){
        value = "Expirée"
      }
      if(proforma.historiques.suivants.proforma){
        value = "Annulée"
      }
      if(proforma.historiques.suivants.facture){
        value = "Facturée"
      }
      proforma['front_custom_etat'] = value
    })
  }

  function getProformasPDF() {
    let params = {
      'start': 0,
      'search': proformasParams.current.search,
      'sort': proformasParams.current.sort,
      'order': proformasParams.current.order,
      'filters': proformasParams.current.filters,
      'includes': { 'expirees': proformasParams.current.includes.expirees, 'annulees': proformasParams.current.includes.annulees, 'facturees': proformasParams.current.includes.facturees }
    }
    if (granted(grants, "proformas.consult")) {
      setIsPDFLoading(true)
      ProformaService.getProformasPDF(folderDetails._id, params).then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Proformas - Liste`)
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function removeProformaList(proformaIdList) {
    ProformaService.removeProformas(folderDetails._id, proformaIdList).then(res => {
      setConfirmDialog({ show: false });
      getProformas()
      changeCurrentProforma()
      setSideWidth()
      uncheckElementList(proformaIdList)
    }).catch(err => {
      setConfirmDialog({
        ...confirmDialog,
        dialogInfos: { ...confirmDialog.dialogInfos, error: err }
      })
    });
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }

  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['proformas']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentProforma(proforma) {
    proforma?._id ? setSearchParams({ proformaId: proforma._id }) : setSearchParams(searchParams.delete('proformaId'))
    setCurrentProforma(proforma)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    proformasParams.current['start'] = (proformasParams.current.limit * p) - proformasParams.current.limit
    getProformas()
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      proformasParams.current['start'] = 0
      proformasParams.current['search'] = value
      getProformas()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      proformasParams.current['start'] = 0
      proformasParams.current['filters'] = f
      getProformas()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    proformasParams.current['start'] = 0
    proformasParams.current['filters'] = null
    getProformas()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    proformasParams.current['sort'] = s
    proformasParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getProformas()
  }

  function onProformaSaved(proformaId) {
    getProforma(proformaId)
    getProformas()
  }

  function closeSide() {
    setCurrentProforma()
  }

  function openBrouillon(brouillonId, documentType) {
    navigate(`/${folderDetails._id}/${documentType}/brouillon?brouillonId=${brouillonId}&from=proformas`)
  }

  function createNewProforma() {
    let newProforma = {
      "type": "proforma"
    }
    ProformaService.postProforma(folderDetails._id, { proformas: [newProforma] }).then(res => {
      for (var brouillonId in res.data.data.brouillons) {
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });

  }

  function removeProformaListDialog(proformaIdList) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer ${proformaIdList.length} proforma(s) ?`,
        description: 'Voulez vous supprimer les éléments sélectionnés ?',
        actionName: 'Supprimer la sélection',
        btnVariant: 'danger',
        element: proformaIdList,
        error: false,
        confirmAction: 'removeProformaList'
      }
    })
  }

  function removeProformaDialog(proforma) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: proforma.nom,
        description: 'Voulez vous supprimer cet élément ?',
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: [proforma._id],
        error: false,
        confirmAction: 'removeProformaList'
      }
    })
  }

  function downloadPDF(proforma) {
    EditionService.getProforma(folderDetails._id, proforma._id, "pdf").then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        getFile(res.data, `Proforma ${proforma.code}`)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function editProforma(proforma) {
    ProformaService.updateProforma(folderDetails._id, proforma._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function facturerProforma(proforma) {
    ProformaService.facturerProforma(folderDetails._id, proforma._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "factures")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function duplicateProforma(proforma) {
    ProformaService.duplicateProforma(folderDetails._id, proforma._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeParamsIncludes(e) {
    console.log('CHANGE')
    proformasParams.current.includes[e.target.name] = e.target.checked
    updatePreference({ ["proformasIncludes"]: proformasParams.current.includes })
    setCurrentPagination(1)
    proformasParams.current['start'] = 0
    getProformas()
  }

  return (
    <Container fluid id="Proformas" className="py-4">

      <ErrorManager error={globalError} fixed="true" />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row>
            {granted(grants, "proformas.consult") &&
              <>
                <Col xs="12" xl="3" className="mb-sm-2 mb-xl-0">
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="search"
                      onChange={e => changeSearchValue(e.target.value)}
                    />

                    {!proformasParams.current.filters &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                        <Button variant={proformasParams.current.filters ? 'warning' : 'secondary'} onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                          <i className="material-icons i-lg">filter_alt</i>
                        </Button>
                      </OverlayTrigger>
                    }

                    {proformasParams.current.filters &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Supprimer les filtres</Tooltip>}>
                        <Button variant={proformasParams.current.filters ? 'warning' : 'secondary'} onClick={() => clearFilters()}>
                          <i className="material-icons i-lg">clear</i>
                        </Button>
                      </OverlayTrigger>
                    }

                  </InputGroup>
                </Col>
                <Col xs="12" xl="auto" className="d-flex align-items-center mb-sm-2 mb-xl-0">
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="ms-2" onClick={() => getProformasPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer
                  </Button>

                  {/* <Form.Check
                    className="ms-3"
                    label="Validées"
                    type="switch"
                    name="valides"
                    onChange={e => changeParamsIncludes(e)}
                    defaultChecked={proformasParams.current.includes.valides}
                  /> */}
                  <Form.Check
                    className="ms-3"
                    label="Expirées"
                    type="switch"
                    name="expirees"
                    disabled={isGetProformasLoading}
                    onClick={e => changeParamsIncludes(e)}
                    defaultChecked={proformasParams.current.includes?.expirees}
                  />
                  <Form.Check
                    className="ms-3"
                    label="Annulées"
                    type="switch"
                    name="annulees"
                    disabled={isGetProformasLoading}
                    onClick={e => changeParamsIncludes(e)}
                    defaultChecked={proformasParams.current.includes?.annulees}
                  />
                  <Form.Check
                    className="ms-3"
                    label="Facturées"
                    type="switch"
                    name="facturees"
                    disabled={isGetProformasLoading}
                    onClick={e => changeParamsIncludes(e)}
                    defaultChecked={proformasParams.current.includes?.facturees}
                  />

                </Col>
              </>
            }
            <Col xs="12" xl="auto" className="mb-sm-2 mb-xl-0 ms-auto d-flex">
              {checkedElementIdList?.length > 0 &&
                <Dropdown className="d-inline-block me-2">
                  <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                    {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item>
                    <Dropdown.Item>Imprimer</Dropdown.Item>
                    {granted(grants, "proformas.remove") &&
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => removeProformaListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                      </>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              }

              {/* Brouillons en cours */}
              <Brouillons brouillonTypes={["proforma"]} openBrouillon={b => openBrouillon(b, "proformas")} grantedConsult={granted(grants, "brouillons.consult")} grantedEdit={granted(grants, "brouillons.edit")} grantedRemove={granted(grants, "brouillons.remove")} />

              {granted(grants, "proformas.edit") &&
                <Button variant="primary" onClick={() => createNewProforma()}>Créer une proforma</Button>
              }

            </Col>
          </Row>

          {granted(grants, "proformas.consult") &&
            <Row className="mt-3">
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">

                  <DynamicTable
                    isDataLoading={isGetProformasLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={proformas?.proformas}
                    currentElement={currentProforma}
                    changeCurrentElement={e => changeCurrentProforma(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={proformasParams.current}
                  />
                  <Pagination itemsLength={proformas?.total} elementsPerPage={proformasParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />
                </div>
              </Col>
            </Row>
          }

          {currentProforma &&
            <SideDetails
              currentElement={{ id: currentProforma?._id, label: new Date(currentProforma?.date).toLocaleString(), title: currentProforma?.client_nom }}
              changeCurrentElement={e => changeCurrentProforma(e)}
              setSideClose={closeSide}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                <ProformaDetails folderId={folderDetails._id} proformaId={currentProforma._id} elementActions={proformaElementActions} setElementAction={(a, e) => eval(a)(e)} />
              }
            />
          }
        </Col>
      </Row>

    </Container>


  );
}

export default Proformas;