import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Form, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { reorder } from '../../../../helper/Helper'

import './ColManager.scss';

const ColManager = (props) => {

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let headers = reorder(props.tableHeaders, result.source.index, result.destination.index)
        headers.map((header, i) => {
            header.position = i
        })
        props.changeTableHeaders(headers);
    }

    function orderUpDown(index, value) {
        let headers = reorder(props.tableHeaders, index, index + value)
        headers.map((header, i) => {
            header.position = i
        })
        props.changeTableHeaders(headers);
    }

    function changeVisibility(evt) {
        const headers = [...props.tableHeaders];
        headers[evt.target.value].hidden = !evt.target.checked
        props.changeTableHeaders(headers);
    }

    return (
        <>

            <Dropdown id="col-manager" className="float-start">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 800 }}
                    overlay={
                        <Tooltip>
                            Modifier les colonnes
                        </Tooltip>
                    }
                >
                    <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                        <i className="material-icons i-lg">table_chart</i>
                    </Dropdown.Toggle>
                </OverlayTrigger>
                <Dropdown.Menu className="mt-1">
                    <div className="px-2 py-0">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="TableHeaders">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {props.tableHeaders?.map((item, index) => (
                                            <span key={item.dataTarget}>
                                                {item.dataTarget != "_id" &&
                                                    <Draggable draggableId={item.dataTarget} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={"draggable py-1 px-2 " + (snapshot.isDragging ? "bg-gray-400 rounded " : "bg-white ")}
                                                            >

                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <span>
                                                                        <Form.Check
                                                                            className="me-1"
                                                                            type="switch"
                                                                            inline
                                                                            value={index}
                                                                            checked={!item.hidden}
                                                                            onChange={changeVisibility}
                                                                        />
                                                                        {item.label}
                                                                    </span>

                                                                    <span className="ms-4 text-secondary">
                                                                        <i className={"material-icons hover-bg-gray-200 rounded px-1 float-start " + (index == 0 ? '' : 'cursor-pointer')} onClick={() => orderUpDown(index, -1)}>arrow_drop_up</i>
                                                                        <i className={"material-icons hover-bg-gray-200 rounded px-1 float-end " + (index == props.tableHeaders.length - 1 ? '' : 'cursor-pointer')} onClick={() => orderUpDown(index, 1)}>arrow_drop_down</i>
                                                                    </span>

                                                                </div>


                                                            </div>
                                                        )}
                                                    </Draggable>
                                                }
                                            </span>
                                        ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </Dropdown.Menu>
            </Dropdown>



        </>
    );
}

export default ColManager;