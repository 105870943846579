import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, InputGroup, Row, Badge, Label, Table, Tooltip, Spinner, OverlayTrigger, FormControl } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';
import { TarifService } from '../../../services/TarifService';
import Pagination from '../../../components/pagination/Pagination';
import './TarifInput.scss';
import { GlobalContext } from "../../../services/GlobalProvider";
import { useOuterClick, currencyFormat, granted, formattedDateInput, getFormErrorMsg } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";




const TarifInput = (props) => {

    const [selectedTarif, setSelectedTarif] = useState()
    const [currentPagination, setCurrentPagination] = useState(1)
    const [isGetTarifsLoading, setIsGetTarifsLoading] = useState(false)
    const [tarifsError, setTarifsError] = useState()
    const [tarifs, setTarifs] = useState()
    const [tarifLoading, setTarifLoading] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [activeRowIndex, setActiveRowIndex] = useState(1)
    const [globalError, setGlobalError] = useState()
    const [filterChecked, setFilterChecked] = useState("libelle")
    const [tarifInputLabel, setTarifInputLabel] = useState("")

    const { parametres, folderDetails, userDetails, grants } = React.useContext(GlobalContext);

    const searchRef = useRef(null);

    let tarifsParams = useRef({
        'start': 0,
        'limit': 8,
        'filters': props.filters || {}
    });

    useEffect(() => {
        //setTarifLoading(false)
    }, [props.isInvalid]);

    useEffect(() => {
        if (!isDropdownOpen) {
            searchRef.current.value = tarifInputLabel
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        if (props.tarifId) {
            getTarif(props.tarifId)
        } else {
            setSelectedTarif()
            setTarifInputLabel()
            searchRef.current.value = null
        }
    }, [props.tarifId]);




    function getTarif(tarifId) {
        setTarifLoading(true)
        TarifService.getTarif(props.folderId, tarifId).then(res => {
            setSelectedTarif(res.data.data)
            setTarifInputLabel(`${res.data.data.code} - ${res.data.data.libelle}`)
            searchRef.current.value = `${res.data.data.code} - ${res.data.data.libelle}`
            setTarifLoading(false)
        }).catch(error => {
            setSelectedTarif()
            setTarifLoading(false)
            setGlobalError(error)
        });
    }

    function addTarif(tarif) {
        setIsDropdownOpen(false)
        props.setTarif(tarif)
    }

    function removeTarif() {
        setIsDropdownOpen(false)
        //searchRef.current.value = null
        props.setTarif()
    }

    function getTarifs() {
        setIsGetTarifsLoading(true)
        setTarifsError()
        setActiveRowIndex()
        TarifService.getTarifs(props.folderId, tarifsParams.current).then(res => {
            setIsGetTarifsLoading(false)
            setTarifs(res.data ? res.data.data : null)
            setActiveRowIndex(0)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetTarifsLoading(false)
            setTarifsError(error)
        });
    }

    function changePagination(p) {
        setCurrentPagination(p)
        tarifsParams.current['start'] = (tarifsParams.current.limit * p) - tarifsParams.current.limit
        getTarifs()
    }

    let searchTimeOut;
    function changeSearchValue(e) {
        window.clearTimeout(searchTimeOut);
        setIsDropdownOpen(true)
        searchTimeOut = window.setTimeout(function () {
            setCurrentPagination(1)
            searchRef.current.value = e.target.value
            tarifsParams.current['start'] = 0

            tarifsParams.current['filters'][`filter[${filterChecked}]`] = [{ operator: "", value: searchRef.current.value }]
            //tarifsParams.current['filters'] = { [`filter[${filterChecked}]`]: [{ operator: "", value: searchRef.current.value }] }
            getTarifs()
        }, 1000);

    }

    function handleFilterChange(e) {
        //updatePreference("tarifSearchFilter", e.target.name)
        setFilterChecked(e.target.name)
        setCurrentPagination(1)
        tarifsParams.current['start'] = 0

        if (e.target.name == "code") {
            tarifsParams.current['filters'][`filter[code]`] = [{ operator: "", value: searchRef.current.value }]
            delete tarifsParams.current['filters'][`filter[libelle]`];
        }
        if (e.target.name == "libelle") {
            tarifsParams.current['filters'][`filter[libelle]`] = [{ operator: "", value: searchRef.current.value }]
            delete tarifsParams.current['filters'][`filter[code]`];
        }
        //tarifsParams.current['filters'] = { [`filter[${e.target.name}]`]: [{ operator: "", value: searchRef.current.value }] }
        getTarifs()
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (activeRowIndex != null && isDropdownOpen) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    setActiveRowIndex((activeRowIndex < tarifs?.tarifs.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    addTarif(tarifs.tarifs[activeRowIndex])
                    break;
                case "Escape":
                    setIsDropdownOpen(false)
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div id="TarifInput" className="position-relative">

            <InputGroup className="">

                {!tarifLoading &&
                    <i className="material-icons input-icon text-gray-400">search</i>
                }

                <FormControl
                    autoFocus={props.autoFocus}
                    isInvalid={props.isInvalid}
                    disabled={tarifLoading || props.disabled}
                    className={"rounded-start input-icon-space " + ((!props.tarifComptantButton && !selectedTarif) ? "rounded" : "")}
                    type="text"
                    ref={searchRef}
                    onChange={changeSearchValue}
                    placeholder="Rechercher un tarif"
                />



                {tarifLoading &&
                    <span className="input-icon text-gray-400">
                        <Spinner animation="border" size="sm" className="me-2" />
                    </span>
                }



                {props.tarifComptantButton == "true" &&
                    <OverlayTrigger
                        delay={{ show: 800 }}
                        overlay={
                            <Tooltip>
                                Tarif comptant
                            </Tooltip>
                        }
                    >
                        <Button disabled={tarifLoading || props.disabled} onClick={() => addTarif(props.tarifComptant)} variant="secondary">
                            <i className="material-icons i-lg">account_box</i>
                        </Button>
                    </OverlayTrigger>

                }

                {selectedTarif &&
                    <OverlayTrigger
                        delay={{ show: 800 }}
                        overlay={
                            <Tooltip>
                                Supprimer
                            </Tooltip>
                        }
                    >
                        <Button disabled={tarifLoading || props.disabled} onClick={() => removeTarif()} variant="secondary">
                            <i className="material-icons i-lg">delete_forever</i>
                        </Button>
                    </OverlayTrigger>

                }

                {props.isInvalid &&
                    <Form.Control.Feedback type="invalid">
                        {props.isInvalid}
                    </Form.Control.Feedback>
                }


            </InputGroup>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            {isDropdownOpen && (
                <span className="drop-container zindex-100 border border-gray-500 rounded p-3 position-absolute top-100 bg-white shadow-lg">

                    <i onClick={() => setIsDropdownOpen(false)} className="material-icons text-gray-300 fs-4 position-absolute top-0 end-0 me-2 mt-1 cursor-pointer">close</i>

                    <h6>Rechercher dans :</h6>
                    <Form.Group className="d-flex">
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "code"}
                            label="Code"
                            name="code"
                            type="radio"
                            id={`inline-1`}
                        />
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "libelle"}
                            label="Libelle"
                            name="libelle"
                            type="radio"
                            id={`inline-2`}
                        />
                    </Form.Group>

                    <hr />

                    {isGetTarifsLoading &&
                        <span className="hloader"></span>
                    }

                    {(!isGetTarifsLoading && !tarifs?.tarifs) &&
                        <em>- Aucun élément trouvé -</em>
                    }

                    {(!isGetTarifsLoading && tarifs?.tarifs) &&
                        <>
                            <Table size="sm" hover className="rounded">
                                <thead className="text-uppercase text-gray-600 small">
                                    <tr>
                                        <th>Code</th>
                                        <th>Libelle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tarifs?.tarifs?.map((tarif, i) => (
                                        <tr key={tarif?._id} className={"cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")} onClick={() => addTarif(tarif)}>
                                            <td>{tarif?.code}</td>
                                            <td>
                                                {tarif?.libelle}
                                                <Badge className="float-end" bg="secondary">{tarif?.type}</Badge>
                                                {tarif?.bloquer &&
                                                    <Badge className="me-2 float-end" bg="danger">Bloqué</Badge>
                                                }


                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination size="sm" itemsLength={tarifs?.total} elementsPerPage={tarifsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                        </>
                    }

                </span>
            )}

        </div>

    )
}
export default TarifInput;
