import React, { useEffect, useState } from 'react';
import { ArticleService } from '../../../../../services/ArticleService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Spinner, Card, Tabs, Tab, InputGroup, FormControl, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../../helper/Helper'


const Fournisseurs = (props) => {

    const [globalError, setGlobalError] = useState()

    const [getArticleFournisseursLoading, setGetArticleFournisseursLoading] = useState(false)
    const [articleFournisseursList, setArticleFournisseursList] = useState()
    const [filteredFournisseursList, setFilteredFournisseursList] = useState()
    const [fournisseursList, setFournisseursList] = useState()
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [fournisseurEditId, setFournisseurEditId] = useState()
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [searchValue, setSearchValue] = useState(null)
    const [isGetFournisseursLoading, setIsGetFournisseursLoading] = useState(true)

    useEffect(() => {
        let showList = []
        articleFournisseursList?.map(f => {
            if(f.fournisseur.nom.toLowerCase().includes(searchValue.toLowerCase()) || !searchValue){
                showList.push(f)
            }
        })
        setFilteredFournisseursList(showList)
    }, [searchValue]);

    useEffect(() => {
        getArticleFournisseurs()
        getFournisseurs()
    }, []);

    function getArticleFournisseurs() {
        setGetArticleFournisseursLoading(true)
        setGlobalError()
        ArticleService.getFournisseurs(props.folderId, props.article._id).then(res => {
            setGetArticleFournisseursLoading(false)
            setArticleFournisseursList(res.data ? res.data.data.fournisseurs : null)
            setFilteredFournisseursList(res.data ? res.data.data.fournisseurs : null)
        }).catch(error => {
            setGetArticleFournisseursLoading(false)
            setGlobalError(error)
        });

    }

    function getFournisseurs() {
        setIsGetFournisseursLoading(true)
        setGlobalError()
        FournisseurService.getFournisseurs(props.folderId).then(res => {
            setFournisseursList(res.data.data.fournisseurs)
            setIsGetFournisseursLoading(false)
        }).catch(error => {
            setIsGetFournisseursLoading(false)
            setGlobalError(error)
        });
    }

    function removeFournisseurConfirm(f) {

        setConfirmDialog({
            show: true,
            dialogInfos: {
                title: 'Supprimer',
                description: `Voulez vous supprimer ${f.fournisseur.nom} ?`,
                actionName: 'Supprimer',
                btnVariant: 'danger',
                element: f,
                error: false,
                confirmAction: 'removeFournisseur'
            }
        })

    }

    function removeFournisseur(f) {
        ArticleService.removeFournisseur(props.folderId, props.article._id, f.fournisseur_id).then(res => {
            getArticleFournisseurs()
            setConfirmDialog({ show: false })
        }).catch(error => {
            setGlobalError(error)
            setConfirmDialog({ show: false })
        });
    }

    const EditForm = (f) => {

        let fournisseur

        if(f.fournisseur){
            fournisseur = {
                fournisseur_id: f.fournisseur.fournisseur_id,
                reference_fournisseur: f.fournisseur.reference_fournisseur,
                type_transport: f.fournisseur.type_transport,
                cout_transport: f.fournisseur.cout_transport,
                prix_achat: f.fournisseur.prix_achat,
                devise: f.fournisseur.devise,
                type_transport: f.fournisseur.type_transport,
                valeur_devise: f.fournisseur.valeur_devise,
                prix_revient: f.fournisseur.prix_revient,
                date_achat: f.fournisseur.date_achat
            }
        }else{
            fournisseur = {
                fournisseur_id: '',
                reference_fournisseur: '',
                type_transport: '',
                cout_transport: '',
                prix_achat: '',
                devise: '',
                type_transport: '',
                valeur_devise: '',
                prix_revient: '',
                date_achat: ''
            }
        }

        const [fournisseurForm, setFournisseurForm] = useState(fournisseur)

        function handleChange(e) {
            setFournisseurForm({ ...fournisseurForm, [e.target.name]: e.target.value })
        }

        function addFournisseur() {
            ArticleService.postFournisseur(props.folderId, props.article._id, { fournisseurs: [fournisseurForm] }).then(res => {
                props.onFournisseurChange()
                getArticleFournisseurs()
                setIsFormOpen(false)
            }).catch(error => {
                setGlobalError(error)
            });
        }

        function updateFournisseur() {
            ArticleService.updateFournisseur(props.folderId, props.article._id, { fournisseurs: [fournisseurForm] }).then(res => {
                props.onFournisseurChange()
                getArticleFournisseurs()
                setFournisseurEditId()
            }).catch(error => {
                setGlobalError(error)
            });
        }

        function cancelEdition() {
            setIsFormOpen(false)
            setFournisseurEditId()
        }

        return (

            <>
                <td className="border-0"></td>
                <td className="border-0">
                    <Form.Select disabled={f.fournisseur} name="fournisseur_id" value={fournisseurForm.fournisseur_id} onChange={handleChange} size="sm">
                        <option>- Sélectionner -</option>
                        {fournisseursList?.map(f => (
                            <option disabled={articleFournisseursList && articleFournisseursList.findIndex(af => af.fournisseur_id == f._id) != -1} key={f._id} value={f._id}>{f.nom}</option>
                        ))}
                    </Form.Select>
                </td>
                <td className="border-0"><Form.Control name="reference_fournisseur" value={fournisseurForm.reference_fournisseur} onChange={handleChange} type="string" size="sm" /></td>
                <td className="border-0"><Form.Control name="prix_achat" value={fournisseurForm.prix_achat} onChange={handleChange} type="number" size="sm" /></td>
                <td className="border-0"><Form.Control name="devise" value={fournisseurForm.devise} onChange={handleChange} type="text" size="sm" /></td>
                <td className="border-0"><Form.Control name="type_transport" value={fournisseurForm.type_transport} onChange={handleChange} type="text" size="sm" /></td>
                <td className="border-0"><Form.Control name="date_achat" value={fournisseurForm.date_achat} onChange={handleChange} type="date" size="sm" /></td>
                <td className="border-0"></td>
                <td className="border-0 d-flex">
                    <Button size="sm" className="me-1" variant="secondary" onClick={() => cancelEdition()}>Annuler</Button>
                    <Button disabled={(!fournisseurForm.fournisseur_id || !fournisseurForm.date_achat)} size="sm" onClick={f.fournisseur ? updateFournisseur : addFournisseur}>Enregistrer</Button>
                </td>
            </>
        );
    }

    function popAdresse(adresse){
        return (
            <Popover id="popover-basic">
                <Popover.Header as="h3">{adresse.adresse}</Popover.Header>
                <Popover.Body>
                    <span className="me-2 d-block"><b>Libelle : </b>{adresse.libelle}</span>
                    <span className="me-2 d-block"><b>CP : </b>{adresse.code_postal}</span>
                    <span className="me-2 d-block"><b>Ville : </b>{adresse.ville}</span>
                    <span className="me-2 d-block"><b>Pays : </b>{adresse.pays}</span>
                    <span className="me-2 d-block"><b>Adresse principale : </b>{adresse.principal ? 'oui' : 'non'}</span>
                    <span className="me-2 d-block"><b>Observation : </b>{adresse.observation}</span>
                </Popover.Body>
            </Popover>

        )
    }

    return (
        <>

            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a,e) => eval(a)(e)}
            />

            {globalError && <ErrorManager error={globalError} />}

            {!getArticleFournisseursLoading && 
                <div className="d-flex justify-content-between mb-3 ">
                        <span className='flex-fill me-2'>
                            <InputGroup>
                                <i className="material-icons input-icon text-gray-400">search</i>
                                <FormControl
                                    className="rounded-start input-icon-space"
                                    disabled={isFormOpen || fournisseurEditId}
                                    placeholder="Rechercher (Nom)"
                                    type="search"
                                    onChange={e => setSearchValue(e.target.value)}
                                />
                            </InputGroup>
                        </span>
                    <Button disabled={isFormOpen || fournisseurEditId || !fournisseursList} onClick={() => setIsFormOpen(true)}>Ajouter un fournisseur</Button>
                </div>
            }

            {(!isGetFournisseursLoading && !fournisseursList) &&
                <Alert variant="warning">Aucun fournisseur existant, veuillez <a href={`/${props.folderId}/fournisseurs`}>créer un fournisseur</a> pour l'ajouter.</Alert>
            }

            {(!getArticleFournisseursLoading && (filteredFournisseursList?.length > 0 || isFormOpen)) && 
                <div className="overflow-auto" style={{minHeight:190}}>

                    <Table size="sm" className="mt-2 w-100 bg-gray-100 small" bordered>
                        <thead>
                            <tr className='small'>
                                <th>Code</th>
                                <th>Nom</th>
                                <th>Ref fournisseur</th>
                                <th>Prix d'achat</th>
                                <th>Devise</th>
                                <th>Type transport</th>
                                <th>Date d'achat</th>
                                <th>Adresses</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody className="align-middle">

                            {isFormOpen &&
                                <tr className="bg-gray-400"><EditForm /></tr>
                            }

                            {filteredFournisseursList?.map((fournisseur, i) => (

                                <tr key={i} className={(fournisseur.fournisseur_id == fournisseurEditId) ? "bg-gray-400" : ""}>

                                    {(fournisseur.fournisseur_id == fournisseurEditId) &&
                                        <EditForm fournisseur={fournisseur} />
                                    }

                                    {(fournisseur.fournisseur_id != fournisseurEditId) &&
                                        <>
                                            <td>{fournisseur.fournisseur?.code}</td>
                                            <td>{fournisseur.fournisseur?.nom}</td>
                                            <td>{fournisseur.reference_fournisseur}</td>
                                            <td>{fournisseur.prix_achat}</td>
                                            <td>{fournisseur.devise}</td>
                                            <td>{fournisseur.type_transport}</td>
                                            <td>{new Date(fournisseur.date_achat).toLocaleString()}</td>
                                            <td className="d-flex border-0">
                                                {fournisseur.fournisseur?.adresses?.map(adresse => (
                                                        <OverlayTrigger key={adresse._id} trigger="click" rootClose placement="top" overlay={popAdresse(adresse)}>
                                                            <Button variant="secondary" size="sm" className="me-2">
                                                                <i className="material-icons cursor-pointer">home</i>
                                                            </Button>
                                                        </OverlayTrigger>

                                                ))}
                                            </td>
                                            <td>
                                                <Dropdown className="light-dropdown">
                                                    <Dropdown.Toggle>
                                                        <i className="material-icons px-2">more_horiz</i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item disabled={isFormOpen || fournisseurEditId} onClick={() => setFournisseurEditId(fournisseur.fournisseur_id)}>Modifier</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => removeFournisseurConfirm(fournisseur)}>Supprimer</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </>
                                    }

                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>
            }


            {getArticleFournisseursLoading && <span className="hloader"></span>}
            {(!getArticleFournisseursLoading && !articleFournisseursList && !globalError && !isFormOpen) && <em>- Aucun élément trouvé -</em>}

        </>
    );
}

export default Fournisseurs;