import React, { useEffect, useRef, useState } from 'react';
import { UsersProfilService } from '../../../services/UsersProfilService';
import { GlobalContext } from "../../../services/GlobalProvider";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, ButtonGroup, Tab } from 'react-bootstrap';
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, deepFind } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { PDFService } from '../../../services/PDFService';
import { ParametreService } from '../../../services/ParametreService';

import './PersonnaliserTheme.scss';

import Global from './tpl/Global';

import HeaderDefaultTpl from './tpl/header/DefaultTpl';
import HeaderFenetreTpl from './tpl/header/FenetreTpl';
import HeaderImageTpl from './tpl/header/ImageTpl';

import BodyDefaultTpl from './tpl/body/factures/DefaultTpl';
import BodySimplifiedTpl from './tpl/body/factures/SimplifiedTpl';
import BodyCondensedTpl from './tpl/body/factures/CondensedTpl';

import FooterDefaultTpl from './tpl/footer/DefaultTpl';
import FooterImageTpl from './tpl/footer/ImageTpl';


export default function PersonnaliserTheme() {

    const { folderDetails } = React.useContext(GlobalContext);

    let [PDF, setPDF] = useState()
    let [previewLoading, setPreviewLoading] = useState(false)
    let [paramsLoading, setParamsLoading] = useState(false)
    const [globalError, setGlobalError] = useState()
    const [globalParams, setGlobalParams] = useState()
    const [facturesParams, setFacturesParams] = useState()

    useEffect(() => {
        getParametres()
    }, []);


    function getPDFPreview(globalParams, facturesParams) {

        let previewParams = Object.assign(Object.create(Object.getPrototypeOf(globalParams)), globalParams)
        previewParams["body"] = facturesParams["body"]

        setPreviewLoading(true)
        PDFService.postPDFPreview(folderDetails._id, previewParams).then(res => {
            const file = new Blob([res.data], { type: 'application/pdf' });
            setPDF(URL.createObjectURL(file))
            setPreviewLoading(false)
        }).catch(error => {
            setPreviewLoading(false)
            setGlobalError(error)
        });
    }

    function getParametres() {
        setParamsLoading(true)

        // params: editions.factures

        let params = { 'params': 'editions' };
        ParametreService.getParametres(folderDetails._id, false, false, params).then(res => {
            let global = res.data.data.parametres.editions.global
            let factures = res.data.data.parametres.editions.factures
            setGlobalParams(global)
            setFacturesParams(factures)
            getPDFPreview(global, factures)
            setParamsLoading(false)
        }).catch(error => {
            setParamsLoading(false)
            setGlobalError(error)
        });
    }

    function updateParametresDossier() {
        setParamsLoading(true)
        let data = {
            params: {
                editions: {
                    global: globalParams,
                    factures: {
                        body: {
                            template_name: facturesParams.body.template_name,
                            templates: facturesParams.body.templates,
                            observation: facturesParams.body.observation
                        }
                    },
                    recus: {
                        body: {
                            template_name: facturesParams.body.template_name,
                            templates: facturesParams.body.templates
                        }
                    }
                }
            }
        }

        ParametreService.updateParametresDossier(folderDetails._id, data).then(res => {
            setParamsLoading(false)
        }).catch(error => {
            setParamsLoading(false)
            setGlobalError(error)
        });

    }

    function nextGlobalTemplate(tplName) {
        let current = globalParams[tplName].template_name
        let tplList = Object.keys(globalParams[tplName].templates);
        let index = tplList.indexOf(current)
        let nextIndex = tplList.length - 1 == index ? 0 : index + 1
        setGlobalParams(prevState => ({
            ...prevState,
            [tplName]: {
                ...prevState[tplName],
                template_name: tplList[nextIndex]
            }
        }))
    }

    function prevGlobalTemplate(tplName) {
        let current = globalParams[tplName].template_name
        let tplList = Object.keys(globalParams[tplName].templates);
        let index = tplList.indexOf(current)
        let nextIndex = index == 0 ? tplList.length - 1 : index - 1
        setGlobalParams(prevState => ({
            ...prevState,
            [tplName]: {
                ...prevState[tplName],
                template_name: tplList[nextIndex]
            }
        }))
    }

    function nextFacturesTemplate(tplName) {
        let current = facturesParams[tplName].template_name
        let tplList = Object.keys(facturesParams[tplName].templates);
        let index = tplList.indexOf(current)
        let nextIndex = tplList.length - 1 == index ? 0 : index + 1
        setFacturesParams(prevState => ({
            ...prevState,
            [tplName]: {
                ...prevState[tplName],
                template_name: tplList[nextIndex]
            }
        }))
    }

    function prevFacturesTemplate(tplName) {
        let current = facturesParams[tplName].template_name
        let tplList = Object.keys(facturesParams[tplName].templates);
        let index = tplList.indexOf(current)
        let nextIndex = index == 0 ? tplList.length - 1 : index - 1
        setFacturesParams(prevState => ({
            ...prevState,
            [tplName]: {
                ...prevState[tplName],
                template_name: tplList[nextIndex]
            }
        }))
    }

    return (

        <Row id="PersonnaliserTheme">

            <Col sm={7} xxl={6} className="bg-white border-start border-end position-relative">
                {globalError && (
                    <Col xs="12" className="mt-2">
                        <ErrorManager error={globalError} />
                    </Col>
                )}
                <div className="height-full-min-80 px-2 py-4 py-xxl-5 px-xxl-5 pb-0 ">
                    <div className="light-v-scroll pe-3">
                        <div className="d-flex align-items-center mb-5 mt-1">
                            <h6 className="m-0">Personnalisez le template de vos factures, reçus, listes etc.</h6>
                            <Button className="ms-auto me-2" variant="secondary">Annuler</Button>
                            <Button className="" onClick={() => updateParametresDossier()}>Enregistrer</Button>
                        </div>
                        {paramsLoading && <span className="w-100"><span className="hloader"></span></span>}

                        {!paramsLoading &&
                            <div className="d-flex flex-column">
                                {globalParams &&
                                    <>
                                        <div className="mb-5">
                                            <span className="d-flex align-items-center mb-2">
                                                <i className="text-primary me-2 fs-4 material-icons">format_shapes</i>
                                                <h6 className="m-0 me-2">Style global</h6>
                                                {/*                                             <Dropdown className="no-btn-caret ms-auto">
                                                    <Dropdown.Toggle variant="secondary">
                                                        <i className="material-icons">more_horiz</i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>Réinitialiser les valeurs</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}
                                            </span>
                                            <div style={{ marginLeft: 32 }}>
                                                <Global globalParams={globalParams} />
                                            </div>
                                        </div>

                                        {/* Header */}
                                        <div className="mb-5">
                                            <span className="d-flex align-items-start mb-0">
                                                <span className="d-flex pe-4">
                                                    <i className="text-primary me-2 fs-4 material-icons">tune</i>
                                                    <span>
                                                        <h6 className="mt-2">Choix du Header</h6>
                                                        <p className="text-gray-500">{globalParams.header.templates[globalParams.header.template_name].description}</p>
                                                    </span>
                                                </span>
                                                <ButtonGroup className="ms-auto">
                                                    <Button className="" variant="secondary" onClick={() => prevGlobalTemplate('header')}>
                                                        <i className="material-icons i-lg">navigate_before</i>
                                                    </Button>
                                                    <Button className="" variant="secondary" onClick={() => nextGlobalTemplate('header')}>
                                                        <i className="material-icons i-lg">navigate_next</i>
                                                    </Button>
                                                </ButtonGroup>
                                            </span>
                                            <div style={{ marginLeft: 32 }}>
                                                {globalParams.header.template_name == "default" && <HeaderDefaultTpl globalParams={globalParams.header.templates[globalParams.header.template_name]} />}
                                                {globalParams.header.template_name == "fenetre" && <HeaderFenetreTpl globalParams={globalParams.header.templates[globalParams.header.template_name]} />}
                                                {globalParams.header.template_name == "image" && <HeaderImageTpl globalParams={globalParams.header.templates[globalParams.header.template_name]} />}
                                            </div>
                                        </div>

                                        {/* Body - factures */}
                                        <div className="mb-5">
                                            <span className="d-flex align-items-start mb-0">
                                                <span className="d-flex pe-4">
                                                    <i className="text-primary me-2 fs-4 material-icons">tune</i>
                                                    <span>
                                                        <h6 className="mt-2">Choix du Body</h6>
                                                        <p className="text-gray-500">{facturesParams.body.templates[facturesParams.body.template_name].description}</p>
                                                    </span>
                                                </span>
                                                <ButtonGroup className="ms-auto">
                                                    <Button className="" variant="secondary" onClick={() => prevFacturesTemplate('body')}>
                                                        <i className="material-icons i-lg">navigate_before</i>
                                                    </Button>
                                                    <Button className="" variant="secondary" onClick={() => nextFacturesTemplate('body')}>
                                                        <i className="material-icons i-lg">navigate_next</i>
                                                    </Button>
                                                </ButtonGroup>
                                            </span>
                                            <div style={{ marginLeft: 32 }}>
                                                {facturesParams.body.template_name == "default" && <BodyDefaultTpl facturesParams={facturesParams.body.templates[facturesParams.body.template_name]} />}
                                                {facturesParams.body.template_name == "simplified" && <BodySimplifiedTpl facturesParams={facturesParams.body.templates[facturesParams.body.template_name]} />}
                                                {facturesParams.body.template_name == "condensed" && <BodyCondensedTpl facturesParams={facturesParams.body.templates[facturesParams.body.template_name]} />}
                                            </div>
                                        </div>

                                        {/* Footer */}
                                        <div className="mb-5">
                                            <span className="d-flex align-items-start mb-0">
                                                <span className="d-flex pe-4">
                                                    <i className="text-primary me-2 fs-4 material-icons">tune</i>
                                                    <span>
                                                        <h6 className="mt-2">Choix du Footer</h6>
                                                        <p className="text-gray-500">{globalParams.footer.templates[globalParams.footer.template_name].description}</p>
                                                    </span>
                                                </span>
                                                <ButtonGroup className="ms-auto">
                                                    <Button className="" variant="secondary" onClick={() => prevGlobalTemplate('footer')}>
                                                        <i className="material-icons i-lg">navigate_before</i>
                                                    </Button>
                                                    <Button className="" variant="secondary" onClick={() => nextGlobalTemplate('footer')}>
                                                        <i className="material-icons i-lg">navigate_next</i>
                                                    </Button>
                                                </ButtonGroup>
                                            </span>
                                            <div style={{ marginLeft: 32 }}>
                                                {globalParams.footer.template_name == "default" && <FooterDefaultTpl globalParams={globalParams.footer.templates[globalParams.footer.template_name]} />}
                                                {globalParams.footer.template_name == "image" && <FooterImageTpl globalParams={globalParams.footer.templates[globalParams.footer.template_name]} />}
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        }

                    </div>
                </div>
            </Col>

            {PDF &&
                <Col sm={5} xxl={6} className="bg-gray-600 position-relative p-0 m-0 overflow-hidden d-flex flex-column align-items-center">

                    <Button variant="warning" className="my-3" onClick={() => getPDFPreview(globalParams, facturesParams)}>Prévisualiser</Button>

                    {previewLoading && <span className="px-5 w-100"><span className="hloader"></span></span>}

                    {!previewLoading && <embed src={PDF} width="100%" height="100%" />}


                </Col>
            }

        </Row>

    );
}

