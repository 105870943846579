import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Modal, ToastContainer, Accordion } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { FournisseurService } from '../../services/FournisseurService';
import { FactureService } from '../../services/FactureService';
import { BrouillonService } from '../../services/BrouillonService';
import { EditionService } from '../../services/EditionService';
import { ClientService } from '../../services/ClientService';
import { UsersProfilService } from '../../services/UsersProfilService';
import { PreferenceService } from '../../services/PreferenceService';


import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetailsBrouillon from '../../components/side-details/SideDetailsBrouillon';
import FournisseurDetails from '../../components/side-details/fournisseur/fournisseur-details/FournisseurDetails';
import FournisseurEdition from '../../components/side-details/fournisseur/fournisseur-edition/FournisseurEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import { UserService } from '../../services/UserService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../services/GlobalProvider";
import { getFormErrorMsg, currencyFormat, granted, formattedDateInput, getInvalidObject, getFile } from '../../helper/Helper'
import Details from '../brouillon/details/Details'
import Reglements from './reglements/Reglements'
import WysiwygInput from "../../components/form-inputs/wysiwyg-input/WysiwygInput";
import ClientInput from "../../components/form-inputs/client-input/ClientInput";

import ClientEdition from '../../components/side-details/client/client-edition/ClientEdition';
import ClientDetails from '../../components/side-details/client/client-details/ClientDetails';

import ArticleEdition from '../../components/side-details/article/article-edition/ArticleEdition';
import ArticleDetails from '../../components/side-details/article/article-details/ArticleDetails';

import ModalValidation from './modal-validation/ModalValidation';
import SecteurAnalytique from './secteur-analytique/SecteurAnalytique';


import './Brouillon.scss';

export default function Brouillon() {

    const navigate = useNavigate();

    const location = useLocation();

    const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [currentBrouillon, setCurrentBrouillon] = useState();
    const [modifiedValues, setModifiedValues] = useState();
    const [showChanges, setShowChanges] = useState(false);
    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [isSaving, setIsSaving] = useState(false)
    const [clientComptant, setClientComptant] = useState()
    const [isAccordeonOpen, setIsAccordeonOpen] = useState(true)
    const [profilsFolderUsers, setProfilsFolderUsers] = useState()

    const [isClientDetailOpen, setIsClientDetailOpen] = useState(false)
    const [isClientEditing, setIsClientEditing] = useState(false)
    const [clientElementActions] = useState([{ icon: "edit", title: "Modifier le client", action: "editClient" }])

    const [articleDetailOpenId, setArticleDetailOpenId] = useState()
    const [isArticleEditing, setIsArticleEditing] = useState(false)
    const [articleElementActions] = useState([{ icon: "edit", title: "Modifier l'article", action: "editArticle" }])

    const brouillonId = searchParams.get('brouillonId') ? searchParams.get('brouillonId') : null
    const createdFrom = searchParams.get('from') ? searchParams.get('from') : null

    const [showModalValidation, setShowModalValidation] = useState({ show: false, postValidateResponse: null })


    let brouillonToUpdate = useRef();

    let typeInPath = location.pathname.split("/")[2];

    useEffect(() => {
        if (globalContextLoaded) {
            getBrouillon()
            parametres?.brouillons?.comptant_default && getClientComptant()
            getFolderUsers()
        }
    }, [globalContextLoaded]);

    useEffect(() => {
        if (currentBrouillon != null) { // Ajouter le brouillon en cours dans les préférences
            if (currentBrouillon.type == "facture" || currentBrouillon.type == "avoir") {
                PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['factures']: { ["currentDraft"]: currentBrouillon._id } } }).then(res => {
                    getPreferences()
                }).catch(error => {
                    setGlobalError(error)
                });
            }
            if (currentBrouillon.type == "proforma") {
                PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['proformas']: { ["currentDraft"]: currentBrouillon._id } } }).then(res => {
                    getPreferences()
                }).catch(error => {
                    setGlobalError(error)
                });
            }
        }
    }, [currentBrouillon != null]); // Evite de relancer à chaque changement de currentBrouillon

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function removeCurrentDraftPreferenceAndRedirect(redirect, idDocument) {
        if (typeInPath == "factures") {
            PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['factures']: { ["currentDraft"]: "" } } }).then(res => {
                if (redirect) {
                    preferences.factures.currentDraft = ""
                    getPreferences()
                    navigate(`/${folderDetails._id}/factures?${idDocument ? ('factureId=' + idDocument) : ""}`)
                }
            }).catch(error => {
                setGlobalError(error)
            });

        }
        if (typeInPath == "proformas") {
            PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['proformas']: { ["currentDraft"]: "" } } }).then(res => {
                if (redirect) {
                    preferences.proformas.currentDraft = ""
                    getPreferences()
                    navigate(`/${folderDetails._id}/proformas?${idDocument ? ('proformaId=' + idDocument) : ""}`)
                }
            }).catch(error => {
                setGlobalError(error)
            });
        }
    }

    function changeCurrentBrouillon(brouillon) {
        setIsSaving(true)
        brouillonToUpdate.current = {
            "_id": brouillon?._id,
            "client_id": brouillon?.client_id,
            "vendeur": { "user_id": brouillon?.vendeur?.user_id },
            "adresse_facturation": { "adresse_id": brouillon?.adresse_facturation?.adresse_id },
            "adresse_livraison": { "adresse_id": brouillon?.adresse_livraison?.adresse_id },
            "statut": brouillon?.statut,
            "client_nom": brouillon?.client_nom,
            "user_id": brouillon?.user_id,
            "observation": brouillon?.observation,
            "secteur_analytique": brouillon?.secteur_analytique,
            "description": brouillon?.description,
            "date_execution": brouillon?.date_execution,
        };
        updateBrouillon()
    }

    function getBrouillon() {
        BrouillonService.getBrouillon(folderDetails._id, brouillonId).then(res => {
            res.status !== 200 && setGlobalError({ statusText: `Ce brouillon n'a pas été trouvé (${res.statusText})` })
            if (res.status !== 200) {
                setGlobalError({ statusText: `Ce brouillon n'a pas été trouvé (${res.statusText})` })
                if (brouillonId == preferences[typeInPath].currentDraft) {
                    removeCurrentDraftPreferenceAndRedirect(true)
                }
            }
            setCurrentBrouillon(res.data.data)
            setModifiedValues(res.data.data?.modifiedValues)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function postCheckBrouillon() {
        BrouillonService.postCheckBrouillon(folderDetails._id, brouillonId).then(res => {
            res.status !== 200 && setGlobalError({ statusText: `Ce brouillon n'a pas été trouvé (${res.statusText})` })
            getBrouillon()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    let updateTimeOut = useRef();
    function updateBrouillon() {
        window.clearTimeout(updateTimeOut.current);
        updateTimeOut.current = window.setTimeout(function () {
            BrouillonService.updateBrouillon(folderDetails._id, { "brouillons": [brouillonToUpdate.current] }).then(res => {
                setIsSaving(false)
                getBrouillon()
            }).catch(error => {
                setGlobalError(error)
                setInvalidData(getInvalidObject(error.data?.data))
                setIsSaving(false)
            });
        }, 1000);
    }

    function postValidate() {

        // - pas de modif :
        // doc current : c67f0d4382
        // idDocument : c67f0d4382
        // generatedInfo : "Aucune modification n'a été apporté à ce document."
        // status : no_change
        // -->> pas de doc crée


        // - modifs :
        // doc current : c67f0d4382
        // idDocument : c67f0d4382
        // generatedInfo : cc14024491
        // status : update
        // -->> pas de doc crée

        
        BrouillonService.postValidate(folderDetails._id, currentBrouillon._id).then(res => {
            setShowModalValidation({ show: true, postValidateResponse: res })
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function downloadDraftPDF() {

        EditionService.getBrouillon(folderDetails._id, currentBrouillon._id, "pdf").then(res => {
            if (!res.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                getFile(res.data, `Brouillon ${currentBrouillon.code}`)
            }
        }).catch(error => {
            setGlobalError(error)
        });

    }

    function getClientComptant() {
        let clientComptantId = parametres?.brouillons?.comptant_default
        ClientService.getClient(folderDetails._id, clientComptantId).then(res => {
            setClientComptant(res.data.data)
        }).catch(error => {
            setClientComptant()
            setGlobalError(error)
        });
    }

    function getFolderUsers() {
        UsersProfilService.getUsersProfilsFolderUsers(folderDetails._id).then(res => {
            setProfilsFolderUsers(res.data.data)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function handleChange(e) {
        console.log('------e.target.name')
        console.log(e.target.name)
        changeCurrentBrouillon({ ...currentBrouillon, [e.target.name]: e.target.value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function handleChangeAdresse(e) {
        let currentBrouillonCopy = { ...currentBrouillon }
        currentBrouillonCopy[e.target.name]["adresse_id"] = e.target.value;
        changeCurrentBrouillon(currentBrouillonCopy);
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function handleChangeVendeur(e) {
        let currentBrouillonCopy = { ...currentBrouillon }
        currentBrouillonCopy["vendeur"]["user_id"] = e.target.value;
        changeCurrentBrouillon(currentBrouillonCopy);
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function changeClient(c) {
        let currentBrouillonCopy = { ...currentBrouillon }
        c?._id ? currentBrouillonCopy['client_id'] = c._id : currentBrouillonCopy['client_id'] = ""
        currentBrouillonCopy['client_nom'] = ""
        currentBrouillonCopy['adresse_facturation']['adresse_id'] = ""
        currentBrouillonCopy['adresse_livraison']['adresse_id'] = ""
        changeCurrentBrouillon(currentBrouillonCopy);
        setInvalidData({ ...invalidData, ['client_id']: null })
    }

    function closeBrouillon() {
        PreferenceService.updatePreferences(folderDetails._id, { prefs: { [typeInPath]: { ["currentDraft"]: "" } } }).then(res => {
            preferences[typeInPath].currentDraft = ""
            getPreferences()
            navigate(`/${folderDetails._id}/${createdFrom}`)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function handleKeyDown(e) {
        const { code } = e;
        switch (code) {
            case "F10":
                changeClient(clientComptant)
                e.preventDefault();
                break;
            case "ctrlKey":
                break;
            default:
                break;
        }
    }

    function getReglementStatus() {

        if (currentBrouillon.details.length > 0) {
            if (currentBrouillon?.reglements?.reste_a_payer <= 0) {
                return <Badge pill bg="success" text="white" className="ms-2 fs-6 d-flex flex-row"><i className="material-icons me-1">credit_card</i>Réglée</Badge>
            }
            if (currentBrouillon?.reglements?.reste_a_payer == currentBrouillon?.reglements?.net_a_payer) {
                return <Badge pill bg="danger" text="white" className="ms-2 fs-6 d-flex flex-row"><i className="material-icons me-1">credit_card</i>Non réglée</Badge>
            }
            if (currentBrouillon?.reglements?.total_reglements != 0 && (currentBrouillon?.reglements?.total_reglements < currentBrouillon?.reglements?.net_a_payer)) {
                return <Badge pill bg="warning" text="dark" className="ms-2 fs-6 d-flex flex-row"><i className="material-icons me-1">credit_card</i>Partiellement réglée</Badge>
            }
        } else {
            return null
        }

    }

    function removeBrouillonDialog() {
        setConfirmDialog({
            show: true,
            dialogInfos: {
                title: `Supprimer le brouillon`,
                description: `Voulez vous supprimer le brouillon ${currentBrouillon.code} ?`,
                actionName: 'Supprimer',
                btnVariant: 'danger',
                element: currentBrouillon._id,
                error: false,
                confirmAction: 'removeBrouillon'
            }
        })
    }

    function removeBrouillon() {
        BrouillonService.removeBrouillon(folderDetails._id, currentBrouillon._id).then(res => {
            setConfirmDialog({ show: false })
            closeBrouillon()
        }).catch(error => {
            setGlobalError(error)
            setConfirmDialog({ show: false })
        });
    }

    function closeSide() {
        setIsClientEditing(false)
        setIsClientDetailOpen(false)
        setIsArticleEditing(false)
        setArticleDetailOpenId()
        getBrouillon()
    }

    function onArticleSaved() {
        setIsArticleEditing(false)
        setArticleDetailOpenId()
        postCheckBrouillon()
    }

    function editClient() {
        setIsClientEditing(true)
    }

    function editArticle() {
        setIsArticleEditing(true)
    }

    return (
        <>
            {isSaving &&
                <span className="position-fixed rounded shadow-lg bottom-0 end-0 zindex-9000 px-4 py-2 m-5 bg-gray-700 text-white">
                    Enregistrement
                    <Spinner size="sm" animation="border" className="ms-2" />
                </span>
            }

            {showModalValidation.show && <ModalValidation postValidateResponse={showModalValidation?.postValidateResponse} currentBrouillon={currentBrouillon} setClose={(idDocument) => removeCurrentDraftPreferenceAndRedirect(true, idDocument)} />}

            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            {/* Panneau de détails à droite */}

            {isClientDetailOpen &&
                <SideDetailsBrouillon
                    editMode={isClientEditing}
                    setSideClose={closeSide}
                    setSideCancel={closeSide}
                    detailsComponent={
                        isClientEditing ?
                            <ClientEdition folderId={folderDetails._id} clientEditingId={currentBrouillon.client_id} onSetSaved={closeSide} />
                            :
                            <ClientDetails folderId={folderDetails._id} clientId={currentBrouillon.client_id} elementActions={clientElementActions} setElementAction={(a, e) => eval(a)(e)} />
                    }
                />
            }

            {articleDetailOpenId &&
                <SideDetailsBrouillon
                    editMode={isArticleEditing}
                    setSideClose={closeSide}
                    setSideCancel={closeSide}
                    detailsComponent={
                        isArticleEditing ?
                            <ArticleEdition folderId={folderDetails._id} articleEditingId={articleDetailOpenId} onSetSaved={onArticleSaved} />
                            :
                            <ArticleDetails folderId={folderDetails._id} articleId={articleDetailOpenId} elementActions={articleElementActions} setElementAction={(a, e) => eval(a)(e)} />
                    }
                />
            }

            <div id="Brouillon">
                <>

                    {globalError && (
                        <ErrorManager error={globalError} fixed="true" />
                    )}

                    {currentBrouillon &&
                        <Container fluid className="border-bottom  ps-0">
                            <Row className="  ">
                                <Col xs="auto" className="d-flex align-items-center text-white bg-gray-900 ps-5 pe-5">
                                    <h4 className="m-0 p-0 text-uppercase"><i className="float-start material-icons me-2">receipt_long</i>{currentBrouillon.type} {currentBrouillon.code}</h4>
                                </Col>
                                <Col className="py-3">
                                    <Row>
                                        <Col xs={12} xxl="auto" className="ps-3 d-flex align-items-center  order-2 order-xxl-1">
                                            {/* <Badge pill bg="dark" text="white" className="fs-6 d-flex flex-row"><i className="material-icons me-1">receipt_long</i>{currentBrouillon.type} {currentBrouillon.code}</Badge> */}
                                            <Badge pill bg="gray-200" text="dark" className="ms-2 fs-6 d-flex flex-row">
                                                <i className="material-icons me-1">account_balance_wallet</i>
                                                <span className={(showChanges && modifiedValues?.statut) ? "modified-bg " : ""}>
                                                    {currentBrouillon.statut == "debit" && "Débit"}
                                                    {currentBrouillon.statut == "comptant" && "Comptant"}
                                                    {currentBrouillon.statut == "interne" && "Interne"}
                                                </span>
                                            </Badge>
                                            <Badge pill bg="gray-200" text="dark" className="ms-2 fs-6 d-flex flex-row">
                                                <i className="material-icons me-1">event</i>
                                                {currentBrouillon.type == "facture" &&
                                                    <>Facture du {new Date(currentBrouillon.date).toLocaleString()}</>
                                                }
                                                {currentBrouillon.type == "proforma" &&
                                                    <>Proforma du {new Date(currentBrouillon.date).toLocaleString()}</>
                                                }
                                                {currentBrouillon.type == "avoir" &&
                                                    <>Avoir du {new Date(currentBrouillon.date).toLocaleString()}</>
                                                }

                                            </Badge>

                                            {currentBrouillon.type == "facture" && getReglementStatus()}
                                            
                                            
                                            <Form.Check
                                                className="ms-3"
                                                type="switch"
                                                label="Surligner les changements"
                                                id="disabled-custom-switch"
                                                checked={showChanges}
                                                onChange={() => setShowChanges(!showChanges)}
                                            />
                                           

                                        </Col>
                                        <Col xs={12} xxl="auto" className="pb-2 pb-xxl-0 ms-xxl-auto ps-3 d-flex align-items-center  order-1 order-xxl-2">
                                            <div className="d-flex align-items-center w-100">

                                                {granted(grants, "brouillons.edit.vendeur") &&

                                                    <>
                                                        <span className="me-2">Vendeur : </span>
                                                        {profilsFolderUsers?.length > 0 ?
                                                            <Form.Group className="d-flex">
                                                                <Form.Select name="profil" defaultValue={currentBrouillon?.vendeur?.user_id} onChange={handleChangeVendeur}>
                                                                    {profilsFolderUsers.map(profil =>
                                                                        <option key={profil.user_id} value={profil.user_id}>{profil.user_login}</option>
                                                                    )}
                                                                </Form.Select>
                                                            </Form.Group>
                                                            :
                                                            <em>- Aucun profil trouvé -</em>
                                                        }
                                                    </>


                                                }

                                                <Dropdown className="ms-auto ms-xxl-3 float-end">
                                                    <OverlayTrigger
                                                        delay={{ show: 800 }}
                                                        overlay={
                                                            <Tooltip>
                                                                Plus d'actions
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                                            <i className="material-icons i-lg">more_horiz</i>
                                                        </Dropdown.Toggle>
                                                    </OverlayTrigger>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={downloadDraftPDF}>Imprimer le brouillon</Dropdown.Item>
                                                        {granted(grants, "brouillons.remove") &&
                                                            <Dropdown.Item className="text-danger" onClick={() => removeBrouillonDialog()}>Supprimer</Dropdown.Item>
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <Button className="ms-2" variant="secondary" onClick={closeBrouillon}>Fermer</Button>
                                                <Button className="ms-2" onClick={postValidate} disabled={isSaving || !currentBrouillon.client_id || !currentBrouillon.vendeur || currentBrouillon.details.length == 0}>
                                                    {currentBrouillon.type == "facture" &&
                                                        <>Valider la facture</>
                                                    }
                                                    {currentBrouillon.type == "proforma" &&
                                                        <>Valider la proforma</>
                                                    }
                                                    {currentBrouillon.type == "avoir" &&
                                                        <>Valider l'avoir</>
                                                    }
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>



                                </Col>
                            </Row>
                        </Container>
                    }

                    {currentBrouillon &&
                        <Container fluid className="py-4">


                            {/* Zone infos header accordeon */}
                            <Row className="mb-3">

                                <Accordion defaultActiveKey="headerKey">
                                    <Accordion.Item eventKey="headerKey">

                                        <Accordion.Header onClick={() => { setIsAccordeonOpen(!isAccordeonOpen) }}>
                                            {isAccordeonOpen ?
                                                <>
                                                    <i className="me-2 fs-4 material-icons">info</i>Informations
                                                </>
                                                :
                                                <>
                                                    <span className="d-flex align-items-center w-100">

                                                        <Row className="w-100">
                                                            <Col xs={5} className="d-flex align-items-center">
                                                                {currentBrouillon?.client_nom &&
                                                                    <h6 className="text-dark d-flex align-items-center fw-normal m-0 me-4"><i className="text-primary-600 me-2 fs-4 material-icons">person_outline</i>{currentBrouillon.client_nom}</h6>
                                                                }
                                                                {currentBrouillon?.date_execution &&
                                                                    <h6 className="text-dark d-flex align-items-center fw-normal m-0 me-4"><i className="text-primary-600 me-2 fs-4 material-icons">event</i>Exécution le {formattedDateInput(currentBrouillon.date_execution)}</h6>
                                                                }
                                                                {currentBrouillon?.observation &&
                                                                    <h6 className="text-dark d-flex align-items-center fw-normal m-0 me-4"><i className="text-primary-600 me-2 fs-4 material-icons">notes</i>Observations</h6>
                                                                }
                                                            </Col>
                                                            <Col xs={7} className="d-flex align-items-center">
                                                                <Row className="w-100">
                                                                    <Col xs={{ span: 4, offset: (currentBrouillon?.type == "proforma" ? 4 : 0) }}>
                                                                        <span className="d-flex align-items-center px-2 w-100 d-flex bg-primary-700 text-light rounded ms-2"><i className="me-2 fs-4 material-icons">receipt_long</i>Total TTC : {currencyFormat(currentBrouillon.total_ttc)} F</span>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <span className="d-flex align-items-center px-2 w-100 d-flex bg-primary-600 text-light rounded ms-2"><i className="me-2 fs-4 material-icons">bar_chart</i>Total prix de revient : {currencyFormat(currentBrouillon.total_prix_revient)} F</span>
                                                                    </Col>
                                                                    {currentBrouillon?.type != "proforma" &&
                                                                        <Col xs={4} >
                                                                            <span className="d-flex align-items-center px-2 w-100 d-flex bg-primary-500 text-light rounded ms-2">
                                                                                <i className="me-2 fs-4 material-icons">credit_card</i>
                                                                                {currentBrouillon.type == "avoir" ?
                                                                                    <>{currentBrouillon.reglements.reglements.length} remboursement(s) saisi(s)</>
                                                                                    :
                                                                                    <>{currentBrouillon.reglements.reglements.length} règlements, {currencyFormat(currentBrouillon.reglements.reste_a_payer)} F restant</>
                                                                                }

                                                                            </span>
                                                                        </Col>
                                                                    }
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                    </span>
                                                </>
                                            }
                                        </Accordion.Header>

                                        <Accordion.Body className="p-0">
                                            <Row>
                                                <Col xs={12} xxl={5} className="pe-0 mb-3 mb-xxl-0">
                                                    <Row className="p-3">
                                                        <Col sm={6}>
                                                            <div className={(showChanges && modifiedValues?.client_id) ? "modified-border" : ""}>
                                                                <h6 className="text-dark d-flex align-items-center fw-normal mb-3"><i className="text-primary-600 me-2 fs-4 material-icons">person_outline</i>Client<span className="text-danger">*</span></h6>
                                                                <div className="d-flex align-items-start">
                                                                    <div className="w-100">
                                                                        <ClientInput disabled={currentBrouillon.code} clientId={currentBrouillon.client_id} clientComptant={clientComptant} clientComptantButton={clientComptant ? "true" : "false"} /* isInvalid={getFormErrorMsg(invalidData?.client_id)} */ folderId={folderDetails._id} setClient={c => changeClient(c)} />
                                                                    </div>

                                                                    {currentBrouillon.client_nom &&
                                                                        <OverlayTrigger
                                                                            delay={{ show: 800 }}
                                                                            overlay={
                                                                                <Tooltip>
                                                                                    Consulter
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button className="ms-2" variant="secondary" onClick={() => setIsClientDetailOpen(true)} >
                                                                                <i className="material-icons i-lg">exit_to_app</i>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    }

                                                                </div>

                                                                {currentBrouillon.client &&

                                                                    <>
                                                                        {currentBrouillon.client.type == "comptant" &&
                                                                            <Form.Group className={"ms-auto w-auto mb-2 mt-2 " + ((showChanges && modifiedValues?.client_nom) ? "modified-border" : "")}>
                                                                                <Form.Label>Nom du client :</Form.Label>
                                                                                <Form.Control name="client_nom" placeholder="Nom du client" defaultValue={currentBrouillon.client_nom} isInvalid={getFormErrorMsg(invalidData?.description)} onChange={handleChange} type="text" />
                                                                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.client_nom)}</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        }

                                                                        <div className="mt-2">
                                                                            {(currentBrouillon.client.type == "debit") &&
                                                                                <>

                                                                                    <h6 className="text-dark d-flex align-items-center fw-normal mb-3 mt-3"><i className="text-primary-600 me-2 fs-4 material-icons">location_pin</i>Adresses</h6>
                                                                                    {currentBrouillon?.client?.adresses?.length > 0 &&
                                                                                        <>
                                                                                            <Form.Group className="mb-2">
                                                                                                <Form.Label>Adresse de facturation :</Form.Label>
                                                                                                <Form.Select disabled={currentBrouillon?.client?.adresses?.length < 2} name="adresse_facturation" defaultValue={currentBrouillon.adresse_facturation?.adresse_id || ""} onChange={handleChangeAdresse}>
                                                                                                    {currentBrouillon?.client?.adresses?.length >= 2 &&
                                                                                                        <option value="" disabled>- Choisir -</option>
                                                                                                    }
                                                                                                    {currentBrouillon.client.adresses?.map(adresse =>
                                                                                                        <option key={adresse._id} value={adresse._id}>{adresse.libelle || adresse.adresse}</option>
                                                                                                    )}
                                                                                                </Form.Select>
                                                                                            </Form.Group>

                                                                                            <Form.Group>
                                                                                                <Form.Label>Adresse de livraison :</Form.Label>
                                                                                                <Form.Select disabled={currentBrouillon?.client?.adresses?.length < 2} name="adresse_livraison" defaultValue={currentBrouillon.adresse_livraison?.adresse_id || ""} onChange={handleChangeAdresse}>
                                                                                                    {currentBrouillon?.client?.adresses?.length >= 2 &&
                                                                                                        <option value="" disabled>- Choisir -</option>
                                                                                                    }
                                                                                                    {currentBrouillon.client.adresses?.map(adresse =>
                                                                                                        <option key={adresse._id} value={adresse._id}>{adresse.libelle || adresse.adresse}</option>
                                                                                                    )}
                                                                                                </Form.Select>
                                                                                            </Form.Group>
                                                                                        </>
                                                                                    }
                                                                                    {currentBrouillon?.client?.adresses?.length == 0 &&
                                                                                        <em>- Aucune adresse enregistrée -</em>
                                                                                    }

                                                                                </>
                                                                            }

                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <div className={"mb-3 " + ((showChanges && modifiedValues?.date_execution) ? "modified-border" : "")}>
                                                                <h6 className="text-dark d-flex align-items-center fw-normal mb-3"><i className="text-primary-600 me-2 fs-4 material-icons">event</i>Date d'exécution</h6>
                                                                <Form.Group>
                                                                    <Form.Control name="date_execution" placeholder="Date d'exécution" value={formattedDateInput(currentBrouillon.date_execution)} onChange={handleChange} type="date" />
                                                                </Form.Group>
                                                            </div>
                                                            <div className={"mb-3 " + ((showChanges && modifiedValues?.observation) ? "modified-border" : "")}>
                                                                <h6 className="text-dark d-flex align-items-center fw-normal mb-3"><i className="text-primary-600 me-2 fs-4 material-icons">notes</i>Observations</h6>
                                                                <WysiwygInput value={currentBrouillon.observation} placeHolder="Rédiger les informations relatives à la facture..." changeValue={handleChange} name="observation" isInvalid={getFormErrorMsg(invalidData?.observation)} feedback={getFormErrorMsg(invalidData?.observation)} />
                                                                {/* <div className="border border-gray-400 rounded p-2" >
                                                                </div> */}
                                                            </div>
                                                            {parametres?.general?.export_comptable.comptabilite_analytique &&
                                                                <SecteurAnalytique value={currentBrouillon.secteur_analytique} setSecteur={s => handleChange(s)} name="secteur_analytique" isInvalid={getFormErrorMsg(invalidData?.secteur_analytique)} feedback={getFormErrorMsg(invalidData?.secteur_analytique)}/>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} xxl={7} className="ps-xxl-0">
                                                    <Row className="m-0 h-100">

                                                        {/* <Col sm={{ span: 4, offset: (currentBrouillon?.type == "proforma" ? 4 : 0) }} className="bg-primary-700 text-white"> */}
                                                        <Col sm={currentBrouillon?.type == "proforma" ? { span: 6 } : null} xxl={currentBrouillon?.type == "proforma" ? { span: 4, offset: 4 } : null} className="bg-primary-700 text-white">
                                                            <div className="p-3">
                                                                <h6 className="d-flex align-items-center fw-normal mb-3"><i className="me-2 fs-4 material-icons">receipt_long</i>Facture</h6>
                                                                <div className="d-flex flex-column">
                                                                    {currentBrouillon.details.length == 0 &&
                                                                        <em>- Aucun total à afficher -</em>
                                                                    }
                                                                    {currentBrouillon.details.length > 0 &&
                                                                        <>

                                                                            {currentBrouillon.total_brut_ht != null &&
                                                                                <p className="m-0">
                                                                                    Total brut HT
                                                                                    <span className={"float-end " + ((showChanges && modifiedValues?.total_brut_ht) ? "modified-bg" : "")}>
                                                                                        {currencyFormat(currentBrouillon.total_brut_ht)}
                                                                                    </span>
                                                                                </p>
                                                                            }

                                                                            {currentBrouillon.total_remise != null &&
                                                                                <p className="m-0">
                                                                                    Total des remises
                                                                                    <span className={"float-end " + ((showChanges && modifiedValues?.total_remise) ? "modified-bg" : "")}>
                                                                                        {currencyFormat(currentBrouillon.total_remise)}
                                                                                    </span>
                                                                                </p>
                                                                            }

                                                                            {currentBrouillon.total_net_ht != null &&
                                                                                <p className="m-0">
                                                                                    Total net HT
                                                                                    <span className={"float-end " + ((showChanges && modifiedValues?.total_net_ht) ? "modified-bg" : "")}>
                                                                                        {currencyFormat(currentBrouillon.total_net_ht)}
                                                                                    </span>
                                                                                </p>
                                                                            }

                                                                            {currentBrouillon.total_taxes?.montant_total != null &&
                                                                                <p className="m-0">
                                                                                    Total DES TAXES
                                                                                    <span className={"float-end " + ((showChanges && modifiedValues?.total_taxes?.montant_total) ? "modified-bg" : "")}>
                                                                                        {currencyFormat(currentBrouillon.total_taxes?.montant_total)}
                                                                                    </span>
                                                                                </p>
                                                                            }

                                                                            {currentBrouillon.total_taxes?.montants_details != null &&
                                                                                <div className="border-start ms-0 text-primary-200 border-primary-300 me-1">
                                                                                    {currentBrouillon.total_taxes.montants_details.map(montant => (
                                                                                        <p key={montant.contribution_id} className="ms-2 my-0">
                                                                                            Dont {montant.libelle} (
                                                                                            <span className={(showChanges && modifiedValues?.total_taxes?.montants_details.find(m => m._id == montant._id)) ? "modified-bg" : ""}>
                                                                                                {currencyFormat(montant.montant)} F
                                                                                            </span>
                                                                                            )</p>
                                                                                    ))}
                                                                                </div>
                                                                            }

                                                                            {currentBrouillon.total_ttc != null &&
                                                                                <h6 className="mt-3">
                                                                                    TOTAL TTC (FPC)
                                                                                    <span className={"float-end " + ((showChanges && modifiedValues?.total_ttc) ? "modified-bg" : "")}>
                                                                                        {currencyFormat(currentBrouillon.total_ttc)}
                                                                                    </span>
                                                                                </h6>

                                                                            }

                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col sm={currentBrouillon?.type == "proforma" ? { span: 6 } : null} xxl={currentBrouillon?.type == "proforma" ? { span: 4 } : null} className="bg-primary-600 text-white">
                                                            <div className="p-3">
                                                                <h6 className="d-flex align-items-center fw-normal mb-3"><i className="me-2 fs-4 material-icons">bar_chart</i>Synthèse prix</h6>
                                                                <div className="d-flex flex-column">
                                                                    {currentBrouillon.details.length == 0 &&
                                                                        <em>- Aucun total à afficher -</em>
                                                                    }
                                                                    {currentBrouillon.details.length > 0 &&
                                                                        <div className="">

                                                                            {(currentBrouillon.details.length > 0 && currentBrouillon.total_prix_revient != null) &&
                                                                                <Row>
                                                                                    <Col xs="9"><p className="m-0">Total prix de revient</p></Col>
                                                                                    <Col xs="3"><p className="m-0 text-end">
                                                                                        <span className={(showChanges && modifiedValues?.total_prix_revient) ? "modified-bg" : ""}>
                                                                                            {currencyFormat(currentBrouillon.total_prix_revient)}
                                                                                        </span>
                                                                                    </p></Col>
                                                                                </Row>
                                                                            }

                                                                            {(currentBrouillon.details.length > 0 && currentBrouillon.taux_marge_global_calc != null) &&
                                                                                <Row>
                                                                                    <Col xs="9"><p className="m-0">Taux de marge global</p></Col>
                                                                                    <Col xs="3"><p className="m-0 text-end">
                                                                                        <span className={(showChanges && modifiedValues?.taux_marge_global_calc) ? "modified-bg" : ""}>
                                                                                            {currencyFormat(currentBrouillon.taux_marge_global_calc)}
                                                                                        </span>
                                                                                    </p></Col>
                                                                                </Row>
                                                                            }
                                                                            {(currentBrouillon.details.length > 0 && currentBrouillon.taux_marque_global_calc != null) &&
                                                                                <Row>
                                                                                    <Col xs="9"><p className="m-0">Taux de marque global</p></Col>
                                                                                    <Col xs="3"><p className="m-0 text-end">
                                                                                        <span className={(showChanges && modifiedValues?.taux_marque_global_calc) ? "modified-bg" : ""}>
                                                                                            {currentBrouillon.taux_marque_global_calc}
                                                                                        </span>
                                                                                    </p></Col>
                                                                                </Row>
                                                                            }
                                                                            {(currentBrouillon.details.length > 0 && currentBrouillon.coefficient_global_calc != null) &&
                                                                                <Row>
                                                                                    <Col xs="9"><p className="m-0">Coefficient global</p></Col>
                                                                                    <Col xs="3"><p className="m-0 text-end">
                                                                                        <span className={(showChanges && modifiedValues?.coefficient_global_calc) ? "modified-bg" : ""}>
                                                                                            {currentBrouillon.coefficient_global_calc}
                                                                                        </span>
                                                                                    </p></Col>
                                                                                </Row>
                                                                            }

                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </Col>

                                                        {currentBrouillon?.type != "proforma" &&
                                                            <Col sm="4" className="bg-primary-500 text-white">
                                                                <div className="p-3">
                                                                    <Reglements brouillon={currentBrouillon} reloadBrouillon={getBrouillon} showChanges={showChanges} modifiedValues={modifiedValues}/>
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </Row>

                            {/* Zone details */}
                            <Row>
                                <Col xs={12}>
                                    <div className="rounded border p-3">
                                        <h6 className="text-dark d-flex align-items-center fw-normal mb-3"><i className="text-primary-600 me-2 fs-4 material-icons">manage_search</i>Détails<span className="text-danger">*</span></h6>
                                        <Form.Group>
                                            <Form.Control className={(showChanges && modifiedValues?.description) ? "modified-border" : ""} as="textarea" name="description" placeholder="Description à destination du client" defaultValue={currentBrouillon.description} isInvalid={getFormErrorMsg(invalidData?.description)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.description)}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Details folderId={folderDetails._id} openArticleId={id => setArticleDetailOpenId(id)} brouillon={currentBrouillon} showChanges={showChanges} modifiedValues={modifiedValues} reloadBrouillon={getBrouillon} dropBottom={!isAccordeonOpen && currentBrouillon.details.length < 8} />
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    }
                </>
            </div>
        </>
    );
}